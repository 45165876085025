import { FormatDate, stringify } from '@/Utilities'
import { ArticleData } from '@components/DataQuery/DataQueryContext/ArticleData'
import { useStepStyle } from '@components/NewMigration/step.styles'
import {
	createTableColumn,
	DataGridHeaderCell,
	DataGridCell,
	TableCellLayout,
	TableColumnSizingOptions,
	Link,
	mergeClasses,
} from '@fluentui/react-components'
import { useStatusFilter } from './useStatusFilter'
import { StatusRender } from './StatusRender'
import { useQueryContext } from '@components/DataQuery/DataQueryContext'
import { DynamicColumn } from '@components/DataQuery/DataQueryContext/DataQueryResult'
import { DocumentTextLink20Regular } from '@fluentui/react-icons'
const LinkColumnId = '_Link'
const LineNumberId = '_LineNumber'
const StatusId = '_ReviewStatus'
const accessData = (article: ArticleData, path: string) => {
	const p = (path ?? '').replaceAll(' > ', '.')
	let root = article as any
	return root[p]
}
export const useDataGrid = (onLinkClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: ArticleData) => void) => {
	const baseClassses = useStepStyle()
	const { component, selectedStatus } = useStatusFilter()
	const context = useQueryContext()
	const columns = context.DataQueryResult.Columns
	const columnSizing: TableColumnSizingOptions = {
		[LinkColumnId]: {
			defaultWidth: 35,
			minWidth: 35,
		},
		[LineNumberId]: {
			defaultWidth: 35,
			minWidth: 35,
		},
		[StatusId]: {
			defaultWidth: 180,
			minWidth: 180,
		},
		...Object.fromEntries(
			columns.map((column) => [
				column.Props,
				{
					defaultWidth: column.Width,
					minWidth: 50,
				},
			])
		),
	}
	const renderCell = (item: ArticleData, column: DynamicColumn) => {
		const value = accessData(item, column.DisplayName)
		if (typeof value !== 'string') return value
		if (value.startsWith('http'))
			return (
				<Link href={value} target="_blank">
					{value}
				</Link>
			)
		if (column.DisplayName.toLocaleLowerCase().endsWith('date')) {
			if (value) return FormatDate(value)
		}
		return value
	}
	const columnsDef = [
		createTableColumn<ArticleData>({
			columnId: LineNumberId,
			renderHeaderCell() {
				return <DataGridHeaderCell className={mergeClasses('no-border', baseClassses.lineNumber)} tabIndex={undefined} />
			},
			renderCell(item) {
				return (
					<DataGridCell className={baseClassses.lineNumber} tabIndex={undefined}>
						{item.lineNumber}
					</DataGridCell>
				)
			},
		}),
		createTableColumn<ArticleData>({
			columnId: LinkColumnId,
			renderHeaderCell() {
				return <DataGridHeaderCell className={mergeClasses('no-border', baseClassses.lineNumber)} tabIndex={undefined} />
			},
			renderCell(item) {
				return (
					<DataGridCell className={baseClassses.lineNumber} tabIndex={undefined}>
						<Link href={item.Url} target="_blank" onClick={(e) => onLinkClick(e, item)} title="Source link">
							<DocumentTextLink20Regular />
						</Link>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<ArticleData>({
			columnId: StatusId,
			renderHeaderCell() {
				return (
					<DataGridHeaderCell className={baseClassses.header}>
						<TableCellLayout truncate>
							Review Status
							{component}
						</TableCellLayout>
					</DataGridHeaderCell>
				)
			},
			renderCell(item) {
				return <StatusRender item={item} />
			},
		}),
		...columns.map((column) =>
			createTableColumn<ArticleData>({
				columnId: column.DisplayName,
				renderHeaderCell() {
					return (
						<DataGridHeaderCell className={baseClassses.header}>
							<TableCellLayout title={column.DisplayName} truncate>
								{column.DisplayName}
							</TableCellLayout>
						</DataGridHeaderCell>
					)
				},
				renderCell(item) {
					return (
						<DataGridCell>
							<TableCellLayout title={stringify(accessData(item, column.DisplayName))} truncate>
								{renderCell(item, column)}
							</TableCellLayout>
						</DataGridCell>
					)
				},
			})
		),
	]
	return {
		columns: columnsDef,
		columnSizingOptions: columnSizing,
		selectedStatus,
	}
}
