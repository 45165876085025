import { Button, Body1, Dropdown, Option } from '@fluentui/react-components'
import { ArrowPrevious24Regular, ChevronLeft24Regular, ChevronRight24Regular, ArrowNext24Regular } from '@fluentui/react-icons'
import './Pagination.scss'
export type PaginationProps = {
	current: number
	pageSize: number
	total: number
	totalPage: number
	onChange: (current: number, pageSize: number) => void
	changeCurrent: (current: number) => void
	changePageSize: (pageSize: number) => void
}
export const Pagination = (props: PaginationProps) => {
	const page = props.current
	const maxPage = props.totalPage
	const disableForward = props.current >= props.totalPage
	const disableBackward = props.current <= 1
	return (
		<section className="pagination">
			<Button icon={<ArrowPrevious24Regular />} disabled={disableBackward} onClick={() => props.changeCurrent(1)} title="First page" />
			<Button icon={<ChevronLeft24Regular />} disabled={disableBackward} onClick={() => props.changeCurrent(page - 1)} title="Previous  page" />
			<Body1>{Math.min(page, maxPage)}</Body1>
			<Body1>/</Body1>
			<Body1>{maxPage}</Body1>
			<Button icon={<ChevronRight24Regular />} disabled={disableForward} onClick={() => props.changeCurrent(page + 1)} title="Next page" />
			<Button icon={<ArrowNext24Regular />} disabled={disableForward} onClick={() => props.changeCurrent(maxPage)} title="Last page" />
			<Body1>Display count:</Body1>
			<Dropdown
				className="pagination-pagesize"
				value={props.pageSize.toString()}
				onOptionSelect={(e, d) => {
					let n = Number(d.optionValue)
					if (isNaN(n)) n = 10
					props.changePageSize(n)
				}}
				title="Page size"
			>
				<Option>10</Option>
				<Option>20</Option>
				<Option>50</Option>
				<Option>100</Option>
			</Dropdown>
		</section>
	)
}
