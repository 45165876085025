import { TaskStatus } from '@/Api/Types'
import { Body1, makeStyles } from '@fluentui/react-components'
import './MigrationProgress.scss'
import MigrationStatus from '@/Media/Migration Status.png'
import { InfoIcon } from '@components/InfoIcon'
type Props = {
	status: TaskStatus
	visible?: boolean
}
const useStyle = makeStyles({
	container: {
		maxWidth: '100vw',
		overflowX: 'auto',
		overflowY: 'hidden',
	},
})
export const MigrationProgress = ({ status: s, visible }: Props) => {
	const classes = useStyle()
	return (
		<Body1 className="migration-progress">
			<Body1>In Processing ({Math.floor((s.published / s.included) * 100)}%)</Body1>
			<InfoIcon tooltipClass="migration-progress-overlay" visible={visible}>
				<section className={classes.container}>
					<section className="migration-progress-step">
						<img src={MigrationStatus} alt="Migration Status" />
						<section className="migration-progress-counter">
							<section className="migration-progress-text">
								<Body1 />
							</section>
							<section className="migration-progress-text large">
								<Body1>{s.included} Included</Body1>
							</section>
							<section className="migration-progress-text">
								<Body1>{s.retrieved} Retrieved</Body1>
							</section>
							<section className="migration-progress-text">
								<Body1>{s.converted} Converted</Body1>
							</section>
							<section className="migration-progress-text">
								<Body1>{s.converted} Created</Body1>
							</section>
							<section className="migration-progress-text">
								<Body1>{s.published} Published</Body1>
							</section>
						</section>
						<Body1 className="migration-progress-info">
							<math display="inline">
								<mtext>Processing Completion Rate</mtext>
								<mo>=</mo>
								<mo>(</mo>
								<mfrac>
									<mrow>
										<mtext>#Published</mtext>
									</mrow>
									<mrow>
										<mtext>#Included</mtext>
									</mrow>
								</mfrac>
								<mo>)</mo>
								<mo>×</mo>
								<mtext>100%</mtext>
							</math>
						</Body1>
					</section>
				</section>
			</InfoIcon>
		</Body1>
	)
}
