import { observer } from 'mobx-react-lite'
import { useMigrationContext } from '../Context'
import { FormItem } from '@components/FormItem'
import { Body1, Button, Input, Link, makeStyles, mergeClasses, Radio, RadioGroup, Textarea, tokens } from '@fluentui/react-components'
import { SearchRepositorySelect } from './SearchRepositorySelect'
import { DocsetSelect } from './DocsetSelect'
import { StepProps } from '..'
import { EPermissionType, PermissionType } from '@/Api/Types'
import { ActionButton } from '@components/ActionButton'
import { useNavigate } from 'react-router-dom'
import { InfoIcon } from '@components/InfoIcon'
const useStyle = makeStyles({
	text: {
		display: 'inline-flex',
	},
	link: {
		display: 'inline-flex',
	},
	tooltip: {
		maxWidth: '350px',
	},
	description: {
		display: 'flex',
		flexDirection: 'column',
	},
	textarea: {
		flex: '1 1 0',
		minHeight: '8em',
	},
	gap: {
		marginTop: tokens.spacingVerticalS,
	},
})
export const Step1 = observer((props: StepProps) => {
	const context = useMigrationContext()
	const Step1 = context.Step1
	const navigate = useNavigate()
	const onNext = async () => {
		return Step1.OnNext().then(() => {
			props.OnNext()
		})
	}
	const onCancel = () => {
		navigate(-1)
	}
	const classes = useStyle()
	return (
		<section className="step">
			<section className="step-body">
				<section className={mergeClasses('grid-gap-large', classes.gap)}>
					<FormItem store={Step1} prop="Name" label="Migration Name" required>
						{({ value, valueChange, onBlur }) => (
							<Input
								maxLength={200}
								value={value}
								onChange={(e, v) => valueChange(v.value)}
								onBlur={onBlur}
								autoComplete="off"
								placeholder="Type your migration name"
							/>
						)}
					</FormItem>
				</section>
				<section className="grid-2 grid-gap-large">
					<FormItem store={Step1} prop="Description" label="Migration Description" required className={classes.description}>
						{({ value, valueChange, onBlur }) => (
							<Textarea
								maxLength={500}
								value={value}
								onChange={(e, v) => valueChange(v.value)}
								onBlur={onBlur}
								autoComplete="off"
								placeholder="Type your migration description"
								className={classes.textarea}
							/>
						)}
					</FormItem>
					<FormItem store={Step1} prop="Disclaimer" label="Disclaimer">
						{({ value, valueChange, onBlur }) => (
							<Textarea
								value={value}
								onChange={(e, v) => valueChange(v.value)}
								onBlur={onBlur}
								autoComplete="off"
								resize="vertical"
								className={classes.textarea}
							/>
						)}
					</FormItem>
				</section>
				<section>
					<FormItem
						store={Step1}
						prop="DefaultPermissionType"
						label={
							<Body1 className={classes.text}>
								Default Permission Type
								<InfoIcon tooltipClass={classes.tooltip}>
									The permissioned type metadata is page-level metadata set in the docfx.json file. Set the default permission type
									for the migration here and override it in step 4 if needed for specific pages. Click{' '}
									<Link
										href="https://review.learn.microsoft.com/en-us/help/platform/permissioned-create?branch=main"
										target="_blank"
									>
										Configure permissioned-type for permissioned content
									</Link>{' '}
									for details.
								</InfoIcon>
							</Body1>
						}
						required
					>
						{({ value, valueChange, onBlur }) => (
							<RadioGroup
								layout="horizontal"
								value={value}
								onBlur={onBlur}
								onChange={(_, data) => valueChange(data.value as EPermissionType)}
							>
								<Radio value={PermissionType.Public} label={PermissionType.Public} />
								<Radio value={PermissionType.Private} label={PermissionType.Private} />
								<Radio value={PermissionType.Secret} label={PermissionType.Secret} />
							</RadioGroup>
						)}
					</FormItem>
				</section>
				<section className="grid-2 grid-gap-large">
					<FormItem store={Step1} prop="RepoUrl" label="Repository" required>
						{({ value, valueChange }) => <SearchRepositorySelect Value={value} OnSelect={valueChange} />}
					</FormItem>
					<FormItem store={Step1} prop="Docset" label="Docset" required>
						{({ value, valueChange }) => <DocsetSelect Value={value} OnSelect={valueChange} RepoUrl={Step1.RepoUrl} />}
					</FormItem>
				</section>
			</section>
			<section className="step-footer">
				{!context.MigrationId && <Button onClick={onCancel}>Cancel</Button>}
				<ActionButton appearance="primary" disabled={!Step1.CanNext} title={Step1.WhyCant} onClick={onNext}>
					Next
				</ActionButton>
			</section>
		</section>
	)
})
