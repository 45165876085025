/* eslint-disable react-hooks/exhaustive-deps */
import { Combobox, ComboboxProps, mergeClasses, Option } from '@fluentui/react-components'
import { useEffect, useMemo, useState } from 'react'
import './EnhancedSelect.scss'
export type EnhancedOption = {
	value: string
	text?: string
	disable?: boolean
	children?: string
}
type Props = {
	value: string
	onOptionSelect: (value: string) => void
	options: EnhancedOption[]
	noOptionsMessage?: string
	autoSelect?: boolean
} & Omit<ComboboxProps, 'onOptionSelect'>

export const EnhancedSelect = ({ value, onOptionSelect, options, noOptionsMessage, autoSelect, className, ...props }: Props) => {
	const normalizedOption = useMemo(() => {
		return options.map((o) => ({ value: o.value, children: o.children ?? o.text ?? o.value, disable: o.disable }))
	}, [options])
	const valueToText = useMemo(() => {
		return Object.fromEntries(normalizedOption.map((o) => [o.value, o.children] as const))
	}, [normalizedOption])
	const [query, setQuery] = useState<string>(valueToText[value] ?? '')
	const filtedOption = useMemo(() => {
		if (!query) return normalizedOption
		else {
			const text = query.toLocaleLowerCase()
			return normalizedOption.filter((o) => o.children.toLocaleLowerCase().includes(text))
		}
	}, [query, normalizedOption])
	const selectedOptions = value ? [value] : []
	const onBlur = () => {
		setQuery(valueToText[value] ?? '')
	}
	useEffect(() => {
		setQuery(valueToText[value] ?? '')
	}, [normalizedOption, value])
	return (
		<Combobox
			clearable
			value={query}
			onChange={(e) => setQuery(e.target.value)}
			onOptionSelect={(e, d) => {
				setQuery(d.optionText ?? '')
				onOptionSelect(d.selectedOptions[0])
			}}
			onBlur={onBlur}
			{...props}
			className={mergeClasses('enhanced-select', className)}
			title={query}
			selectedOptions={selectedOptions}
		>
			{filtedOption.length === 0 ? (
				<Option disabled>No Option</Option>
			) : (
				filtedOption.map((o) => (
					<Option key={o.children} value={o.value} text={o.children} disabled={o.disable}>
						{o.children}
					</Option>
				))
			)}
		</Combobox>
	)
}
