import { Field, FieldSlots } from '@fluentui/react-components'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
type Prop<T, TKey extends keyof T> = {
	store: T
	prop: TKey
	required?: boolean
	label: FieldSlots['label']
	requiredMissingMessage?: string
	message?: string
	children: (props: { value: T[TKey]; valueChange: (val: T[TKey]) => void; onBlur: () => void }) => React.ReactNode
	validationState?: Parameters<typeof Field>[0]['validationState']
	className?: string
}
export const FormItem = observer(<T, TKey extends keyof T>(prop: Prop<T, TKey>) => {
	const [message, setMessage] = useState(prop.message ?? '')
	return (
		<Field
			label={prop.label}
			required={prop.required}
			validationMessage={message}
			validationState={prop.validationState}
			className={prop.className}
		>
			{prop.children({
				value: prop.store[prop.prop],
				valueChange: (val) => {
					const dirtyStore = prop.store as { IsDirty?: boolean }
					if (dirtyStore.IsDirty !== undefined && typeof dirtyStore.IsDirty === 'boolean') {
						dirtyStore.IsDirty = true
					}
					prop.store[prop.prop] = val
				},
				onBlur() {
					if (prop.required && !prop.store[prop.prop]) {
						setMessage(prop.requiredMissingMessage ?? `Require ${typeof prop.label === 'string' ? prop.label : ''}`)
					} else {
						setMessage('')
					}
				},
			})}
		</Field>
	)
})
