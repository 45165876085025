import { Link, Toast, ToastBody, ToastIntent, ToastTitle, ToastTrigger, useToastController } from '@fluentui/react-components'
import { Debug } from '@/Utilities/Debug'
type Listener = (title: string, content: string, intent: ToastIntent) => void
class Context {
	public constructor() {
		this.listener = () => {}
	}
	public Emit(title: string, content: string, intent: ToastIntent) {
		this.listener(title, content, intent)
	}
	private listener: Listener
	public get Listener() {
		return this.listener
	}
	public set Listener(v: Listener) {
		this.listener = v
	}
}
const c = new Context()
const s = {
	dispatch: (() => {}) as ReturnType<typeof useToastController>['dispatchToast'],
	update: (() => {}) as ReturnType<typeof useToastController>['updateToast'],
}
export const GlobalToaster = () => {
	const { dispatchToast, updateToast } = useToastController()
	const listener: Listener = (t, e, i) => {
		dispatchToast(
			<Toast>
				<ToastTitle
					action={
						<ToastTrigger>
							<Link>Dismiss</Link>
						</ToastTrigger>
					}
				>
					{t}
				</ToastTitle>
				<ToastBody>{e}</ToastBody>
			</Toast>,
			{
				intent: i,
			}
		)
	}
	s.dispatch = dispatchToast
	s.update = updateToast
	c.Listener = listener
	return <></>
}
Debug.StorgeGlobal('Context', c)
export const DispatchToast: ReturnType<typeof useToastController>['dispatchToast'] = (content, options?) => {
	s.dispatch(content, options)
}
export const UpdateToast: ReturnType<typeof useToastController>['updateToast'] = (options) => {
	s.update(options)
}
