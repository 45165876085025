import { createTableColumn, TableColumnSizingOptions } from '@fluentui/react-components'
import { useMemo } from 'react'
import { QueryExpression } from './QueryBuilderUtils'

export const useDataGrid = (maxDepth: number) => {
	const numOfGroupCols = Math.max(0, maxDepth - 1)

	const columnSizing: TableColumnSizingOptions = Object.assign(
		{
			'add-remove': {
				defaultWidth: 50,
				idealWidth: 50,
				minWidth: 50,
			},
			group: {
				defaultWidth: 50,
				idealWidth: 50,
				minWidth: 50,
			},
			'and-or': {
				defaultWidth: 100,
				idealWidth: 100,
			},
			field: {
				autoFitColumns: true,
				defaultWidth: 300,
				idealWidth: 300,
			},
			value: {
				autoFitColumns: true,
				defaultWidth: 300,
				idealWidth: 300,
			},
		},
		Object.fromEntries(
			new Array(numOfGroupCols).fill(1).map((v, i) => {
				return [
					`group-col-${i}`,
					{
						defaultWidth: 40,
						idealWidth: 40,
						minWidth: 40,
					},
				]
			})
		)
	)
	const columnsDef = [
		createTableColumn<QueryExpression>({
			columnId: 'add-remove',
		}),
		createTableColumn<QueryExpression>({
			columnId: 'group',
		}),
		...new Array(numOfGroupCols).fill(1).map((v, i) => {
			return createTableColumn<QueryExpression>({
				columnId: `group-col-${i}`,
			})
		}),
		createTableColumn<QueryExpression>({
			columnId: 'and-or',
		}),
		createTableColumn<QueryExpression>({
			columnId: 'field',
		}),
		createTableColumn<QueryExpression>({
			columnId: 'operator',
		}),
		createTableColumn<QueryExpression>({
			columnId: 'value',
		}),
	]
	return useMemo(
		() => ({
			columns: columnsDef,
			columnSizingOptions: columnSizing,
		}),
		[maxDepth]
	)
}
