import { useMemo } from 'react'

export const useInLoading = () => {
	return useMemo(() => {
		return {
			loading: () => {
				document.body.classList.add('loading')
			},
			finish: () => {
				document.body.classList.remove('loading')
			},
		}
	}, [])
}
export const wrapperLoading = <T>(promiss: Promise<T>) => {
	return new Promise<T>(() => {
		document.body.classList.add('loading')
		return promiss
	}).finally(() => {
		document.body.classList.remove('loading')
	})
}
