import { observer } from 'mobx-react-lite'
import { StepProps } from '..'
import { Button, DataGrid, DataGridBody, DataGridHeader, DataGridRow, mergeClasses } from '@fluentui/react-components'
import { Pagination } from '@components/Pagination'
import { useMigrationContext } from '../Context'
import { ActionButton } from '@components/ActionButton'
import { usePagination, useRequest } from 'ahooks'
import { Api } from '@/Api'
import { runInAction } from 'mobx'
import { TableColorTooltip } from '../Components/TableColorTooltip'
import { useDataGrid } from './useDataGrid'
import { useStyle } from './styles'
import { Article } from './types'
import { GetConfig, GetTocs } from './api'
import { useFilter } from './useFilter'
import { LoadingSpinner } from '@components/LoadingSpinner'
export const Step4 = observer((props: StepProps) => {
	const classes = useStyle()
	const context = useMigrationContext()
	const step4 = context.Step4

	const { data, loading, runAsync, pagination } = usePagination(
		(param) => {
			return Api.MigrationArticle.GetArticles(context.MigrationId!, filterData, {
				pageNum: param.current,
				pageSize: param.pageSize,
			}).then((res) => {
				const list = res.list as Article[]
				runInAction(() => {
					list.forEach((r) => {
						const e = step4.GetEntity(r)

						e.topic = r.msTopic ?? 'troubleshooting-general'
						e.permission = r.permissionType ?? context.Step1.DefaultPermissionType
						e.name = (r.targetRepoFileName ?? '').replace('.md', '')
						e.folder = r.targetRepoFolder ?? ''
						e.service = r.msService ?? ''
						if (r.targetRepoBranch) {
							e.readonly = true
						}
						r.entity = e
					})
				})
				return {
					list,
					total: res.total,
				}
			})
		},
		{ defaultPageSize: 10 }
	)
	const { data: configs } = useRequest(GetConfig, { cacheKey: 'Step-4-Config' })
	const { data: tocs } = useRequest(() => GetTocs(context.Step1.RepoUrl, context.Step1.Docset.folder), {
		refreshDeps: [context.Step1.RepoUrl, context.Step1.Docset.folder],
	})
	const config = configs ?? {
		'MS.Service': [],
		'MS.Topic': [],
	}
	const toc = tocs ?? []
	const { columnSizingOptions, columns } = useDataGrid(config, toc)

	const search = () => {
		runAsync({
			current: 1,
			pageSize: 10,
		})
	}
	const [component, filterData] = useFilter(search)
	const items = data?.list ?? []

	const onNext = async () => {
		await step4.AutoSave()
		props.OnNext()
	}
	return (
		<section className="step">
			<section className="step-body">
				<section className="step-filter">{component}</section>
				<DataGrid
					items={items}
					columns={columns}
					getRowId={(item) => item.id}
					columnSizingOptions={columnSizingOptions}
					className={mergeClasses(classes.grid, 'resizable-grid')}
					resizableColumns
					resizableColumnsOptions={{
						autoFitColumns: false,
					}}
					focusMode="row_unstable"
				>
					<DataGridHeader>
						<DataGridRow>{({ renderHeaderCell }) => renderHeaderCell()}</DataGridRow>
					</DataGridHeader>
					{loading ? (
						<LoadingSpinner />
					) : (
						<DataGridBody<Article>>
							{({ item, rowId }) => <DataGridRow<Article> key={rowId}>{({ renderCell }) => renderCell(item)}</DataGridRow>}
						</DataGridBody>
					)}
				</DataGrid>
				<Pagination {...pagination} />
			</section>
			<section className="step-footer">
				<Button onClick={props.OnPrevious}>Previous</Button>
				<ActionButton appearance="primary" onClick={onNext}>
					Next
				</ActionButton>
				<TableColorTooltip />
			</section>
		</section>
	)
})
