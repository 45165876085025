import { Button } from '@fluentui/react-components'
import React, { useState } from 'react'
import './ActionButton.scss'
import { useInLoading } from '@/Hook/useInLoading'
import { LoadingSpinner } from '@components/LoadingSpinner'
type ButtonProps = Parameters<typeof Button>[0]
type Props = {
	onClick: () => Promise<unknown> | unknown
	children: React.ReactNode
	disabled?: ButtonProps['disabled']
	appearance?: ButtonProps['appearance']
	className?: ButtonProps['className']
	title?: ButtonProps['title']
}

export const ActionButton = ({ onClick, children, disabled, className, ...args }: Props) => {
	const [isLoading, setLoading] = useState(false)
	const bodyLoading = useInLoading()
	const click = () => {
		setLoading(true)
		const result = onClick()
		bodyLoading.loading()
		if (result instanceof Promise) {
			result.finally(() => {
				bodyLoading.finish()
				setLoading(false)
			})
		} else {
			setLoading(false)
			bodyLoading.finish()
		}
	}
	return (
		<Button
			disabled={isLoading || disabled}
			className={'action-button ' + className}
			icon={isLoading ? <LoadingSpinner /> : undefined}
			onClick={click}
			{...args}
		>
			{children}
		</Button>
	)
}
