import { TableRowId, DataGridProps } from '@fluentui/react-components'
import { useState } from 'react'

export const useDataGridSelected = () => {
	const [selectedRows, setSelectedRows] = useState(new Set<TableRowId>([]))
	const onSelectionChange: DataGridProps['onSelectionChange'] = (e, data) => {
		setSelectedRows(data.selectedItems)
	}
	return [
		selectedRows,
		{
			selectedItems: selectedRows,
			onSelectionChange: onSelectionChange,
			selectionAppearance: 'neutral',
		} as Partial<DataGridProps>,
		setSelectedRows,
	] as const
}
