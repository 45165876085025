import { IComboBoxOption } from '@fluentui/react'
import {
	containsOperator,
	doesNotContainOperator,
	equalsOperator,
	greaterThanOperator,
	greaterThanOrEqualsOperator,
	inOperator,
	isAfterOperator,
	isBeforeOperator,
	isNotOperator,
	isNotSetOperator,
	isOnOperator,
	isSetOperator,
	lessThanOperator,
	lessThanOrEqualsOperator,
	MetadataQueryOperator,
	notEqualsOperator,
	QueryPropsType,
} from './QueryBuilderConstants'
import _ from 'lodash'
import moment from 'moment'
import { DataField } from '@components/DataQuery/Interface'
import { ColumnSorting } from '@components/DataQuery/DataQueryContext'
const MAX_GROUP_CATEGORIES = 3
const GROUP_START = 'start'
const GROUP_END = 'end'
const GROUP_MID = 'interm'
/**
 * @description Escapes special ODATA chars
 * @param value input string to escape
 * @returns escaped string
 */
const replaceSpecialODATAChars = (value: string): string => {
	value = value.replace(/'/g, "''")
	value = value.replace(/%/g, '%25')
	value = value.replace(/\+/g, '%2B')
	value = value.replace(/\//g, '%2F')
	value = value.replace(/\?/g, '%3F')
	value = value.replace(/#/g, '%23')
	value = value.replace(/&/g, '%26')
	//value = value.replace(/ /g, '\\ ')
	return value
}
/**
 * @description determines beginning of day from given date string
 * @param dateStr UTC date string representing selected date
 * @returns UTC date string representing start of day
 */
const toDayStart = (dateStr: string) => {
	const date = new Date(moment(dateStr).local().valueOf())

	const dayStart = moment(date).startOf('day').valueOf()

	const startDate = new Date(dayStart)

	return moment.utc(startDate).format('YYYY-MM-DDTHH:mm:ssZ')
}

/**
 * @description determines end of day from given date string
 * @param dateStr UTC date string representing selected date
 * @returns UTC date string representing end of day
 */
const toDayEnd = (dateStr: string) => {
	const date = new Date(moment(dateStr).local().valueOf())

	const dayEnd = moment(date).endOf('day').valueOf()

	const endDate = new Date(dayEnd)

	return moment.utc(endDate).format('YYYY-MM-DDTHH:mm:ssZ')
}
/**
 * @description converts a single expression to a filter string used by search API
 * @param expr expression to stringify
 * @param metadataFields list of known metadata fields loaded at startup
 */
export const expressionToFilterString = (expr: QueryExpression, metadataFields: DataField[]): string => {
	const field = metadataFields.find((f) => f.FieldName === expr.field)!

	const value = field.FieldType === QueryPropsType.Text ? replaceSpecialODATAChars(expr.value) : expr.value
	let exprStr = ''
	switch (expr.operator) {
		case containsOperator:
			exprStr = `search.ismatch('"${value}"', '${field.SearchFieldName}')`
			// exprStr = `search.ismatch('"${value}"', '${field.SearchFieldName}','simple', 'all')`
			break
		case doesNotContainOperator:
			//exprStr = `not search.ismatch('"${value}"', '${field.SearchFieldName}')`
			exprStr = `not search.ismatch('"${value}"', '${field.SearchFieldName}')`
			break
		/**
		 * ToDo: TASK 4004848 - Add support for 'Begins with' and 'Ends with' string operators
		 * ToDo: TASK 4004906 - Add support for 'In' and 'Not in' list operators
		 */
		case isAfterOperator:
			exprStr = `${field.SearchFieldName} gt ${toDayEnd(value)}`
			break
		case isBeforeOperator:
			exprStr = `${field.SearchFieldName} lt ${toDayStart(value)}`
			break
		case isOnOperator:
			exprStr = `(${field.SearchFieldName} ge ${toDayStart(value)} and ${field.SearchFieldName} le ${toDayEnd(value)})`
			break
		case isNotOperator:
			exprStr = `(${field.SearchFieldName} lt ${toDayStart(value)} or ${field.SearchFieldName} gt ${toDayEnd(value)})`
			break
		case notEqualsOperator:
			exprStr = `${field.SearchFieldName} ne ${value}`
			break
		case equalsOperator:
			const valueStr = field.FieldType === QueryPropsType.Text ? `'${value}'` : `${value}`
			exprStr = `${field.SearchFieldName} eq ${valueStr}`
			break
		case lessThanOperator:
			exprStr = `${field.SearchFieldName} lt ${value}`
			break
		case greaterThanOperator:
			exprStr = `${field.SearchFieldName} gt ${value}`
			break
		case lessThanOrEqualsOperator:
			exprStr = `${field.SearchFieldName} le ${value}`
			break
		case greaterThanOrEqualsOperator:
			exprStr = `${field.SearchFieldName} ge ${value}`
			break
		case isSetOperator:
			exprStr = `${field.SearchFieldName} ne null`
			break
		case isNotSetOperator:
			exprStr = `${field.SearchFieldName} eq null`
			break
		case inOperator:
			exprStr = getFilterStringForInOperator(expr, exprStr, field)
			break
		default:
			break
	}

	return `${exprStr}`
}
const getFilterStringForInOperator = (expr: QueryExpression, exprStr: string, field: DataField): string => {
	if (expr !== null) {
		const inputValues = expr.value.split(',').map((item) => item.trim())
		let c = inputValues.length
		for (const value of inputValues) {
			if (c > 1) {
				exprStr = exprStr + `${field.SearchFieldName} eq '${value}' or `
			} else {
				exprStr = exprStr + `${field.SearchFieldName} eq '${value}'`
			}
			c = c - 1
		}

		return `(` + exprStr + `)`
	}

	return exprStr
}
/**
 * @description Recursively walks expression tree to build filterString used by search API
 * @param node expression or expression group to stringify
 * @param siblingIndex position in parent group
 * @param metadataFields list of known metadata fields loaded at startup
 */
const filterStringBuilder = (node: QueryExpression, siblingIndex: number, metadataFields: DataField[]): string => {
	let filterStr = ''
	let andOrStr = ''
	let filterStr_token = ''
	if (node.children) {
		// if group
		if (siblingIndex !== 0) {
			// walk first children for grouping operator
			let firstChild = node.children[0]

			// fix error by sonic
			while (firstChild && firstChild.children) firstChild = firstChild.children[0]
			if (firstChild && firstChild.andOr) andOrStr = ` ${firstChild.andOr.toLocaleLowerCase()} `
		}
		// recurse each child
		let i = 0
		filterStr_token += `(`
		while (i < node.children.length) {
			const child = node.children[i]
			filterStr_token += filterStringBuilder(child, i, metadataFields)
			i += 1
		}
		filterStr_token += ')'
	} else {
		// elif expr
		if (siblingIndex !== 0) {
			andOrStr = ` ${node.andOr.toLowerCase()} `
		}
		filterStr_token = expressionToFilterString(node, metadataFields)
	}
	filterStr += `${andOrStr}${filterStr_token}`

	return filterStr
}

/**
 * Converts QueryRows into filter string passed to search API
 *
 * @param queryExpressions Tree of query expressions
 * @param metadataFields Metadata field definitions
 */
export const generateSearchStrings = (queryExpressions: QueryExpression, metadataFields: DataField[]) => {
	const searchStr = '*'
	const filterStr = filterStringBuilder(queryExpressions, 0, metadataFields)
	const excludeIsDeleted = `${filterStr ? ' and ' : ''}(IsDeleted eq 'No' and Metadata/ArticleStatus ne 'Archived')`

	// return {
	//   searchString: searchStr,
	//   filterString: filterStr + excludeBinaries
	// }

	return {
		searchString: searchStr,
		filterString: filterStr + excludeIsDeleted,
	}
}
export const generateOrderby = (sorting: ColumnSorting[], fields: DataField[]) => {
	const map = Object.fromEntries(fields.map((field) => [field.FieldName, field.SearchFieldName]))
	return sorting.map((val) => `${map[val.Props]} ${val.Sorting}`).join(',')
}
/**
 * Bulk edit operations
 */
export class QueryExpression {
	static nextID = 1

	// unique identifier used for comparison
	id: number

	// indicates whether the expression is selected for grouping
	group: boolean

	// indicates how the expression is grouped with the previous expression (And/Or)
	andOr: string

	// metadata field being filtered against
	field: string

	// type of the metadata field
	fieldType: string

	// filter operator (equals, not equals, greater than, etc.)
	operator: MetadataQueryOperator

	// value of the field to filter against
	value: string

	// null if single expression, list of expression if expression group
	children: QueryExpression[]

	// backwards link to parent node (inverse of children)
	parent: QueryExpression

	constructor() {
		this.id = QueryExpression.nextID
		QueryExpression.nextID += 1

		this.group = false
		this.andOr = null!
		this.field = null!
		this.fieldType = null!
		this.operator = null!
		this.value = null!
		this.children = null!
		this.parent = null!
	}
}
export type BulkOperation = 'remove' | 'update'

export interface FieldOptions extends IComboBoxOption {
	fieldType: string
	enumValues: string[]
	operation?: BulkOperation
	fieldName?: string
}
/**
 * @param root expression tree
 * @returns maximum depth of expression tree
 */
export const getMaxDepth = (root: QueryExpression): number => {
	let maxDepth = 0
	const leafNodes = getQueryRows(root)

	for (const node of leafNodes) {
		const depth = getExpressionDepth(node)
		if (depth > maxDepth) maxDepth = depth
	}

	return maxDepth
}
/**
 * @description Traverses expression tree and returns array of expressions (DFS order).
 * @param root expression tree
 * @returns array of expression found (ordered by DFS traversal)
 */
export const getQueryRows = (root: QueryExpression): (QueryExpression & { index: number })[] => {
	const numRows = count(root)
	const queryRows = [] as (QueryExpression & { index: number })[]
	for (let rowIndex = 0; rowIndex < numRows; rowIndex += 1) {
		queryRows.push(Object.assign({ index: rowIndex }, getExpression(root, rowIndex)))
	}

	return queryRows
}
/**
 * @param expr target expression
 * @returns depth of target expression
 */
export const getExpressionDepth = (expr: QueryExpression): number => {
	let depth = 0
	let curr = expr
	while (curr.parent) {
		depth += 1
		curr = curr.parent
	}

	return depth
}
/**
 * @description Walks expression tree and returns the expression at given rowIndex.
 * @param root expression tree
 * @param rowIndex Number of expressions away the target is (DFS)
 * @returns expression found at rowIndex
 */
export const getExpression = (root: QueryExpression, rowIndex: number): QueryExpression => {
	const nodesToProcess = []
	nodesToProcess.push(root)

	let expressionIndex = 0
	while (nodesToProcess.length > 0) {
		const curr = nodesToProcess.pop() as QueryExpression
		if (curr.children) {
			let childIndex = curr.children.length - 1
			while (childIndex >= 0) {
				nodesToProcess.push(curr.children[childIndex])
				childIndex -= 1
			}
		} else {
			if (expressionIndex === rowIndex) return curr
			expressionIndex += 1
		}
	}
	return undefined!
}
/**
 * @description Walks the expression tree and return the number of expressions found.
 * @param root expression tree
 * @returns number of expression in tree
 */
export const count = (root: QueryExpression): number => {
	const nodesToProcess = []
	nodesToProcess.push(root)

	let expressionCount = 0
	while (nodesToProcess.length > 0) {
		const curr = nodesToProcess.pop() as QueryExpression
		if (curr.children) {
			let childIndex = curr.children.length - 1
			while (childIndex >= 0) {
				nodesToProcess.push(curr.children[childIndex])
				childIndex -= 1
			}
		} else {
			expressionCount += 1
		}
	}

	return expressionCount
}
/**
 * @description traverses up a leaf node (expr) to find information about the groups it is a member of
 * @param expr target expression
 */
export const getGroupInfo = (expr: QueryExpression) => {
	const groups = []

	let curr = expr
	let prevLevel = 0
	// follow parent links to determing groups
	while (curr !== null) {
		const depth = getExpressionDepth(curr)
		// node is only a group if it has children (exclude root level)
		if (curr.children !== null && depth > 0) {
			const maxChildDepth = getMaxDepth(curr)
			const level = maxChildDepth - depth
			const category = level % MAX_GROUP_CATEGORIES
			const colSpan = level - prevLevel
			const position = getExpressionPositionWithinGroup(expr, curr)
			groups.unshift({
				node: curr,
				colSpan,
				category,
				position,
				whiteSpace: false,
			})
			prevLevel = level
		}
		curr = curr.parent
	}

	return groups
}

/**
 *
 * @param fieldsToExclude Array of strings to omit from the FieldOptions collection
 * @param allFields All available field search options
 */
export const excludeSearchOptions = (fieldsToExclude: string[], allFields: FieldOptions[]): FieldOptions[] =>
	fieldsToExclude ? allFields.filter((option) => !fieldsToExclude.includes(option['key'].toString())) : allFields
/**
 * @description Determines if node (expr) is the first or last node within a group when running DFS
 * @param expr target expression
 * @param group target group
 */
export const getExpressionPositionWithinGroup = (expr: QueryExpression, group: QueryExpression) => {
	// follow first child links
	let curr = group.children[0]
	while (curr.children) {
		curr = curr.children[0]
	}
	if (exprEquals(curr, expr)) return GROUP_START

	// follow last child links
	curr = group.children[group.children.length - 1]
	while (curr.children) {
		curr = curr.children[curr.children.length - 1]
	}
	if (exprEquals(curr, expr)) return GROUP_END

	// not start or end, must be mid
	return GROUP_MID
}
/**
 * @description Compares two expressions using their unique ID's
 * @param expr1 first expression
 * @param expr2 second expression
 * @returns id1 === id2
 */
export const exprEquals = (expr1: QueryExpression, expr2: QueryExpression) => expr1.id === expr2.id
/**
 * @description Removes expression from tree
 * @root expression tree
 * @rowIndex Position in QueryRows of expression to remove
 */
export const removeExpression = (root: QueryExpression, rowIndex: number) => {
	if (count(root) === 1) return // cannot remove final expression
	const expr = getExpression(root, rowIndex)
	if (rowIndex === 0) {
		const exprToBeFirst = getExpression(root, 1)
		exprToBeFirst.andOr = null!
	}
	const i = getPositionWithinParent(expr)
	const parent = expr.parent
	expr.parent = null!
	parent.children.splice(i, 1)
	if (parent.children.length === 1) {
		// need to collapse loneChild to parent
		const loneChild = parent.children[0]
		if (loneChild.children) {
			const newChildren = []
			let j = 0
			while (j < loneChild.children.length) {
				const child = loneChild.children[j]
				child.parent = parent
				newChildren.push(child)
				j += 1
			}
			parent.children = newChildren
		} else {
			parent.group = loneChild.group
			parent.andOr = loneChild.andOr
			parent.field = loneChild.field
			parent.fieldType = loneChild.fieldType
			parent.operator = loneChild.operator
			parent.value = loneChild.value
			parent.children = null!
		}
	}
}
/**
 * @description Returns the target expression's position within its parent's children array
 * @param expr target expression
 * @returns position within target expression's parent children array
 */
export const getPositionWithinParent = (expr: QueryExpression): number => _.findIndex(expr.parent.children, ['id', expr.id])
/**
 * @description Removes incomplete expressions from expression tree.
 * @param root expression tree
 */
export const removeInvalidExpressions = (root: QueryExpression) => {
	const nodesToProcess = []
	nodesToProcess.push(root)

	let expressionIndex = 0
	while (nodesToProcess.length > 0) {
		const curr = nodesToProcess.pop() as QueryExpression
		if (curr.children) {
			let childIndex = curr.children.length - 1
			while (childIndex >= 0) {
				nodesToProcess.push(curr.children[childIndex])
				childIndex -= 1
			}
		} else {
			if (isValid(curr, expressionIndex !== 0)) {
				expressionIndex += 1
			} else {
				removeExpression(root, expressionIndex)
			}
		}
	}
}

/**
 * @description Checks expression for required fields
 * @param expr target expression
 * @param checkAndOr Optional grouping operator check for expressions other than the first
 * @returns true if all required fields are set, and false otherwise
 */
export const isValid = (expr: QueryExpression, checkAndOr = true) => {
	if (expr.field && expr.operator) {
		if (expr.operator !== isSetOperator && expr.operator !== isNotSetOperator) {
			// value is required
			if (expr.value) {
				if (checkAndOr) {
					return expr.andOr ? true : false
				}

				return true
			} else {
				return false
			}
		}
		if (checkAndOr) {
			return expr.andOr ? true : false
		}

		return true
	}

	return false
}
/**
 * @description Traverses expression tree and returns array of selected expressions,
 * and their respective rowIndexes.
 * @param root expression tree
 * @returns array of expressions that have group field set to true (with their respective rowIndexes)
 */
export const getSelectedExpressions = (
	root: QueryExpression
): {
	expr: QueryExpression
	rowIndex: number
}[] => {
	let rowIndex = 0
	const selectedRows = []
	while (rowIndex < count(root)) {
		const expr = getExpression(root, rowIndex)
		if (expr.group)
			selectedRows.push({
				expr,
				rowIndex,
			})
		rowIndex += 1
	}

	return selectedRows
}
/**
 * @param expressions array of selected expressions
 * @returns true if all expressions in array form an expression group, and false otherwise
 */
export const isGroup = (expressions: QueryExpression[]): boolean => {
	let closestCommonAncestor = getClosestCommonAncestor(expressions[0], expressions[1])
	let i = 2
	while (i < expressions.length) {
		closestCommonAncestor = getClosestCommonAncestor(closestCommonAncestor, expressions[i])
		i += 1
	}
	if (!closestCommonAncestor.parent) return false
	if (!subTreeSelected(closestCommonAncestor)) return false

	return true
}
/**
 * @param expr1 first expression
 * @param expr2 second expression
 * @returns the closest common ancestor of two expressions
 */
export const getClosestCommonAncestor = (expr1: QueryExpression, expr2: QueryExpression): QueryExpression => {
	const ancestors1 = findAllAncestors(expr1)
	const ancestors2 = findAllAncestors(expr2)

	let i = 0
	while (i < ancestors1.length) {
		const ancestorA = ancestors1[i]
		const ancestorFound = ancestors2.find((expr) => expr.id === ancestorA.id)
		if (ancestorFound) return ancestorFound
		i += 1
	}
	return null!
}

/**
 * @description Traverses a subtree to see if all child expressions are selected
 * @param expr root of subtree
 * @returns true if all nodes under subtree are selected, and false otherwise
 */
export const subTreeSelected = (expr: QueryExpression): boolean => {
	const nodesToProcess = []
	nodesToProcess.push(expr)

	while (nodesToProcess.length > 0) {
		const curr = nodesToProcess.pop() as QueryExpression
		if (curr.children) {
			let childIndex = curr.children.length - 1
			while (childIndex >= 0) {
				nodesToProcess.push(curr.children[childIndex])
				childIndex -= 1
			}
		} else {
			if (!curr.group) return false
		}
	}

	return true
}
/**
 * @description Traverses expression tree upwards to find all ancestors.
 * @param expr target expression
 * @returns array of ancestors ordered from closest to furthest.
 */
export const findAllAncestors = (expr: QueryExpression): QueryExpression[] => {
	const ancestors = []
	ancestors.push(expr)
	let curr = expr.parent
	while (curr) {
		ancestors.push(curr)
		curr = curr.parent
	}

	return ancestors
}
/**
 * @description Walks expression tree and returns the node with the given ID.
 * @param root expression tree
 * @param id unique identifier
 * @returns expression found with ID
 */
export const findExpression = (root: QueryExpression, id: number): QueryExpression => {
	const nodesToProcess = []
	nodesToProcess.push(root)

	while (nodesToProcess.length > 0) {
		const curr = nodesToProcess.pop() as QueryExpression
		if (curr.id === id) return curr
		if (curr.children) {
			let childIndex = curr.children.length - 1
			while (childIndex >= 0) {
				nodesToProcess.push(curr.children[childIndex])
				childIndex -= 1
			}
		}
	}
	return null!
}
/**
 * @description Traverses expression tree to see if any expressions are valid.
 * @param root expression tree
 * @returns true if a valid expression is found, and false otherwise
 */
export const hasValidExpression = (root: QueryExpression): boolean => {
	const queryRows = getQueryRows(root)
	for (const row of queryRows) {
		if (isValid(row, false)) return true
	}

	return false
}
const _FixParent = (exp: QueryExpression, parent: QueryExpression) => {
	if (!exp.parent) exp.parent = parent
	if (exp.children) {
		exp.children.forEach((c) => _FixParent(c, exp))
	}
}
export const fixParent = (root: QueryExpression) => {
	if (root.children) {
		root.children.forEach((c) => _FixParent(c, root))
	}
}
