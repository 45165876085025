import { Api } from '@/Api'
import { MigrationArticle } from '@/Api/Types'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { Dialog, Button, DialogActions, DialogBody, DialogSurface, DialogTitle, DialogTrigger, DialogContent, Link } from '@fluentui/react-components'
import { DocumentTextLink20Regular, LinkRegular } from '@fluentui/react-icons'
import { useBoolean, useRequest } from 'ahooks'
import { useEffect, useRef } from 'react'
type Props = {
	item: MigrationArticle
}
export const PreviewArticle = ({ item }: Props) => {
	const ref = useRef<HTMLIFrameElement>(null)
	const url = `https://internal.evergreen.microsoft.com/en-us/topic/${item.articleSourceId ?? ''}`
	const [open, { set: setOpen }] = useBoolean(false)
	const {
		data: docment,
		loading,
		runAsync,
	} = useRequest(
		async () => {
			const res = await Api.Proxy.GetContent(url)
			const parser = new DOMParser()
			const parsedDocument = parser.parseFromString(res, 'text/html')
			return parsedDocument
		},
		{ manual: true }
	)
	useEffect(() => {
		if (open && url) {
			runAsync()
		}
	}, [open, runAsync, url])
	useEffect(() => {
		if (docment && ref.current) {
			const r = ref.current
			r.contentDocument!.head.innerHTML = `<base href="https://internal.evergreen.microsoft.com/" target="_blank"/>${docment.head.innerHTML}`
			r.contentDocument!.body.append(docment.body)
			r.contentDocument!.body.append('<style>#supAppliesToList{overflow: visible !important;}</style>')
		}
	}, [docment, ref])
	return (
		<Dialog onOpenChange={(e, d) => setOpen(d.open)} open={open}>
			<DialogTrigger disableButtonEnhancement>
				<Link
					href={`https://internal.evergreen.microsoft.com/en-us/topic/${item.articleSourceId ?? ''}`}
					target="_blank"
					onClick={(e) => {
						e.stopPropagation()
						e.preventDefault()
						setOpen(true)
					}}
					title="Preview original article"
				>
					<DocumentTextLink20Regular />
				</Link>
			</DialogTrigger>
			<DialogSurface className="preview-dialog">
				<DialogBody>
					<DialogTitle>
						Preview
						<Link href={url} target="_blank" style={{ verticalAlign: 'middle' }}>
							<LinkRegular />
						</Link>
					</DialogTitle>
					<DialogContent>
						{loading ? <LoadingSpinner /> : url && <iframe ref={ref} className="article-content" title="Preview"></iframe>}
					</DialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary">Close</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}
