import { Api } from '@/Api'
import { EContentCheckPoint, ContentCheckError, ContentCheckPoint, MigrationArticlesForConvert } from '@/Api/Types'
import { FormatDate } from '@/Utilities'
import {
	Body1,
	Body1Strong,
	Button,
	createTableColumn,
	DataGrid,
	DataGridBody,
	DataGridCell,
	DataGridHeader,
	DataGridHeaderCell,
	DataGridRow,
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Table,
	TableBody,
	TableCell,
	TableCellLayout,
	TableColumnDefinition,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from '@fluentui/react-components'
import { useRequest } from 'ahooks'
import { groupBy } from 'lodash'
import { makeStyles } from '@fluentui/react-components'

type Props = {
	item: MigrationArticlesForConvert
}
const checkpointMap = {
	[ContentCheckPoint.LMCMediaCheck]: 'Media Check',
	[ContentCheckPoint.Grammar]: 'Grammar',
} as { [key in EContentCheckPoint]: string }
const checkpoints = Object.entries(checkpointMap) as [EContentCheckPoint, string][]
const cp = checkpoints.map(([k]) => k)
const useStyles = makeStyles({
	result: { width: '450px', minWidth: '450px' },
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
	},
	grammerCheck: {
		wordBreak: 'break-all',
	},
})
type Item = [EContentCheckPoint, ContentCheckError[]]

export const AfterConvertCheckFailDialog = (props: Props) => {
	const classes = useStyles()
	const { data } = useRequest(
		() =>
			Api.ContentCheck.GetArticleContentCheck(props.item.id, cp).then((r) => {
				return Object.entries(groupBy(r, (r) => r.contentCheckPoint))
			}),
		{
			refreshDeps: [props.item.id],
		}
	)
	const errors = data ?? []
	const renderResult = (checkpoint: EContentCheckPoint, error: ContentCheckError[]) => {
		if (checkpoint === 'Grammar') {
			if (error)
				return (
					<Table>
						<TableHeader>
							<TableHeaderCell>Error Content</TableHeaderCell>
							<TableHeaderCell>Suggestion</TableHeaderCell>
						</TableHeader>
						<TableBody>
							{error.map((e) => (
								<TableRow key={e.id}>
									<TableCell>
										<Body1 wrap className={classes.grammerCheck}>
											{e.errorContent}
										</Body1>
									</TableCell>
									<TableCell>
										<Body1 wrap className={classes.grammerCheck}>
											{e.suggestion}
										</Body1>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				)
		} else return error[0]?.errorContent ?? 'Pass'
	}
	const columns: TableColumnDefinition<Item>[] = [
		createTableColumn<Item>({
			columnId: 'checkpoint',
			renderHeaderCell: () => {
				return <DataGridHeaderCell />
			},
			renderCell: (item) => {
				return (
					<DataGridCell>
						<TableCellLayout>{checkpointMap[item[0]]}</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Item>({
			columnId: 'checkDate',
			renderHeaderCell: () => {
				return <DataGridHeaderCell>Check Date</DataGridHeaderCell>
			},
			renderCell: (item) => {
				const first = item[1][0]
				return <DataGridCell>{FormatDate(first?.createdTime ?? props.item.lastModifiedTime)}</DataGridCell>
			},
		}),
		createTableColumn<Item>({
			columnId: 'result',
			renderHeaderCell: () => {
				return <DataGridHeaderCell className={classes.result}>Result</DataGridHeaderCell>
			},

			renderCell: (item) => {
				return <DataGridCell className={classes.result}>{renderResult(item[0], item[1])}</DataGridCell>
			},
		}),
	]

	return (
		<Dialog>
			<DialogTrigger disableButtonEnhancement>
				<Button appearance="outline" className="failed">
					Warning
				</Button>
			</DialogTrigger>
			<DialogSurface style={{ minWidth: '1000px' }}>
				<DialogBody>
					<DialogTitle>Check Error Detail:</DialogTitle>
					<DialogContent>
						<section className="grid-2">
							<section>
								<Body1Strong>Content ID:</Body1Strong>
								<Body1>{props.item.articleSourceId}</Body1>
							</section>
							<section>
								<Body1Strong>Author:</Body1Strong>
								<Body1>{props.item.sourceAuthor}</Body1>
							</section>
						</section>
						<DataGrid
							selectionAppearance="none"
							noNativeElements
							items={errors}
							columns={columns}
							focusMode="none"
							resizableColumnsOptions={{ autoFitColumns: true }}
						>
							<DataGridHeader>
								<DataGridRow>{({ renderHeaderCell }) => renderHeaderCell()}</DataGridRow>
							</DataGridHeader>
							<DataGridBody<Item>>
								{({ item, rowId }) => <DataGridRow<Item> key={rowId}>{({ renderCell }) => renderCell(item)}</DataGridRow>}
							</DataGridBody>
						</DataGrid>
					</DialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary">Close</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}
