import { ArticleForPublish } from '@/Api/Types'
import { FormatDate } from '@/Utilities'
import {
	createTableColumn,
	DataGridHeaderCell,
	DataGridCell,
	TableCellLayout,
	Link,
	TableColumnSizingOptions,
	mergeClasses,
} from '@fluentui/react-components'
import { useStepStyle } from '../step.styles'
import { PRLinkRender } from './PRLinkRender'
import { GridHeaderCell } from '../Components'

export const useDataGrid = () => {
	const baseClassses = useStepStyle()
	const columnSizing: TableColumnSizingOptions = {
		lineNumber: {
			defaultWidth: 35,
			minWidth: 35,
		},
		articleId: {
			defaultWidth: 200,
			minWidth: 200,
		},
		articleTitle: {
			defaultWidth: 200,
			minWidth: 200,
		},
	}
	const columnsDef = [
		createTableColumn<ArticleForPublish>({
			columnId: 'lineNumber',
			renderHeaderCell() {
				return <DataGridHeaderCell className={mergeClasses('no-border', baseClassses.lineNumber)} />
			},
			renderCell(item) {
				return <DataGridCell className={baseClassses.lineNumber}>{item.lineNumber}</DataGridCell>
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'articleId',
			renderHeaderCell() {
				return <GridHeaderCell content="Article ID" className="table-column-source" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						<TableCellLayout title={item.articleSourceId} truncate>
							{item.articleSourceId}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'articleTitle',
			renderHeaderCell() {
				return <GridHeaderCell content="Article Title" className="table-column-source" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						<TableCellLayout title={item.articleTitle} truncate>
							{item.articleTitle}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'sourceRevisionNumber',
			renderHeaderCell() {
				return <GridHeaderCell content="Revision Number" className="table-column-source" />
			},
			renderCell(item) {
				return <DataGridCell>{item.sourceRevisionNumber}</DataGridCell>
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'sourceAuthor',
			renderHeaderCell() {
				return <GridHeaderCell content="Authors" className="table-column-source" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						<TableCellLayout title={item.sourceAuthor} truncate>
							{item.sourceAuthor}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'sourceLastPublishDate',
			renderHeaderCell() {
				return <GridHeaderCell content="Last Publish Date" className="table-column-source" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						<TableCellLayout title={FormatDate(item.sourceLastPublishDate)} truncate>
							{FormatDate(item.sourceLastPublishDate)}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'pr',
			renderHeaderCell(data) {
				return <GridHeaderCell content="PR Link" className="table-column-system" />
			},
			renderCell(item) {
				return <PRLinkRender item={item} />
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'previewURL',
			renderHeaderCell() {
				return <GridHeaderCell content="Preview URL" className="table-column-system" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						{item.prNumber && item.reviewUrl && (
							<Link
								onClick={(e) => {
									e.stopPropagation()
								}}
								target="_blank"
								href={item.reviewUrl}
							>
								Preview
							</Link>
						)}
					</DataGridCell>
				)
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'review',
			renderHeaderCell() {
				return <GridHeaderCell content="Peer-review Status" className="table-column-system" />
			},
			renderCell(item) {
				return <DataGridCell>{item.prNumber && (item.reviewStatus ?? 'Pending')}</DataGridCell>
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'prStatus',
			renderHeaderCell() {
				return <GridHeaderCell content="PR Status" className="table-column-system" />
			},
			renderCell(item) {
				return <DataGridCell>{item.prStatus}</DataGridCell>
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'liveDate',
			renderHeaderCell() {
				return <GridHeaderCell content="Live Date" className="table-column-system" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						<TableCellLayout title={FormatDate(item.liveDate)} truncate>
							{FormatDate(item.liveDate)}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<ArticleForPublish>({
			columnId: 'liveUrl',
			renderHeaderCell() {
				return <GridHeaderCell content="Live URL" className="table-column-system" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						{item.prNumber && item.liveUrl && (
							<Link
								onClick={(e) => {
									e.stopPropagation()
								}}
								target="_blank"
								href={item.liveUrl}
							>
								Live
							</Link>
						)}
					</DataGridCell>
				)
			},
		}),
	]
	return {
		columns: columnsDef,
		columnSizingOptions: columnSizing,
	}
}
