import { EStep5Status, Step5StatsuOptions } from '@/Api/Types'
import { Input, Dropdown, Button, Option } from '@fluentui/react-components'
import { useState } from 'react'

export const useFilter = (search: () => void) => {
	const [articleId, setArticleId] = useState('')
	const [title, setTitle] = useState('')
	const [status, setStatus] = useState([] as string[])
	const reset = () => {
		setArticleId('')
		setTitle('')
		setStatus([])
		requestIdleCallback(() => {
			search()
		})
	}
	return [
		<>
			<Input placeholder="Article ID" value={articleId} onChange={(e, d) => setArticleId(d.value)} />
			<Input placeholder="Title" value={title} onChange={(e, d) => setTitle(d.value)} />
			<Dropdown
				selectedOptions={status}
				multiselect
				placeholder="Status"
				onOptionSelect={(e, d) => setStatus(d.selectedOptions)}
				title="Status"
			>
				{Object.entries(Step5StatsuOptions).map(([k, v]) => (
					<Option key={k} value={v.toString()}>
						{k}
					</Option>
				))}
			</Dropdown>
			<Button appearance="primary" onClick={search}>
				Search
			</Button>
			<Button onClick={reset}>Reset</Button>
		</>,
		{
			articleId,
			title,
			status: status.map((o) => Number(o) as EStep5Status),
			isConverted: true,
		},
	] as const
}
