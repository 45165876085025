import { Button, DataGrid, DataGridBody, DataGridHeader, DataGridRow } from '@fluentui/react-components'
import { StepProps } from '..'
import { useMigrationContext } from '../Context'
import { useEffect } from 'react'
import { MigrationActionStatus, MigrationStep } from '@/Api/Types'
import { observer } from 'mobx-react-lite'
import { Api } from '@/Api'
import { ToMap } from '@/Utilities'
import { ActionButton } from '@components/ActionButton'
import { usePagination } from 'ahooks'
import { Pagination } from '@components/Pagination'
import { runInAction } from 'mobx'
import { TableColorTooltip } from '../Components/TableColorTooltip'
import { useDataGrid } from './useDataGrid'
import { Article } from './type'
import { useInLoading } from '@/Hook/useInLoading'
import { useFilter } from './useFilter'
import { useDataGridSelected } from '@/Hook/useDataGridSelected'
import { useRootContext } from '@/Mobx'
import { LoadingSpinner } from '@components/LoadingSpinner'
export const Step3 = observer((props: StepProps) => {
	const context = useMigrationContext()
	const step3 = context.Step3
	const { loading: setLoading, finish } = useInLoading()
	const { notifications } = useRootContext()

	const { data, loading, runAsync, pagination } = usePagination(
		(param) => {
			return Api.MigrationTask.GetItems(context.MigrationId!, filterData, {
				pageNum: param.current,
				pageSize: param.pageSize,
			})
		},
		{ defaultPageSize: 10 }
	)
	const search = () => {
		runAsync({
			current: 1,
			pageSize: 10,
		})
	}
	const [component, filterData] = useFilter(search)
	useEffect(() => {
		if (data && data.list.length !== 0) {
			Api.MigrationTask.GetItemStatusAndDates(data.list.map((i) => i.id)).then((res) => {
				step3.StatusMap = ToMap(
					res,
					(i) => i.id,
					(i) => i.actions
				)
			})
		}
	}, [data, step3])
	const { columns, columnSizingOptions } = useDataGrid()
	const items = data?.list ?? []
	const [selectedRows, selectedProps, setSelectedRows] = useDataGridSelected()
	const getStatusLoop = (ids: Set<number>, notificationId: string) => {
		Api.MigrationTask.GetItemStatusAndDates([...ids], [MigrationStep.ArticleSync, MigrationStep.ContentCheck]).then((res) => {
			runInAction(() => {
				res.forEach((val) => {
					step3.StatusMap.set(val.id, val.actions)
				})
			})
			res.forEach((item) => {
				if (
					item.actions[MigrationStep.ContentCheck]?.status === MigrationActionStatus.Success ||
					item.actions[MigrationStep.ContentCheck]?.status === MigrationActionStatus.Failed ||
					item.actions[MigrationStep.ArticleSync]?.status === MigrationActionStatus.Failed
				) {
					ids.delete(item.id)
				}
			})
			setSelectedRows(ids)
			if (ids.size !== 0) {
				window.setTimeout(() => getStatusLoop(ids, notificationId), 1000)
			} else {
				finish()
				notifications.modifyNotification(notificationId, 'Success', 'Step 3: Retrieve and check finished.', 'Success')
			}
		})
	}
	const retrieveCheck = () => {
		const ids = [...selectedRows] as number[]
		setLoading()
		const id = notifications.addNotification('Information', 'Step 3: Retrieving and checking in progress', 'Loading')
		return Api.MigrationTask.RetrieveAndCheck(ids).then(() => {
			window.setTimeout(() => getStatusLoop(new Set(ids), id), 1000)
		})
	}
	const onNext = () => {
		context.TaskStatus.retrieved = 1
		finish()
		props.OnNext()
	}
	const canNext = (() => {
		if (!data || !data.list) return false
		return data.list.some((item) => {
			const status = step3.Status.get(item.id)
			return status?.CheckStatus === 'Success'
		})
	})()
	return (
		<section className="step step-3">
			<section className="step-body">
				<section className="step-filter">
					{component}
					<ActionButton appearance="primary" className="main-action" disabled={selectedRows.size === 0} onClick={retrieveCheck}>
						Retrieve and Check
					</ActionButton>
				</section>
				<DataGrid
					items={items}
					columns={columns}
					selectionMode="multiselect"
					getRowId={(item) => item.id}
					{...selectedProps}
					className="resizable-grid"
					columnSizingOptions={columnSizingOptions}
					resizableColumns
					resizableColumnsOptions={{
						autoFitColumns: false,
					}}
					focusMode="row_unstable"
				>
					<DataGridHeader>
						<DataGridRow
							selectionCell={{
								checkboxIndicator: { 'aria-label': 'Select all rows' },
							}}
						>
							{({ renderHeaderCell }) => renderHeaderCell()}
						</DataGridRow>
					</DataGridHeader>
					{loading ? (
						<LoadingSpinner />
					) : (
						<DataGridBody<Article>>
							{({ item, rowId }) => (
								<DataGridRow<Article>
									key={rowId}
									selectionCell={{
										checkboxIndicator: { 'aria-label': 'Select row' },
									}}
								>
									{({ renderCell }) => renderCell(item)}
								</DataGridRow>
							)}
						</DataGridBody>
					)}
				</DataGrid>
				<Pagination {...pagination} />
			</section>
			<section className="step-footer">
				<Button onClick={props.OnPrevious}>Previous</Button>
				<ActionButton appearance="primary" onClick={onNext} disabled={!canNext}>
					Next
				</ActionButton>
				<TableColorTooltip />
			</section>
		</section>
	)
})
