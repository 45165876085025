import { Api } from '@/Api'

export const GetConfig = () =>
	Api.Config.GetConfig('MS.Service', 'MS.Topic').then((r) => {
		return {
			'MS.Topic': r['MS.Topic'],
			'MS.Service': r['MS.Service'].map((s) => ({
				value: s,
				text: s,
			})),
		}
	})
export const GetTocs = (repoUrl: string, folder: string) => Api.Github.ListTocs(repoUrl, folder)
