import { Layout } from '@/Layout'
import { HomePage } from '@/Pages/Home/inded'
import { AllMigrationsPage, EditMigrationPage } from '@/Pages/Migrations'
import { GithubSettingPage } from '@/Pages/Settings'
import { TestPage } from '@/Pages/TestPage'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '/',
				Component: HomePage,
			},
			{
				path: 'migration',
				children: [
					{ path: '', Component: AllMigrationsPage },
					{ path: 'new', Component: EditMigrationPage },
					{ path: ':migrationId', Component: EditMigrationPage },
				],
			},
			{
				path: 'article',
			},
			{
				path: 'setting',
				children: [{ path: 'github', Component: GithubSettingPage }],
			},
			{
				path: 'test',
				Component: TestPage,
			},
		],
	},
])
export const AppRouter = () => {
	return <RouterProvider router={router} />
}
