/* eslint-disable react-hooks/exhaustive-deps */
import './ColumnOptionsDialog.scss'
import { useEffect, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { ColumnSorting, useQueryContext } from '@components/DataQuery/DataQueryContext'
import {
	Drawer,
	DrawerHeader,
	DrawerHeaderTitle,
	useRestoreFocusSource,
	Button,
	DrawerFooter,
	DrawerBody,
	Body1,
	Tab,
	TabList,
	SelectTabData,
	SelectTabEvent,
} from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { CustomableColumns } from './CustomableColumns'
import { DynamicColumn } from '@components/DataQuery/DataQueryContext/DataQueryResult'
import { autorun, toJS } from 'mobx'
import { uniqueId } from 'lodash'
import { SortableColumns } from './SortableColumns'
import { useAction } from '@/Hook/useAction'

export type CustomableColumnDef = DynamicColumn & {
	id: string
}
export type CustomableColumnsContext = {
	currentColumns: CustomableColumnDef[]
	set Columns(val: CustomableColumnDef[])
	get Columns(): CustomableColumnDef[]
}
export type SortableColumnDef = ColumnSorting & {
	id: string
}
export type SortableColumnsContext = {
	currentColumns: SortableColumnDef[]
	set Columns(val: SortableColumnDef[])
	get Columns(): SortableColumnDef[]
}
export const ColumnOptionsDialog = observer(() => {
	const context = useQueryContext()
	const [selectedValue, setSelectedValue] = useState('columns' as 'columns' | 'sorting')

	const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
		setSelectedValue(data.value as 'columns' | 'sorting')
	}
	const restoreFocusSourceAttributes = useRestoreFocusSource()

	const onColumnOptionsClose = () => {
		context.SetColumnOptionsDialogVisible(false)
	}

	const columnsStore = useLocalObservable<CustomableColumnsContext>(() => {
		return {
			currentColumns: toJS(context.DataQueryResult.Columns).map((columns) => ({
				...columns,
				id: uniqueId(),
			})),
			set Columns(val: CustomableColumnDef[]) {
				this.currentColumns = val
			},
			get Columns() {
				return this.currentColumns
			},
		}
	})
	const sortableColumnsStore = useLocalObservable<SortableColumnsContext>(() => {
		return {
			currentColumns: toJS(context.ColumnSorting).map((columns) => ({
				...columns,
				id: uniqueId(),
			})),
			set Columns(val: SortableColumnDef[]) {
				this.currentColumns = val
			},
			get Columns() {
				return this.currentColumns
			},
		}
	})
	useEffect(() => {
		return autorun(() => {
			columnsStore.Columns = toJS(context.DataQueryResult.Columns).map((columns) => ({
				...columns,
				id: uniqueId(),
			}))
		})
	}, [])
	useEffect(() => {
		return autorun(() => {
			sortableColumnsStore.Columns = toJS(context.ColumnSorting).map((columns) => ({
				...columns,
				id: uniqueId(),
			}))
		})
	}, [])
	const onOK = useAction(() => {
		const map = Object.fromEntries(context.Fields.map((f) => [f.FieldName, f]))
		context.DataQueryResult.Columns = columnsStore.Columns.filter((c) => c.Props).map((column) => {
			const field = map[column.Props]
			return {
				Text: field.DisplayName ?? column.Props,
				Props: column.Props,
				Width: 100,
				DisplayName: field.DisplayName,
			}
		})
		context.ColumnSorting = sortableColumnsStore.Columns.filter((c) => c.Props).map(({ id, ...prop }) => ({ ...prop }))
		onColumnOptionsClose()
	})
	if (!context.ShowColumnOptionsDialog) return <></>

	return (
		<Drawer
			className="column-options"
			type="overlay"
			separator
			open={context.ShowColumnOptionsDialog}
			{...restoreFocusSourceAttributes}
			position="end"
			size="medium"
		>
			<DrawerHeader>
				<DrawerHeaderTitle
					action={
						<Button
							appearance="subtle"
							aria-label="Close"
							icon={<Dismiss24Regular />}
							onClick={() => context.SetColumnOptionsDialogVisible(false)}
						/>
					}
				>
					Column Options
				</DrawerHeaderTitle>
			</DrawerHeader>
			<DrawerBody>
				<Body1>Add or remove columns. To change the column order, drag and drop a field.</Body1>
				<TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
					<Tab value="columns">Columns</Tab>
					<Tab value="sorting">Sorting</Tab>
				</TabList>
				{selectedValue === 'columns' && <CustomableColumns store={columnsStore} />}
				{selectedValue === 'sorting' && <SortableColumns store={sortableColumnsStore} />}
			</DrawerBody>

			<DrawerFooter>
				<Button onClick={onOK} appearance="primary">
					OK
				</Button>
				<Button onClick={onColumnOptionsClose}>Cancel</Button>
			</DrawerFooter>
		</Drawer>
	)
})
