import { useQueryContext } from '@components/DataQuery/DataQueryContext'
import { ArticleData } from '@components/DataQuery/DataQueryContext/ArticleData'
import { DataGridCell, TableCellLayout } from '@fluentui/react-components'
import { observer } from 'mobx-react-lite'

export const StatusRender = observer(({ item }: { item: ArticleData }) => {
	const context = useQueryContext()
	const status = context.GetRowReviewStatus(item['Metadata.ContentID'])
	return (
		<DataGridCell>
			<TableCellLayout truncate>{status}</TableCellLayout>
		</DataGridCell>
	)
})
