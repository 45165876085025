import { Body1, makeStyles, mergeClasses, tokens, Tooltip } from '@fluentui/react-components'
import { Info16Filled, Info16Regular } from '@fluentui/react-icons'
import { useToggle } from 'ahooks'
import { PropsWithChildren } from 'react'
type Props = PropsWithChildren<{ tooltipClass?: string; onClick?: () => void; visible?: boolean }>
const useStyle = makeStyles({
	hover: {
		color: tokens.colorNeutralForeground2BrandHover,
	},
	content: {
		display: 'inline-flex',
		alignItems: 'center',
		cursor: 'pointer',
		marginLeft: tokens.spacingHorizontalSNudge,
	},
})
export const InfoIcon = (props: Props) => {
	const [isHover, { set }] = useToggle()
	const classes = useStyle()

	return (
		<Tooltip
			content={{
				children: props.children,
				className: props.tooltipClass,
			}}
			relationship="description"
			visible={props.visible}
		>
			<Body1 onMouseEnter={() => set(true)} onMouseLeave={() => set(false)} className={mergeClasses(classes.content, isHover && classes.hover)}>
				{isHover ? <Info16Filled /> : <Info16Regular />}
			</Body1>
		</Tooltip>
	)
}
