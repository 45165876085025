import { EMigrationActionStatus } from '@/Api/Types'
type OnNavigateResult =
	| { Skip: true }
	| { Skip: false; Can: true; Success: true }
	| { Skip: false; Can: false; WhyCant?: string }
	| { Skip: false; Can: true; Success: false; WhyFail?: string }
export const NavigateResult = {
	Skip: { Skip: true } as const,
	Success: { Skip: false, Can: true, Success: true } as const,
	Cant: (whycant?: string) => ({ Skip: false, Can: false, WhyCant: whycant } as const),
	Fail: (whyfail?: string) => ({ Skip: false, Can: true, Success: false, WhyFail: whyfail } as const),
}
export interface StepContext {
	OnNavigate: () => Promise<OnNavigateResult>
}
export type StatusAndDates = {
	errorDetail?: string
	finishTime?: string
	status: EMigrationActionStatus
}
export type Status<T extends string[]> = {
	[K in T[number] as `${K}Status`]: EMigrationActionStatus
} & {
	[K in T[number] as `${K}EndTime`]?: string
} & {
	[K in T[number] as `${K}Error`]?: string
}
