import {
	Body1,
	Persona,
	Popover,
	PopoverSurface,
	PopoverTrigger,
	Link,
	ToggleButton,
	Drawer,
	DrawerHeader,
	DrawerHeaderTitle,
	Button,
	DrawerBody,
	makeStyles,
	FluentProvider,
	webDarkTheme,
} from '@fluentui/react-components'
import './MenuBar.css'
import { msalInstance } from '../..'
import { Toast, ToastBody, ToastTitle, useToastController } from '@fluentui/react-components'
import { AlertFilled, AlertRegular, DismissRegular } from '@fluentui/react-icons'
import { useToggle } from 'ahooks'
import { NotificationCenter } from '@components/NotificationCenter'
const useStyle = makeStyles({
	icon: {
		color: 'white',
	},
	barContainer: {
		height: '46px',
		backgroundColor: '#212121',
		padding: '0px 14px 0px 24px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	title: {
		margin: '0px 8px',
		color: 'white',
	},
	itemContainer: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
	},
})
const darkTheme = webDarkTheme
darkTheme.colorNeutralBackground1 = '#212121'
export const MenuBar = () => {
	const classes = useStyle()
	const { dispatchToast } = useToastController()
	const userAccount = msalInstance.getActiveAccount()!
	const [showNotification, { toggle: toggleNotifications, set: setNotifications }] = useToggle()
	const logout = () => {
		msalInstance.logoutPopup()
		dispatchToast(
			<Toast>
				<ToastTitle>Success</ToastTitle>
				<ToastBody>You signed out of your account.</ToastBody>
			</Toast>,
			{ intent: 'success' }
		)
	}
	return (
		<section>
			<section className={classes.barContainer}>
				<section className={classes.itemContainer}>
					<section className="ms-log" />
					<Body1 className={classes.title}>Partner Support Content Management</Body1>
				</section>
				<section className={classes.itemContainer}>
					<ToggleButton
						appearance="transparent"
						checked={showNotification}
						icon={showNotification ? <AlertFilled /> : <AlertRegular />}
						onClick={() => toggleNotifications()}
						className={classes.icon}
						title="Notifications"
					/>
					<Popover>
						<PopoverTrigger>
							<FluentProvider theme={darkTheme}>
								<Persona name={userAccount.name} textAlignment="center" />
							</FluentProvider>
						</PopoverTrigger>
						<PopoverSurface>
							<div className="msacct-callout-inner">
								<div className="callout-header">
									<div className="callout-out">
										<div>
											<Body1>Microsoft</Body1>
										</div>
										<div>
											<Link onClick={logout} target="_blank">
												Sign Out
											</Link>
										</div>
									</div>
									<div style={{ gridColumn: '1 / 3' }}>
										<Persona
											size="large"
											name={userAccount.name}
											primaryText={userAccount.name}
											secondaryText={userAccount.username}
										/>
									</div>
								</div>
							</div>
						</PopoverSurface>
					</Popover>
				</section>
			</section>
			<Drawer open={showNotification} position="end" size="medium">
				<DrawerHeader>
					<DrawerHeaderTitle
						action={
							<Button
								appearance="subtle"
								title="Close"
								aria-label="Close"
								icon={<DismissRegular />}
								onClick={() => setNotifications(false)}
							/>
						}
					>
						Notifications
					</DrawerHeaderTitle>
				</DrawerHeader>
				<DrawerBody>
					<NotificationCenter />
				</DrawerBody>
			</Drawer>
		</section>
	)
}
