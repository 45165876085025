import { Docset, EPermissionType, MigrationTask, MigrationTaskModel, PermissionType } from '@/Api/Types'
import { makeAutoObservable } from 'mobx'
import { NavigateResult, StepContext } from './Types'
import { RootConfig } from '@/Config/Const'
import { NewMigrationContext } from '.'
import { stringify } from 'flatted'
import { Api } from '@/Api'
import { GetRoot } from '@/Mobx'

export class Step1Context implements StepContext {
	public constructor(private root: NewMigrationContext) {
		this.name = ''
		this.description = ''
		this.disclaimer = RootConfig.DefaultDisclaimer
		this.defaultPermissionType = PermissionType.Private
		this.repoUrl = ''
		this.docset = { folder: '', name: '', permissionedType: '' }
		this.docsetDescription = ''
		this.initTask = {} as any
		makeAutoObservable(this)
	}
	public get IsEdited(): boolean {
		return !(
			this.name === this.InitTask.migrationName &&
			this.description === this.InitTask.description &&
			this.disclaimer === this.InitTask.defaultDisclaimer &&
			this.defaultPermissionType === this.InitTask.permissionType &&
			this.repoUrl === this.InitTask.targetRepoUrl &&
			this.docset.name === this.InitTask.targetDocSetName
		)
	}
	async OnNavigate() {
		if (this.CanNext) {
			if (!this.IsEdited) {
				return NavigateResult.Skip
			}
			try {
				await this.CreateOrModify()
				return NavigateResult.Success
			} catch (e) {
				return NavigateResult.Fail(String(e))
			}
		}
		return NavigateResult.Cant('There are still required fields that have not been filled in.')
	}
	CanNavigate() {
		return this.CanNext
	}
	private async CreateOrModify() {
		if (this.root.MigrationId) await this.modify(this.root.MigrationId)
		else await this.create()
	}
	public async OnNext() {
		if (!this.IsEdited) {
			return
		}
		await this.CreateOrModify()
	}
	public async create() {
		const model: MigrationTaskModel = {
			migrationName: this.Name,
			permissionType: this.DefaultPermissionType,
			targetDocSetUrl: this.Docset.folder,
			targetDocSetName: this.Docset.name,
			description: this.Description,
			defaultDisclaimer: this.Disclaimer,
			ownerMail: this.root.Username,
			createdBy: this.root.Username,
			targetRepoUrl: this.RepoUrl,
			articleFilter: JSON.stringify({
				Expression: stringify(this.root.Step2.Expression),
				...this.root.Step2.QueryData,
			}),
		}
		const id = GetRoot().notifications.addNotification(`Create migration(${this.Name})`, 'Processing...', 'Loading')
		await Api.MigrationTask.CreateMigration(model).then((migrationId) => {
			GetRoot().notifications.modifyNotification(id, `Create migration(${this.Name})`, 'Success', 'Success')
			this.root.MigrationId = migrationId
			this.InitTask = model as any
		})
	}
	public async modify(migrationId: number) {
		const model: MigrationTaskModel = {
			migrationName: this.Name,
			permissionType: this.DefaultPermissionType,
			targetDocSetUrl: this.Docset.folder,
			targetDocSetName: this.Docset.name,
			description: this.Description,
			defaultDisclaimer: this.Disclaimer,
			ownerMail: this.root.Username,
			createdBy: this.root.Username,
			targetRepoUrl: this.RepoUrl,
			articleFilter: JSON.stringify({
				Expression: stringify(this.root.Step2.Expression),
				...this.root.Step2.QueryData,
			}),
		}
		const id = GetRoot().notifications.addNotification(`Update migration(${this.Name})`, 'Processing...', 'Loading')
		await Api.MigrationTask.ModifyMigration(migrationId, model).then((migrationId) => {
			GetRoot().notifications.modifyNotification(id, `Update migration(${this.Name})`, 'Success', 'Success')
			this.InitTask = model as any
		})
	}
	public get WhyCant() {
		if (!this.CanNext) return 'There are still required fields that have not been filled in.'
		return ''
	}
	public get CanMoveTo(): boolean {
		return true
	}
	public get CanNext() {
		return !!(this.Name && this.Description && this.DefaultPermissionType && this.RepoUrl && this.Docset.folder)
	}
	private name: string
	public get Name(): string {
		return this.name
	}
	public set Name(v: string) {
		this.name = v
	}

	private description: string
	public get Description(): string {
		return this.description
	}
	public set Description(v: string) {
		this.description = v
	}

	private disclaimer: string
	public get Disclaimer(): string {
		return this.disclaimer
	}
	public set Disclaimer(v: string) {
		this.disclaimer = v
	}

	private defaultPermissionType: EPermissionType
	public get DefaultPermissionType(): EPermissionType {
		return this.defaultPermissionType
	}
	public set DefaultPermissionType(v: EPermissionType) {
		this.defaultPermissionType = v
	}

	private repoUrl: string
	public get RepoUrl(): string {
		return this.repoUrl
	}
	public set RepoUrl(v: string) {
		if (v === this.repoUrl) return
		this.Docset = {
			folder: '',
			name: '',
			permissionedType: '',
		}
		this.repoUrl = v
	}

	private docset: Docset
	public get Docset(): Docset {
		return this.docset
	}
	public set Docset(v: Docset) {
		this.docset = v
	}

	private docsetDescription: string
	public get DocsetDescription(): string {
		return this.docsetDescription
	}
	public set DocsetDescription(v: string) {
		this.docsetDescription = v
	}

	private initTask: MigrationTask
	public get InitTask() {
		return this.initTask
	}
	public set InitTask(v: MigrationTask) {
		this.initTask = v
	}
}
