import { Api } from '@/Api'
import { EReviewResult, ReviewStatus, ReviewResult } from '@/Api/Types'
import { ArticleData } from '@components/DataQuery/DataQueryContext/ArticleData'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { Dialog, Button, DialogActions, DialogBody, DialogSurface, DialogTitle, DialogTrigger, DialogContent, Link } from '@fluentui/react-components'
import { LinkRegular } from '@fluentui/react-icons'
import { useRequest, useToggle } from 'ahooks'
import { useEffect, useRef, useState } from 'react'
type Props = {
	open: boolean
	onClose: (result?: EReviewResult) => Promise<void>
	taskId?: number
	row?: ArticleData | null
}
export const PreviewAction = (props: Props) => {
	const ref = useRef<HTMLIFrameElement>(null)
	const url = props.row?.Url ?? ''
	const [loading, { set }] = useToggle()
	const [isReviewing, { set: setIsReviewing }] = useToggle()
	const [doc, setDoc] = useState<Document | null>(null)
	useEffect(() => {
		if (props.open && url) {
			set(true)
			Api.Proxy.GetContent(url).then((res) => {
				const parser = new DOMParser()
				const parsedDocument = parser.parseFromString(res, 'text/html')
				setDoc(parsedDocument)

				set(false)
			})
		}
	}, [props.open, set, url])
	useEffect(() => {
		if (doc && ref.current) {
			const r = ref.current
			r.contentDocument!.head.innerHTML = `<base href="https://internal.evergreen.microsoft.com/" target="_blank"/>${doc.head.innerHTML}`
			r.contentDocument!.body.append(doc.body)
			const style = r.contentDocument!.createElement('style')
			style.innerHTML = '#supAppliesToList{overflow: visible !important;}'
			r.contentDocument!.body.appendChild(style)
		}
	}, [doc, ref])
	const { data: isConverted } = useRequest(
		() => {
			if (props.taskId && props.row?.['Metadata.ContentID'])
				return Api.MigrationTask.IsItemConverted(props.taskId, props.row?.['Metadata.ContentID'])
			return Promise.resolve(true)
		},
		{
			refreshDeps: [props.taskId, props.row],
		}
	)
	const wrapperCloseEvent = (func: () => Promise<void>) => {
		return () => {
			setIsReviewing(true)
			return func().finally(() => {
				setIsReviewing(false)
			})
		}
	}
	return (
		<Dialog open={props.open}>
			<DialogSurface className="preview-dialog ">
				<DialogBody>
					<DialogTitle>
						Preview
						<Link href={url} target="_blank" style={{ verticalAlign: 'middle' }}>
							<LinkRegular />
						</Link>
					</DialogTitle>
					<DialogContent>
						{loading ? <LoadingSpinner /> : url && <iframe ref={ref} className="article-content" title="Preview"></iframe>}
					</DialogContent>
					<DialogActions>
						<Button
							appearance="primary"
							onClick={wrapperCloseEvent(() => props.onClose(ReviewResult.Include))}
							disabled={isConverted || isReviewing}
						>
							{ReviewStatus.Include}
						</Button>
						<Button
							appearance="primary"
							onClick={wrapperCloseEvent(() => props.onClose(ReviewResult.Exclude))}
							disabled={isConverted || isReviewing}
						>
							{ReviewStatus.Exclude}
						</Button>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary" onClick={() => props.onClose()}>
								Close
							</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}
