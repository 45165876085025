import { NotificationItem, useRootContext } from '@/Mobx'
import {
	Body1,
	Body1Strong,
	Body1Stronger,
	Button,
	Card,
	CardFooter,
	CardHeader,
	makeStyles,
	Menu,
	MenuButtonProps,
	MenuItem,
	MenuList,
	MenuPopover,
	MenuTrigger,
	mergeClasses,
	SplitButton,
	tokens,
} from '@fluentui/react-components'
import { AlertRegular, CheckmarkFilled, DismissRegular, ErrorCircleRegular, WarningRegular } from '@fluentui/react-icons'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
const useClass = makeStyles({
	noNotifications: {
		display: 'flex',
		padding: tokens.spacingHorizontalXXXL,
		flexDirection: 'column',
		alignItems: 'center',
	},
	icon: {
		fontSize: '10em',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	itemContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	itemIcon: {
		fontSize: '2em',
		fontWeight: tokens.strokeWidthThickest,
		margin: tokens.spacingVerticalS,
		flexShrink: 0,
	},
	iconSuccess: {
		color: tokens.colorPaletteGreenForeground2,
	},
	iconError: {
		color: tokens.colorPaletteRedForeground2,
	},
	iconWarning: {
		color: tokens.colorPaletteYellowForeground2,
	},
})

const NotificationCard = observer(({ item }: { item: NotificationItem }) => {
	const { notifications } = useRootContext()
	const classes = useClass()

	const icon = (() => {
		switch (item.status) {
			case 'Loading':
			case 'Info':
				return ''
			case 'Fail':
				return <ErrorCircleRegular className={mergeClasses(classes.itemIcon, classes.iconError)} />
			case 'Success':
				return <CheckmarkFilled className={mergeClasses(classes.itemIcon, classes.iconSuccess)} />
			case 'Warning':
				return <WarningRegular className={mergeClasses(classes.itemIcon, classes.iconWarning)} />
		}
	})()
	return (
		<Card appearance="subtle">
			<CardHeader
				header={<Body1Strong>{item.title}</Body1Strong>}
				action={
					<Button
						appearance="transparent"
						title="Dismiss notification"
						icon={<DismissRegular />}
						onClick={(e) => notifications.dismiss(item.id)}
					/>
				}
			/>
			<section className={classes.itemContainer}>
				{icon}
				<Body1>{item.content}</Body1>
			</section>
			<CardFooter action={moment(item.createAt).fromNow()} />
		</Card>
	)
})

export const NotificationCenter = observer(() => {
	const { notifications } = useRootContext()
	const classes = useClass()
	if (notifications.Notifications.length === 0)
		return (
			<section className={classes.noNotifications}>
				<AlertRegular className={classes.icon} />
				<Body1Stronger>No new notifications from this session</Body1Stronger>
			</section>
		)
	return (
		<section>
			<section className={classes.container}>
				<Menu positioning="below-end">
					<MenuTrigger disableButtonEnhancement>
						{(triggerProps: MenuButtonProps) => (
							<SplitButton
								size="small"
								menuButton={triggerProps}
								primaryActionButton={{
									onClick: notifications.dismissAll,
								}}
							>
								Dismiss all
							</SplitButton>
						)}
					</MenuTrigger>

					<MenuPopover>
						<MenuList>
							<MenuItem onClick={notifications.dismissInformational}>Dismiss informational</MenuItem>
							<MenuItem onClick={notifications.dismissCompleted}>Dismiss completed</MenuItem>
						</MenuList>
					</MenuPopover>
				</Menu>
			</section>
			<hr />
			<section>
				{notifications.Notifications.map((i) => (
					<NotificationCard key={i.id} item={i} />
				))}
			</section>
			<hr />
		</section>
	)
})
