import { Button, DataGridCell, SkeletonItem, TableCellLayout } from '@fluentui/react-components'
import { observer } from 'mobx-react-lite'
import { Article } from './types'
import { AfterConvertCheckFailDialog } from './AfterConvertCheckFailDialog'
import { ConvertFailDialog } from './ConvertFailDialog'
import { useMigrationContext } from '../Context'

export const StatusRender = observer(({ item, statusType }: { item: Article; statusType: 'Convert' | 'Check' }) => {
	const { Step5 } = useMigrationContext()
	const status = Step5.Status.get(item.id) ?? { CheckStatus: 'Pending', ConvertStatus: 'Pending' }
	let children = <></>
	switch (status[statusType === 'Convert' ? 'ConvertStatus' : 'CheckStatus']) {
		case 'Pending':
		case undefined:
			children = <Button appearance="transparent">Pending</Button>
			break
		case 'Processing':
			children = <SkeletonItem />
			break
		case 'Failed':
			children = statusType === 'Convert' ? <ConvertFailDialog /> : <AfterConvertCheckFailDialog item={item} />
			break
		case 'Success':
			children = <Button appearance="transparent">Success</Button>
			break
		case 'Warning':
			children = <AfterConvertCheckFailDialog item={item} />
	}
	return (
		<DataGridCell>
			<TableCellLayout
				title={status[statusType === 'Convert' ? 'ConvertStatus' : 'CheckStatus']}
				truncate
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				{children}
			</TableCellLayout>
		</DataGridCell>
	)
})
