import { types } from 'mobx-state-tree'
import { NotificationCenter } from './Notifications'
import { useContext, createContext } from 'react'

export const RootStore = types.model('Root', {
	notifications: NotificationCenter,
})
const root = RootStore.create({
	notifications: {
		notifications: [],
	},
})
export const RootContext = createContext(root)
export const useRootContext = () => useContext(RootContext)
export const GetRoot = () => root
export type { NotificationItem } from './Notifications'
