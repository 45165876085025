import './DataQueryCommandBar.scss'
import { observer } from 'mobx-react-lite'
import { Toolbar, ToolbarButton } from '@fluentui/react-components'
import { Play24Regular, DocumentTableSearch24Regular, Wrench24Regular, ArrowDownload24Regular } from '@fluentui/react-icons'
import { useQueryContext } from '@components/DataQuery/DataQueryContext'
import { useRootContext } from '@/Mobx'
import { msalInstance } from '@/index'

export const DataQueryCommandBar = observer(() => {
	const context = useQueryContext()
	const { notifications } = useRootContext()
	const onRun = async () => {
		context.FetchArticleData()
	}
	const onExport = async () => {
		const id = notifications.addNotification('Information', 'Step 2: Exporting.', 'Loading')

		const articles = await context.GetExportArticles()
		const userAccount = msalInstance.getActiveAccount()!
		const email = userAccount.username
		let alias = ''
		if (email.includes('@')) {
			const sp = email.split('@')
			alias = sp[0]
		}
		await context.Export(articles.Topics, `${context.Root.Step1.Name} - ${alias}`)
		notifications.modifyNotification(id, 'Success', 'Step 2: Exported.', 'Success')
	}
	return (
		<>
			<Toolbar className="sonic-toolbar">
				<ToolbarButton aria-label="Run" appearance="subtle" icon={<Play24Regular />} onClick={onRun}>
					Run
				</ToolbarButton>
				<ToolbarButton
					aria-label="Column Option"
					appearance="subtle"
					icon={<Wrench24Regular />}
					onClick={() => context.SetColumnOptionsDialogVisible(true)}
				>
					Column Options
				</ToolbarButton>
				<ToolbarButton
					aria-label="Field Dictionary"
					appearance="subtle"
					icon={<DocumentTableSearch24Regular />}
					as="a"
					href="https://aka.ms/ACCTFieldDictionary"
					target="_blank"
				>
					Field Dictionary
				</ToolbarButton>
				<ToolbarButton aria-label="Export Result" appearance="subtle" icon={<ArrowDownload24Regular />} onClick={onExport}>
					Export Result
				</ToolbarButton>
			</Toolbar>
		</>
	)
})
