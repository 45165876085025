import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'

const useStyle = makeStyles({
	container: {
		marginLeft: 'auto',
		display: 'grid',
		gridTemplateColumns: '50px auto',
		gap: tokens.spacingVerticalXXS,
	},
	source: {
		backgroundColor: '#92d050',
	},
	system: {
		backgroundColor: '#057605',
	},
	color: {
		display: 'block',
	},
})
export const TableColorTooltip = () => {
	const classes = useStyle()
	return (
		<section className={classes.container}>
			<section className={mergeClasses(classes.source, classes.color)} />
			<section>Fields retrieved from the source system</section>
			<section className={mergeClasses(classes.system, classes.color)} />
			<section>Fields generated in CMT</section>
		</section>
	)
}
