import { Config } from './Config'
import { ContentCheck } from './ContentCheck'
import { Github } from './github'
import { MigrationArticle } from './MigrationArticle'
import { MigrationTask } from './MigrationTask'
import { MsacctAPI } from './MsacctAPI'
import { Proxy } from './Proxy'
import { Sonic } from './SonicProxy'
import { Test } from './Test'

export const Api = {
	Github,
	MsacctAPI: new MsacctAPI(),
	MigrationTask,
	Sonic,
	Config,
	Proxy,
	MigrationArticle,
	ContentCheck,
	Test,
} as const
