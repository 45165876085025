/* eslint-disable react-hooks/exhaustive-deps */
import { SonicQuery } from '@components/DataQuery'
import { Button } from '@fluentui/react-components'
import { StepProps } from '..'
import { observer } from 'mobx-react-lite'
import { QueryContext } from '@components/DataQuery/DataQueryContext'
import { useMigrationContext } from '../Context'
import { Api } from '@/Api'
import { sonicMsalInstance } from '@/index'
import { AuthenticatedTemplate, MsalProvider, useMsalAuthentication } from '@azure/msal-react'
import { useEffect } from 'react'
import { InteractionType } from '@azure/msal-browser'
import { ActionButton } from '@components/ActionButton'
import { runInAction } from 'mobx'
export const InnerStep2 = observer((props: StepProps) => {
	const context = useMigrationContext()
	const queryContext = context.Step2
	const onNext = async () => {
		await Api.MigrationTask.FinishSelecteArticle(context.MigrationId!).then(() => {
			context.TaskStep = 'Retrieve articles'
			runInAction(() => {
				context.TaskStatus.included = 1
				queryContext.InitQueryData.Excludes = new Set(queryContext.QueryData.Excludes)
				queryContext.InitQueryData.Includes = new Set(queryContext.QueryData.Includes)
			})
			props.OnNext()
		})
	}
	useEffect(() => {
		Api.MigrationTask.GetItems(context.MigrationId!, { onlyIncluded: false }, { pageNum: -1, pageSize: 0 }).then(async (res) => {
			const includes = res.list.filter((i) => !i.isExcluded).map((i) => i.articleSourceId)
			const excludes = res.list.filter((i) => i.isExcluded).map((i) => i.articleSourceId)
			runInAction(() => {
				queryContext.QueryData.Excludes = new Set(excludes)
				queryContext.QueryData.Includes = new Set(includes)
				queryContext.InitQueryData.Excludes = new Set(excludes)
				queryContext.InitQueryData.Includes = new Set(includes)
			})
			await queryContext.LoadPreSelectArticles(res.list.map((i) => i.articleSourceId))
			await queryContext.FetchArticleData()
		})
	}, [context.MigrationId])
	return (
		<MsalProvider instance={sonicMsalInstance}>
			<section className="step">
				<QueryContext.Provider value={queryContext}>
					<SonicQuery />
				</QueryContext.Provider>
				<section className="step-footer">
					<Button onClick={props.OnPrevious}>Previous</Button>
					<ActionButton appearance="primary" disabled={!queryContext.CanNext} onClick={onNext} title={queryContext.WhyCantNext}>
						Next
					</ActionButton>
				</section>
			</section>
		</MsalProvider>
	)
})
const Wrapper = (props: StepProps) => {
	useMsalAuthentication(InteractionType.Redirect)
	return (
		<AuthenticatedTemplate>
			<InnerStep2 {...props} />
		</AuthenticatedTemplate>
	)
}
export const Step2 = (props: StepProps) => {
	return (
		<MsalProvider instance={sonicMsalInstance}>
			<Wrapper {...props} />
		</MsalProvider>
	)
}
