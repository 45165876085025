import { Body1, Button, makeStyles, Popover, PopoverSurface, PositioningImperativeRef, Subtitle1, Tab, tokens } from '@fluentui/react-components'
import { useRef, useEffect } from 'react'

type Props = {
	value: string
	disabled: boolean
	content: string
	showPopover: boolean
	tabIndex?: number
}
const useStyle = makeStyles({
	content: {
		maxWidth: '20em',
	},
	button: {
		marginRight: tokens.spacingHorizontalXS,
	},
})
export const TabWithPopover = (prop: Props) => {
	const buttonRef = useRef<HTMLButtonElement>(null)
	const positioningRef = useRef<PositioningImperativeRef>(null)
	const classes = useStyle()
	useEffect(() => {
		if (buttonRef.current) {
			positioningRef.current?.setTarget(buttonRef.current)
		}
	}, [buttonRef, positioningRef])
	return (
		<section>
			<Tab ref={buttonRef} value={prop.value} disabled={prop.disabled}>
				<Body1>{prop.content}</Body1>
			</Tab>
			<Popover trapFocus open={prop.showPopover} positioning={{ positioningRef }} withArrow>
				<PopoverSurface>
					<section>
						<Subtitle1>Attention</Subtitle1>
						<p className={classes.content}>
							<Body1>
								You are about to leave this page. Unsaved work will be saved automatically. Click 'Save & Move' to continue or
								'Cancel' to stay on the current page.
							</Body1>
						</p>
					</section>

					<section>
						<Button appearance="primary" className={classes.button}>
							Save & Move
						</Button>
						<Button>Cancel</Button>
					</section>
				</PopoverSurface>
			</Popover>
		</section>
	)
}
