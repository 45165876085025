import { NavDrawer, NavDrawerBody, NavDrawerFooter, NavItem, OnNavItemSelectData } from '@fluentui/react-nav-preview'
import { useNavigate } from 'react-router-dom'
import { EventHandler } from '@fluentui/react-utilities'
import './fabric-icons-inline.scss'
import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { useEffect, useState } from 'react'
import { ChevronDoubleLeftRegular, ChevronDoubleRightRegular } from '@fluentui/react-icons'
const query = 'screen and (max-width: 1000px) '
const useClass = makeStyles({
	nav: {
		height: '100%',
		transition: 'all 0.3s',
		maxWidth: '180px',
	},
	footer: {
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '2em',
	},
	icon: {
		minHeight: 'unset',
	},
	body: {
		overflow: 'hidden',
	},
	hide: {
		maxWidth: '4em',
	},
})
export const SideNav = () => {
	const navigate = useNavigate()
	const classes = useClass()
	const [isOpen, setIsOpen] = useState(true)
	const [match, setMatch] = useState(() => {
		return matchMedia(query).matches
	})
	useEffect(() => {
		const queryList = matchMedia(query)
		const updateMatch = () => {
			setMatch(!queryList.matches)
		}

		updateMatch()
		queryList.addEventListener('change', updateMatch)

		return () => {
			queryList.removeEventListener('change', updateMatch)
		}
	}, [])
	useEffect(() => {
		setIsOpen(!match)
	}, [match])
	const onNavItemSelect: EventHandler<OnNavItemSelectData> = (ev, data) => {
		navigate(data.value)
		ev.preventDefault()
	}
	return (
		<NavDrawer type="inline" multiple onNavItemSelect={onNavItemSelect} open className={mergeClasses(classes.nav, isOpen && classes.hide)}>
			<NavDrawerBody className={classes.body}>
				<NavItem href="/" value="/" role="button" title="Home">
					<i className="ms-Icon ms-Icon--Home" /> Home
				</NavItem>
				<NavItem href="/migration" value="/migration" role="button" title="Migrate">
					<i className="ms-Icon ms-Icon--PageEdit" /> Migrate
				</NavItem>
			</NavDrawerBody>
			<NavDrawerFooter>
				{isOpen && (
					<NavItem
						className={classes.footer}
						icon={<ChevronDoubleRightRegular className={classes.icon} />}
						as="button"
						value="hide"
						title="Show more information"
						onClick={(e) => {
							setIsOpen(false)
							e.stopPropagation()
							e.preventDefault()
						}}
					/>
				)}
				{!isOpen && (
					<NavItem
						className={classes.footer}
						icon={<ChevronDoubleLeftRegular className={classes.icon} />}
						as="button"
						value="hide"
						title="Show less information"
						onClick={(e) => {
							setIsOpen(true)
							e.stopPropagation()
							e.preventDefault()
						}}
					/>
				)}
			</NavDrawerFooter>
		</NavDrawer>
	)
}
