import { observer } from 'mobx-react-lite'
import { StepProps } from '..'
import { Button, DataGrid, DataGridBody, DataGridHeader, DataGridRow } from '@fluentui/react-components'
import { Pagination } from '@components/Pagination'
import { Api } from '@/Api'
import { ArticleForPublish } from '@/Api/Types'
import { usePagination } from 'ahooks'
import { useMigrationContext } from '../Context'
import { useRootContext } from '@/Mobx'
import { ActionButton } from '@components/ActionButton'
import { TableColorTooltip } from '../Components'
import { useDataGrid } from './useDataGrid'
import { useFilter } from './useFilter'
import { useDataGridSelected } from '@/Hook/useDataGridSelected'
import { LoadingSpinner } from '@components/LoadingSpinner'
export const Step6 = observer((props: StepProps) => {
	const { notifications } = useRootContext()
	const context = useMigrationContext()
	const { columns, columnSizingOptions } = useDataGrid()
	const { data, loading, runAsync, pagination } = usePagination(
		async (param) => {
			return Api.MigrationArticle.GetMigrationArticlesForPublish(context.MigrationId!, filterData, {
				pageNum: param.current,
				pageSize: param.pageSize,
			})
		},
		{ defaultPageSize: 10, debounceWait: 200 }
	)
	const items = data?.list ?? []
	const [component, filterData] = useFilter(() => {
		runAsync({
			current: 1,
			pageSize: 10,
		})
	})
	const [selectedRows, selectedProps] = useDataGridSelected()
	const createPr = () => {
		const rows = [...selectedRows] as number[]
		if (rows.length === 0) return
		const id = notifications.addNotification('Info', 'Step 6: Create pull request in progress.', 'Info')
		return Api.MigrationArticle.CreatePR(rows).then((r) => {
			notifications.modifyNotification(id, 'Success', 'Step 6: Pull request created', 'Success')
			runAsync(pagination)
		})
	}
	return (
		<section className="step">
			<section className="step-body">
				<section className="step-filter">
					{component}
					<ActionButton appearance="primary" className="main-action" onClick={createPr}>
						Create pull request
					</ActionButton>
				</section>
				<DataGrid
					items={items}
					columns={columns}
					selectionMode="multiselect"
					getRowId={(item) => item.id}
					{...selectedProps}
					className="resizable-grid"
					columnSizingOptions={columnSizingOptions}
					resizableColumns
					resizableColumnsOptions={{
						autoFitColumns: false,
					}}
					focusMode="row_unstable"
				>
					<DataGridHeader>
						<DataGridRow
							selectionCell={{
								checkboxIndicator: { 'aria-label': 'Select all rows' },
							}}
						>
							{({ renderHeaderCell }) => renderHeaderCell()}
						</DataGridRow>
					</DataGridHeader>
					{loading ? (
						<LoadingSpinner />
					) : (
						<DataGridBody<ArticleForPublish>>
							{({ item, rowId }) => (
								<DataGridRow<ArticleForPublish>
									key={rowId}
									selectionCell={{
										checkboxIndicator: { 'aria-label': 'Select row' },
									}}
								>
									{({ renderCell }) => renderCell(item)}
								</DataGridRow>
							)}
						</DataGridBody>
					)}
				</DataGrid>
				<Pagination {...pagination} />
			</section>
			<section className="step-footer">
				<Button onClick={props.OnPrevious}>Previous</Button>
				<TableColorTooltip />
			</section>
		</section>
	)
})
