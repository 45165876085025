import { MapType } from '@/Api/Types'
import { DispatchToast, UpdateToast } from '@components/GlobalToaster'
import { Toast, ToastBody, ToastIntent, ToastTitle } from '@fluentui/react-components'
import { uniqueId } from 'lodash'
import { Instance, types } from 'mobx-state-tree'
import moment from 'moment'
type ToastChangeHandler = NonNullable<Parameters<typeof DispatchToast>[1]>['onStatusChange']
const NotificationStatus = {
	Loading: 'Loading',
	Success: 'Success',
	Fail: 'Fail',
	Info: 'Info',
	Warning: 'Warning',
} as const
type ENotificationStatus = MapType<typeof NotificationStatus>
const statusMap = (status: ENotificationStatus): ToastIntent => {
	const map = {
		Fail: 'error',
		Info: 'info',
		Loading: 'info',
		Success: 'success',
	} as { [key in ENotificationStatus]: ToastIntent }
	return map[status] ?? 'info'
}
const notificationItem = types
	.model('NotificationItem', {
		id: types.identifier,
		status: types.enumeration<ENotificationStatus>(Object.values(NotificationStatus)),
		title: types.string,
		content: types.string,
		createAt: types.Date,
		isMounted: types.boolean,
	})
	.views((self) => ({
		get DisplayTime() {
			return moment(self.createAt).fromNow()
		},
	}))
	.actions((self) => ({
		UpdateIsMounted(b: boolean) {
			self.isMounted = b
		},
	}))
export type NotificationItem = Instance<typeof notificationItem>
export const NotificationCenter = types
	.model('NotificationCenter', {
		notifications: types.array(notificationItem),
	})
	.views((self) => ({
		get Notifications() {
			return self.notifications
		},
	}))
	.actions((self) => {
		const onToastStatusChange: ToastChangeHandler = (e, data) => {
			const notification = self.Notifications.find((n) => n.id === data.toastId)
			if (!notification) return
			notification.UpdateIsMounted(data.status === 'visible')
		}
		return {
			addNotification(title: string, content: string, status: ENotificationStatus) {
				const notification = {
					id: uniqueId('n'),
					status,
					title,
					content,
					createAt: new Date(),
					isMounted: true,
				}
				self.Notifications.push(notification)
				DispatchToast(
					<Toast>
						<ToastTitle>{title}</ToastTitle>
						<ToastBody>{content}</ToastBody>
					</Toast>,
					{
						intent: statusMap(status),
						toastId: notification.id,
						onStatusChange: onToastStatusChange,
					}
				)
				return notification.id
			},
			modifyNotification(id: string, title: string, content: string, status: ENotificationStatus) {
				if (status !== NotificationStatus.Success && status !== NotificationStatus.Fail) return
				const notification = self.Notifications.find((n) => n.id === id)

				if (!notification) return
				notification.title = title
				notification.content = content
				notification.status = status
				if (notification.isMounted) {
					UpdateToast({
						toastId: notification.id,
						content: (
							<Toast>
								<ToastTitle>{title}</ToastTitle>
								<ToastBody>{content}</ToastBody>
							</Toast>
						),
						intent: statusMap(status),
					})
				} else {
					DispatchToast(
						<Toast>
							<ToastTitle>{title}</ToastTitle>
							<ToastBody>{content}</ToastBody>
						</Toast>,
						{
							intent: statusMap(status),
						}
					)
				}
			},
			dismiss(id: string) {
				const notification = self.Notifications.find((n) => n.id === id)
				if (!notification) return
				self.notifications.remove(notification)
			},
			dismissAll() {
				self.notifications.clear()
			},
			dismissCompleted() {
				self.notifications.forEach((n) => {
					if (n.status === 'Success') self.notifications.remove(n)
				})
			},
			dismissInformational() {
				self.notifications.forEach((n) => {
					if (n.status === 'Info') self.notifications.remove(n)
				})
			},
		}
	})
