import { makeStyles, tokens } from '@fluentui/react-components'

export const useStepStyle = makeStyles({
	lineNumber: {
		width: '35px',
		maxWidth: '35px',
	},
	header: {
		fontWeight: tokens.fontWeightSemibold,
	},
})
