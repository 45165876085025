import { FormatDate } from '@/Utilities'
import {
	createTableColumn,
	DataGridHeaderCell,
	DataGridCell,
	TableCellLayout,
	TableColumnSizingOptions,
	mergeClasses,
} from '@fluentui/react-components'
import { DateRender } from './DateRender'
import { StatusRender } from './StatusRender'
import { useStepStyle } from '../step.styles'
import { Article } from './type'
import { GridHeaderCell } from '../Components'
export const useDataGrid = () => {
	const baseClassses = useStepStyle()
	const columnSizing: TableColumnSizingOptions = {
		lineNumber: {
			defaultWidth: 35,
			minWidth: 35,
		},
		articleId: {
			defaultWidth: 250,
			minWidth: 250,
		},
		articleTitle: {
			defaultWidth: 250,
			minWidth: 250,
		},
		sourceAuthor: {
			defaultWidth: 200,
			minWidth: 200,
		},
	}
	const columnsDef = [
		createTableColumn<Article>({
			columnId: 'lineNumber',
			renderHeaderCell() {
				return <DataGridHeaderCell className={mergeClasses('no-border', baseClassses.lineNumber)} />
			},
			renderCell(item) {
				return <DataGridCell className={baseClassses.lineNumber}>{item.lineNumber}</DataGridCell>
			},
		}),
		createTableColumn<Article>({
			columnId: 'articleId',
			renderHeaderCell() {
				return <GridHeaderCell content="Article ID" className="table-column-source" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						<TableCellLayout title={item.articleSourceId} truncate>
							{item.articleSourceId}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'articleTitle',
			renderHeaderCell() {
				return <GridHeaderCell content="Article Title" className="table-column-source" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						<TableCellLayout title={item.articleTitle} truncate>
							{item.articleTitle}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'sourceRevisionNumber',
			renderHeaderCell() {
				return <GridHeaderCell content="Revision Number" className="table-column-source" />
			},
			renderCell(item) {
				return <DataGridCell>{item.articleRevisionNumber ?? 0}</DataGridCell>
			},
		}),
		createTableColumn<Article>({
			columnId: 'sourceAuthor',
			renderHeaderCell() {
				return <GridHeaderCell content="Authors" className="table-column-source" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						<TableCellLayout title={item.articleAuthor} truncate>
							{item.articleAuthor}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'sourceLastPublishDate',
			renderHeaderCell() {
				return <GridHeaderCell content="Last Publish Date" className="table-column-source" />
			},
			renderCell(item) {
				return (
					<DataGridCell>
						<TableCellLayout title={FormatDate(item.articleLastPublishDate)} truncate>
							{FormatDate(item.articleLastPublishDate)}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'retrieveDate',
			renderHeaderCell(data) {
				return <GridHeaderCell content="Retrieve Date" className="table-column-system" />
			},
			renderCell(item) {
				return <DateRender item={item} statusType="Retrieve" />
			},
		}),
		createTableColumn<Article>({
			columnId: 'retrieveStatus',
			renderHeaderCell() {
				return <GridHeaderCell content="Retrieve Status" className="table-column-system" />
			},
			renderCell(item) {
				return <StatusRender item={item} statusType="Retrieve" />
			},
		}),
		createTableColumn<Article>({
			columnId: 'checkDate',
			renderHeaderCell(data) {
				return <GridHeaderCell content="Check Date" className="table-column-system" />
			},
			renderCell(item) {
				return <DateRender item={item} statusType="Check" />
			},
		}),
		createTableColumn<Article>({
			columnId: 'checkStatus',
			renderHeaderCell() {
				return <GridHeaderCell content="Check Status" className="table-column-system" />
			},
			renderCell(item) {
				return <StatusRender item={item} statusType="Check" />
			},
		}),
	]
	return {
		columns: columnsDef,
		columnSizingOptions: columnSizing,
	}
}
