import { getAppInsights } from '@components/DataQuery/Utilities/TelemetryService'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { Pagination, PaginationResponse } from './Types'

export type OperationResult<T> =
	| { success: true; result: T }
	| {
			success: false
			errorCode: number
			errorMessage: string
	  }
let appInsight = getAppInsights()
type PaginationResult<T> = {
	total: number
	list: (T & { lineNumber: number })[]
}
export const WrapperPagination = <T>(res: PaginationResponse<T>, page: Pagination): PaginationResult<T> => {
	const list = res.searchResult as Array<T & { lineNumber: number }>
	let i = (page.pageNum - 1) * page.pageSize + 1
	list.forEach((r) => {
		r.lineNumber = i
		i++
	})
	return { total: res.pagination.total, list }
}
export const WrapperFetch = <T>() => {
	return (response: Response) => {
		if (!response.ok) {
			appInsight.trackException({
				error: new Error(`${response.url} ${response.status}`),
				severityLevel: SeverityLevel.Error,
			})
			Promise.reject(response)
		}
		return new Promise<T>((resolve, reject) => {
			response
				.json()
				.then((r) => r as OperationResult<T>)
				.then((result) => {
					if (result.success) resolve(result.result)
					else {
						appInsight.trackException({
							error: new Error(`${response.url} ${JSON.stringify(result)}`),
							severityLevel: SeverityLevel.Error,
						})
						reject(result)
					}
				})
		})
	}
}
