import { observer } from 'mobx-react-lite'
import { StepProps } from '..'
import { ToMap } from '@/Utilities'
import { Button, DataGrid, DataGridBody, DataGridHeader, DataGridRow } from '@fluentui/react-components'
import { useMigrationContext } from '../Context'
import { useEffect } from 'react'
import { MigrationActionStatus, MigrationStep } from '@/Api/Types'
import { Api } from '@/Api'
import { ActionButton } from '@components/ActionButton'
import { usePagination } from 'ahooks'
import { Pagination } from '@components/Pagination'
import { runInAction } from 'mobx'
import { TableColorTooltip } from '../Components'
import { useDataGrid } from './useDataGrid'
import { Article } from './types'
import { useInLoading } from '@/Hook/useInLoading'
import { useFilter } from './useFilter'
import { useDataGridSelected } from '@/Hook/useDataGridSelected'
import { useRootContext } from '@/Mobx'
import { LoadingSpinner } from '@components/LoadingSpinner'

export const Step5 = observer((props: StepProps) => {
	const context = useMigrationContext()
	const step5 = context.Step5
	const { columns, columnSizingOptions } = useDataGrid()
	const { loading: setLoading, finish } = useInLoading()
	const { notifications } = useRootContext()
	const { data, loading, runAsync, pagination } = usePagination(
		(param) => {
			return Api.MigrationArticle.GetMigrationArticlesForConvert(context.MigrationId!, filterData, {
				pageNum: param.current,
				pageSize: param.pageSize,
			})
		},
		{ defaultPageSize: 10, debounceWait: 200 }
	)
	const items = data?.list ?? []
	const [selectedRows, selectedProps, setSelectedRows] = useDataGridSelected()
	useEffect(() => {
		if (items.length !== 0)
			Api.MigrationArticle.GetArticleStatusAndDates(items.map((i) => i.id)).then((res) => {
				step5.StatusMap = ToMap(
					res,
					(i) => i.articleId,
					(i) => i.actions
				)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items])
	const search = () => {
		runAsync({
			current: 1,
			pageSize: 10,
		})
	}
	const [component, filterData] = useFilter(search)

	const GetStatusLoop = (ids: Set<number>, id: string) => {
		Api.MigrationArticle.GetArticleStatusAndDates([...ids], [MigrationStep.FormatConvert, MigrationStep.CheckAfterConvert]).then((res) => {
			runInAction(() => {
				res.forEach((val) => {
					step5.StatusMap.set(val.articleId, val.actions)
				})
			})
			res.forEach((item) => {
				if (
					item.actions[MigrationStep.CheckAfterConvert]?.status !== MigrationActionStatus.Success &&
					item.actions[MigrationStep.CheckAfterConvert]?.status !== MigrationActionStatus.Warning
				) {
				} else {
					ids.delete(item.articleId)
					runAsync({
						current: pagination.current,
						pageSize: pagination.pageSize,
					})
					runInAction(() => {
						context.TaskStatus.converted = 1
					})
				}
			})
			setSelectedRows(ids)
			if (ids.size !== 0) {
				window.setTimeout(() => GetStatusLoop(ids, id), 2000)
			} else {
				finish()
				notifications.modifyNotification(id, 'Success', 'Step 5: Convert articles finished.', 'Success')
			}
		})
	}
	const convertCheck = async () => {
		const ids = [...selectedRows] as number[]
		const id = notifications.addNotification('Information', 'Step 5: Converting in progress.', 'Loading')

		setLoading()
		await Api.MigrationArticle.ConvertCheck(ids)
		window.setTimeout(() => GetStatusLoop(new Set(ids), id), 2000)
	}
	return (
		<section className="step">
			<section className="step-body">
				<section className="step-filter">
					{component}
					<ActionButton appearance="primary" className="main-action" disabled={selectedRows.size === 0} onClick={convertCheck}>
						Convert
					</ActionButton>
				</section>
				<DataGrid
					items={items}
					columns={columns}
					selectionMode="multiselect"
					getRowId={(item) => item.id}
					{...selectedProps}
					className="resizable-grid"
					columnSizingOptions={columnSizingOptions}
					resizableColumns
					resizableColumnsOptions={{
						autoFitColumns: false,
					}}
					focusMode="row_unstable"
				>
					<DataGridHeader>
						<DataGridRow
							selectionCell={{
								checkboxIndicator: { 'aria-label': 'Select all rows' },
							}}
						>
							{({ renderHeaderCell }) => renderHeaderCell()}
						</DataGridRow>
					</DataGridHeader>
					{loading ? (
						<LoadingSpinner />
					) : (
						<DataGridBody<Article>>
							{({ item, rowId }) => (
								<DataGridRow<Article>
									key={rowId}
									selectionCell={{
										checkboxIndicator: { 'aria-label': 'Select row' },
									}}
								>
									{({ renderCell }) => renderCell(item)}
								</DataGridRow>
							)}
						</DataGridBody>
					)}
				</DataGrid>
				<Pagination {...pagination} />
			</section>
			<section className="step-footer">
				<Button onClick={props.OnPrevious}>Previous</Button>
				<Button appearance="primary" onClick={props.OnNext}>
					Next
				</Button>
				<TableColorTooltip />
			</section>
		</section>
	)
})
