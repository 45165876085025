import { observer } from 'mobx-react-lite'
import { Body1, DataGridCell, SkeletonItem, TableCellLayout } from '@fluentui/react-components'
import { FormatDate } from '@/Utilities'
import { useMigrationContext } from '../Context'
import { MigrationItem } from '@/Api/Types'
export const DateRender = observer(({ item, statusType }: { item: MigrationItem; statusType: 'Retrieve' | 'Check' }) => {
	const { Step3 } = useMigrationContext()
	const status = Step3.Status.get(item.id) ?? { CheckStatus: 'Pending', RetrieveStatus: 'Pending' }
	let children = <></>
	let time = ''
	switch (status[statusType === 'Retrieve' ? 'RetrieveStatus' : 'CheckStatus']) {
		case 'Processing':
			children = <SkeletonItem />
			break
		case 'Failed':
		case 'Warning':
		case 'Success':
			time = FormatDate(status[statusType === 'Retrieve' ? 'RetrieveEndTime' : 'CheckEndTime'])
			children = <Body1>{time}</Body1>
			break
	}
	return (
		<DataGridCell>
			<TableCellLayout title={time} truncate>
				{children}
			</TableCellLayout>
		</DataGridCell>
	)
})
