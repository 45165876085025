/* eslint-disable react-hooks/exhaustive-deps */
import { TabList, SelectTabData, SelectTabEvent, Subtitle2 } from '@fluentui/react-components'
import { useEffect, useState } from 'react'
import { Step1 } from './Step1'
import './NewMigration.scss'
import { Step2 } from './Step2'
import { Context, NewMigrationContext, steps, Steps } from './Context'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Step3 } from './Step3'
import { autorun } from 'mobx'
import { Step4 } from './Step4'
import { Step5 } from './Step5'
import { Step6 } from './Step6'
import { StepContext } from './Context/Types'
import { msalInstance } from '@/index'
import { useInLoading } from '@/Hook/useInLoading'
import { TabWithPopover } from './Components'
import { useRootContext } from '@/Mobx'
import { LoadingSpinner } from '@components/LoadingSpinner'
export type StepProps = {
	OnNext: () => void
	OnPrevious: () => void
}

const components = {
	[steps[0]]: Step1,
	[steps[1]]: Step2,
	[steps[2]]: Step3,
	[steps[3]]: Step4,
	[steps[4]]: Step5,
	[steps[5]]: Step6,
}
const isStepContext = (obj: unknown): obj is StepContext => {
	return !!obj && typeof obj === 'object' && 'OnNavigate' in obj && !!obj.OnNavigate
}
export const NewMigration = observer(() => {
	const userAccount = msalInstance.getActiveAccount()!
	const { notifications } = useRootContext()
	const [currentStep, setStep] = useState<Steps>('Config the migration')
	const routerParams = useParams() as { migrationId?: string }
	const context = useLocalObservable(() => new NewMigrationContext(routerParams, userAccount))
	const navigate = useNavigate()
	let [searchParams, setSearchParams] = useSearchParams()
	const findStepIndex = (step: Steps) => steps.indexOf(step)
	const { finish } = useInLoading()

	const showPopover = useLocalObservable(() => ({
		[steps[0]]: false,
		[steps[1]]: false,
		[steps[2]]: false,
		[steps[3]]: false,
		[steps[4]]: false,
		[steps[5]]: false,
	}))
	useEffect(() => {
		const index = findStepIndex(searchParams.get('step') as Steps)
		if (index === -1) {
			setSearchParams(
				{
					step: steps[0],
				},
				{ replace: true }
			)
		} else setStep(steps[index])
	}, [searchParams])
	const navigateToStep = (step: Steps) => {
		finish()
		setSearchParams(
			{
				step: step,
			},
			{ replace: true }
		)
	}
	const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
		const index = findStepIndex(currentStep) as 0 | 1 | 2 | 3 | 4
		const contextName = `Step${index + 1}` as 'Step1' | 'Step2' | 'Step3' | 'Step4' | 'Step5'
		const stepContext = context[contextName]
		if (isStepContext(stepContext)) {
			stepContext
				.OnNavigate()
				.then((result) => {
					if (result.Skip) return true
					if (result.Can) {
						if (result.Success) {
							return true
						} else {
							if (result.WhyFail) notifications.addNotification('Failed', result.WhyFail, 'Fail')
						}
					} else {
						if (result.WhyCant) notifications.addNotification('Failed', result.WhyCant, 'Fail')
					}
				})
				.then((r) => {
					if (r) navigateToStep(data.value as Steps)
				})
		} else {
			navigateToStep(data.value as Steps)
		}
	}
	const onNext = () => {
		const index = findStepIndex(currentStep)
		navigateToStep(steps[index + 1])
	}
	const onPrevious = () => {
		const index = findStepIndex(currentStep)
		navigateToStep(steps[index - 1])
	}
	const Component = components[currentStep]
	const shouldDisable = (step: Steps) => {
		switch (step) {
			case steps[0]:
				return false
			case steps[1]:
				return context.MigrationId === null
			case steps[2]:
				return context.TaskStatus.included === 0
			case steps[3]:
				return context.TaskStatus.retrieved === 0
			case steps[4]:
				return context.TaskStatus.configured === 0
			case steps[5]:
				return context.TaskStatus.converted === 0
		}
		return true
	}
	useEffect(() => {
		return autorun(() => {
			if (context.MigrationId && !routerParams.migrationId) {
				navigate(`/migration/${context.MigrationId}`, {
					replace: true,
				})
			}
		})
	}, [])
	return (
		<Context.Provider value={context}>
			<Subtitle2 className="migration-title">
				<span>
					{routerParams.migrationId ? 'Current' : 'New'} Migration - {currentStep}
				</span>
				<span className="main-action">{context.Step1.Name ?? ''}</span>
			</Subtitle2>
			<TabList selectedValue={currentStep} onTabSelect={onTabSelect}>
				{steps.map((step, i) => (
					<TabWithPopover
						key={step}
						value={step}
						disabled={shouldDisable(step)}
						content={`${i + 1}. ${step}`}
						showPopover={showPopover[step]}
					/>
				))}
			</TabList>
			{!context.FinishLoading ? <LoadingSpinner /> : <Component OnNext={onNext} OnPrevious={onPrevious} />}
		</Context.Provider>
	)
})
