import ReactDOM from 'react-dom/client'
import './index.scss'
import './layout.scss'
import reportWebVitals from './reportWebVitals'
import { msalConfig } from './Config/MsalConfig'
import { AccountInfo, EventType, PublicClientApplication } from '@azure/msal-browser'
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { SonicMsalConfig } from './Config/SonicMsalConfig'
import { App } from './App'
import React from 'react'

export const msalInstance = new PublicClientApplication(msalConfig)
export const sonicMsalInstance = new PublicClientApplication(SonicMsalConfig)

const initPublicClientApplication = (instance: PublicClientApplication) => {
	if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
		// Account selection logic is app dependent. Adjust as needed for different use cases.
		instance.setActiveAccount(instance.getAllAccounts()[0])
	}

	// Optional - This will update account state if a user signs in from another tab or window
	instance.enableAccountStorageEvents()

	instance.addEventCallback((event) => {
		const payload = event.payload as { account: AccountInfo }
		if (event.eventType === EventType.LOGIN_SUCCESS && payload.account) {
			const account = payload.account
			instance.setActiveAccount(account)
		}
	})
}
initPublicClientApplication(msalInstance)
initPublicClientApplication(sonicMsalInstance)
// Default to using the first account if no account is active on page load

initializeIcons()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
