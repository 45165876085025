import { FormatDate } from '@/Utilities'
import {
	createTableColumn,
	DataGridHeaderCell,
	DataGridCell,
	TableCellLayout,
	TableColumnSizingOptions,
	Body1,
	Link,
	mergeClasses,
} from '@fluentui/react-components'

import { useStepStyle } from '../step.styles'
import { Article, Config } from './types'
import { InfoIcon } from '@components/InfoIcon'
import { useStyle } from './styles'
import { Topic } from './Topic'
import { Service } from './Service'
import { Permission } from './Permission'
import { Folder } from './Folder'
import { FileName } from './FileName'
import { PreviewArticle } from './Preview'
import { GridHeaderCell } from '../Components'

export const useDataGrid = (config: Config, toc: string[]) => {
	const baseClassses = useStepStyle()
	const classes = useStyle()
	const columnSizing: TableColumnSizingOptions = {
		'File Name': {
			minWidth: 350,
		},
		lineNumber: {
			defaultWidth: 35,
			minWidth: 35,
		},
		preview: {
			defaultWidth: 35,
			minWidth: 35,
		},
		'ms.topic': {
			minWidth: 150,
		},
		'ms.service': {
			minWidth: 150,
		},
		'Permission Type': {
			minWidth: 150,
		},
		'Folder path': {
			minWidth: 150,
		},
	}
	const columnsDef = [
		createTableColumn<Article>({
			columnId: 'lineNumber',
			renderHeaderCell() {
				return <DataGridHeaderCell className={mergeClasses('no-border', baseClassses.lineNumber)} tabIndex={undefined} />
			},
			renderCell(item) {
				return (
					<DataGridCell className={baseClassses.lineNumber} tabIndex={undefined}>
						{item.lineNumber}
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'preview',
			renderHeaderCell() {
				return <DataGridHeaderCell className={mergeClasses('no-border', classes.previewIcon)} tabIndex={undefined} />
			},
			renderCell(item) {
				return (
					<DataGridCell className={classes.previewIcon} focusMode="none">
						<PreviewArticle item={item} />
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'articleId',
			renderHeaderCell() {
				return <GridHeaderCell content="Article ID" className="table-column-source" tabIndex={undefined} />
			},
			renderCell(item) {
				return (
					<DataGridCell tabIndex={undefined}>
						<TableCellLayout title={item.articleSourceId} truncate>
							{item.articleSourceId}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'articleTitle',
			renderHeaderCell() {
				return <GridHeaderCell content="Article Title" className="table-column-source" tabIndex={undefined} />
			},
			renderCell(item) {
				return (
					<DataGridCell tabIndex={undefined}>
						<TableCellLayout title={item.articleTitle} truncate>
							{item.articleTitle}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'revisionNumber',
			renderHeaderCell() {
				return <GridHeaderCell content="Revision Number" className="table-column-source" tabIndex={undefined} />
			},
			renderCell(item) {
				return (
					<DataGridCell tabIndex={undefined}>
						<TableCellLayout title={item.sourceRevisionNumber} truncate>
							{item.sourceRevisionNumber}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'EG.Authors',
			renderHeaderCell() {
				return <GridHeaderCell content="Authors" className="table-column-source" tabIndex={undefined} />
			},
			renderCell(item) {
				return (
					<DataGridCell tabIndex={undefined}>
						<TableCellLayout title={item.sourceAuthor} truncate>
							{item.sourceAuthor}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'Last Publish Date',
			renderHeaderCell() {
				return <GridHeaderCell content="Last Publish Date" className="table-column-source" tabIndex={undefined} />
			},
			renderCell(item) {
				return (
					<DataGridCell tabIndex={undefined}>
						<TableCellLayout title={FormatDate(item.sourceLastPublishDate)} truncate>
							{FormatDate(item.sourceLastPublishDate)}
						</TableCellLayout>
					</DataGridCell>
				)
			},
		}),
		createTableColumn<Article>({
			columnId: 'ms.topic',
			renderHeaderCell() {
				return <GridHeaderCell content="ms.topic" className="table-column-system" tabIndex={undefined} />
			},
			renderCell(item) {
				return <Topic item={item} config={config} />
			},
		}),
		createTableColumn<Article>({
			columnId: 'ms.service',
			renderHeaderCell() {
				return <GridHeaderCell content="ms.service" className="table-column-system" tabIndex={undefined} />
			},
			renderCell(item) {
				return <Service item={item} config={config} />
			},
		}),
		createTableColumn<Article>({
			columnId: 'Permission Type',
			renderHeaderCell() {
				return (
					<DataGridHeaderCell className="table-column-system" tabIndex={undefined}>
						<TableCellLayout title="Permission Type" truncate>
							<Body1 className={classes.text}>
								Permission Type
								<InfoIcon tooltipClass={classes.tooltip}>
									Define the permissioned type for each article. Although the value is initially set during migration, users have
									the option to override it at the article level during this step. Click{' '}
									<Link
										target="_blank"
										href="https://review.learn.microsoft.com/en-us/help/platform/permissioned-create?branch=main#6-configure-docfxjson-values-for-permissioned-content"
									>
										Configure permissioned-type for permissioned content
									</Link>{' '}
									for details.
								</InfoIcon>
							</Body1>
						</TableCellLayout>
					</DataGridHeaderCell>
				)
			},
			renderCell(item) {
				return <Permission item={item} />
			},
		}),
		createTableColumn<Article>({
			columnId: 'Folder path',
			renderHeaderCell() {
				return <GridHeaderCell content="Folder path" className="table-column-system" tabIndex={undefined} />
			},
			renderCell(item) {
				return <Folder item={item} toc={toc} />
			},
		}),
		createTableColumn<Article>({
			columnId: 'File Name',
			renderHeaderCell() {
				return (
					<DataGridHeaderCell className="table-column-system" tabIndex={undefined}>
						<TableCellLayout title="File name" truncate>
							<Body1 className={classes.text}>
								File name
								<InfoIcon tooltipClass={classes.tooltip}>
									Specify the name of the converted markdown file. Click{' '}
									<Link
										target="_blank"
										href="https://review.learn.microsoft.com/en-us/help/platform/seo-optimize-urls?branch=main#name-your-files"
									>
										Naming conventions of Markdown files
									</Link>{' '}
									for details.
								</InfoIcon>
							</Body1>
						</TableCellLayout>
					</DataGridHeaderCell>
				)
			},
			renderCell(item) {
				return <FileName item={item} />
			},
		}),
	]
	return {
		columns: columnsDef,
		columnSizingOptions: columnSizing,
	}
}
