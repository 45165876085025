import { Input, Button } from '@fluentui/react-components'
import { useState } from 'react'

export const useFilter = (search: () => void) => {
	const [articleId, setArticleId] = useState('')
	const [title, setTitle] = useState('')
	const reset = () => {
		setArticleId('')
		setTitle('')
		requestIdleCallback(() => {
			search()
		})
	}
	return [
		<>
			<Input placeholder="Article ID" value={articleId} onChange={(e, d) => setArticleId(d.value)} />
			<Input placeholder="Title" value={title} onChange={(e, d) => setTitle(d.value)} />
			<Button appearance="primary" onClick={search}>
				Search
			</Button>
			<Button onClick={reset}>Reset</Button>
		</>,
		{
			articleId,
			title,
		},
	] as const
}
