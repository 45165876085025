import { Api } from '@/Api'
import { Combobox, ComboboxProps, Option } from '@fluentui/react-components'
import { useRequest } from 'ahooks'
import { useEffect, useState } from 'react'
type Props = {
	OnSelect(val: string): void
	Value: string
}
export const SearchRepositorySelect = (props: Props) => {
	const [query, setQuery] = useState(props.Value)
	const { data, loading, runAsync } = useRequest(
		(query: string) => {
			if (query === props.Value) return Promise.resolve([props.Value])
			if (query) return Api.Github.ListRepositories(query)
			else return Promise.resolve([])
		},
		{
			debounceWait: 500,
			onSuccess: (res) => {
				if (res.length === 1) {
					setQuery(res[0])
					props.OnSelect(res[0])
				}
			},
		}
	)
	const onChange: ComboboxProps['onChange'] = (event) => {
		const value = event.target.value.trim()
		setQuery(value)
		runAsync(value)
	}

	useEffect(() => {
		if (query !== props.Value) setQuery(props.Value)
	}, [props.Value])
	const repos = data ?? []
	const selected = props.Value ? [props.Value] : []
	return (
		<Combobox
			clearable
			value={query}
			onChange={onChange}
			onOptionSelect={(_, d) => {
				if (d.optionValue) props.OnSelect(d.optionValue)
			}}
			onBlur={() => {
				setQuery(props.Value ?? '')
			}}
			autoComplete="off"
			placeholder="Type your repository name"
			selectedOptions={selected}
		>
			{loading ? (
				<Option disabled>Loading</Option>
			) : repos.length === 0 ? (
				<Option disabled>No option</Option>
			) : (
				repos.map((repo) => (
					<Option key={repo} value={repo} text={repo}>
						{repo}
					</Option>
				))
			)}
		</Combobox>
	)
}
