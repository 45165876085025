import { EPRStatus } from '@/Api/Types'
import { Input, Dropdown, Button, Option } from '@fluentui/react-components'
import { useState } from 'react'

export const useFilter = (search: () => void) => {
	const [articleId, setArticleId] = useState('')
	const [title, setTitle] = useState('')
	const [status, setStatus] = useState([] as EPRStatus[])
	const reset = () => {
		setArticleId('')
		setTitle('')
		setStatus([])
		requestIdleCallback(() => {
			search()
		})
	}
	return [
		<>
			<Input placeholder="Article ID" value={articleId} onChange={(e, d) => setArticleId(d.value)} />
			<Input placeholder="Title" value={title} onChange={(e, d) => setTitle(d.value)} />
			<Dropdown
				selectedOptions={status}
				multiselect
				placeholder="PR Status"
				onOptionSelect={(e, d) => {
					setStatus(d.selectedOptions as EPRStatus[])
				}}
				title="Status"
			>
				<Option value="Draft">Draft</Option>
				<Option value="Open">Open</Option>
				<Option value="Merged">Merged</Option>
				<Option value="Closed">Closed</Option>
			</Dropdown>
			<Button appearance="primary" onClick={search}>
				Search
			</Button>
			<Button onClick={reset}>Reset</Button>
		</>,
		{
			articleId,
			title,
			status,
		},
	] as const
}
