import { Api } from '@/Api'
import { EPermissionType, MigrationArticle } from '@/Api/Types'
import { makeAutoObservable, runInAction } from 'mobx'
import { NewMigrationContext } from '.'
import { NavigateResult, StepContext } from './Types'
import { debounce } from 'lodash'
import { DispatchToast } from '@components/GlobalToaster'
import { Toast, ToastTitle, ToastBody } from '@fluentui/react-components'
import { GetRoot } from '@/Mobx'
type Article = MigrationArticle & { lineNumber: number }
export type ArticleConfig = {
	id: number
	folder: string
	topic: string
	service: string
	permission: EPermissionType
	name: string
	showPopover: boolean
	readonly: boolean
}
export class Step4Context implements StepContext {
	public constructor(root: NewMigrationContext) {
		this.articleMap = new Map()
		this.dirtyArticle = new Map()
		this.root = root
		this.list = []

		makeAutoObservable(this)
		this.autoTrigger = debounce(() => this.AutoSave(), 4000)
	}
	async OnNavigate() {
		if (this.dirtyArticle.size === 0) return NavigateResult.Skip
		try {
			const d = await this.AutoSave()
			if (d?.length === 0) return NavigateResult.Success
			else return NavigateResult.Cant()
		} catch (e) {
			return NavigateResult.Fail(String(e))
		}
	}
	public SetList(l: Article[]) {
		this.list = l

		this.list.forEach((r) => {
			const e = this.GetEntity(r)

			e.topic = r.msTopic ?? 'troubleshooting-general'
			e.permission = r.permissionType ?? this.root.Step1.DefaultPermissionType
			e.name = r.targetRepoFileName ?? ''
			e.folder = r.targetRepoFolder ?? ''
			e.service = r.msService ?? ''
			if (r.targetRepoBranch) {
				e.readonly = true
			}
		})
	}
	public get Data() {
		return this.list.map((r) => ({
			...r,
			entity: this.GetEntity(r),
		}))
	}
	public get Map() {
		return this.articleMap
	}
	public async Save() {
		if (this.articleMap.size === 0) return
		const duplicated = await Api.MigrationArticle.ConfigArticle(
			[...this.articleMap.values()]
				.filter((e) => {
					return !e.readonly
				})
				.map((entity) => {
					let name = entity.name.trim()
					if (name.length && !name.toLowerCase().endsWith('.md')) name = `${name}.md`
					return {
						id: entity.id,
						msService: entity.service,
						msTopic: entity.topic,
						permissionType: entity.permission,
						targetRepoFileName: name,
						targetRepoFolder: entity.folder,
					}
				})
		)
		if (duplicated.length === 0)
			DispatchToast(
				<Toast>
					<ToastTitle>Success</ToastTitle>
					<ToastBody>Save success.</ToastBody>
				</Toast>,
				{
					intent: 'success',
				}
			)
		else {
			GetRoot().notifications.addNotification('Warning', 'Step 4: Some articles cannot be saved due to duplicate file paths.', 'Success')
			this.RemoveDuplicatedPath(duplicated)
		}
		runInAction(() => {
			this.root.TaskStatus.configured = 1
		})
	}
	private RemoveDuplicatedPath(ids: number[]) {
		ids.forEach((id) => {
			const e = this.GetEntity({ id })
			e.folder = ''
			e.name = ''
		})
	}
	public async AutoSave() {
		if (this.dirtyArticle.size === 0) return []
		const duplicated = await Api.MigrationArticle.ConfigArticle(
			[...this.dirtyArticle.values()]
				.filter((e) => {
					return !e.readonly
				})
				.map((entity) => {
					let name = entity.name.trim()
					if (name.length && !name.toLowerCase().endsWith('.md')) name = `${name}.md`
					return {
						id: entity.id,
						msService: entity.service,
						msTopic: entity.topic,
						permissionType: entity.permission,
						targetRepoFileName: name,
						targetRepoFolder: entity.folder,
					}
				})
		)

		runInAction(() => {
			this.dirtyArticle.clear()
			this.root.TaskStatus.configured = 1
		})
		if (duplicated.length === 0)
			DispatchToast(
				<Toast>
					<ToastTitle>Success</ToastTitle>
					<ToastBody>Save success.</ToastBody>
				</Toast>,
				{
					intent: 'success',
				}
			)
		else {
			GetRoot().notifications.addNotification('Warning', 'Step 4: Some articles cannot be saved due to duplicate file paths.', 'Fail')

			this.RemoveDuplicatedPath(duplicated)
		}
		return duplicated
	}
	private MarkDirty(config: ArticleConfig) {
		if (!this.dirtyArticle.has(config.id)) {
			this.dirtyArticle.set(config.id, config)
		}
		this.autoTrigger()
	}
	public setTopic(row: MigrationArticle, val: string) {
		const entity = this.GetEntity(row)
		if (entity.topic !== val) {
			entity.topic = val
			this.MarkDirty(entity)
		}
	}
	public setService(row: MigrationArticle, val: string) {
		const entity = this.GetEntity(row)

		if (entity.service !== val) {
			entity.service = val
			this.MarkDirty(entity)
		}
	}
	public setPermissionType(row: MigrationArticle, val: EPermissionType) {
		const entity = this.GetEntity(row)
		if (entity.permission !== val) {
			entity.permission = val
			this.MarkDirty(entity)
		}
	}
	public setFolder(row: MigrationArticle, val: string) {
		const entity = this.GetEntity(row)
		if (entity.folder !== val) {
			entity.folder = val
			this.MarkDirty(entity)
		}
	}
	public setName(row: Article, val: string) {
		const regex = /[^a-z0-9-]/g
		const entity = this.GetEntity(row)

		if (regex.test(val)) {
			entity.showPopover = true
			return
		}
		if (entity.name !== val) {
			entity.showPopover = false
			entity.name = val
			this.MarkDirty(entity)
		}
	}
	public GetEntity(row: Pick<MigrationArticle, 'id'>) {
		let entity = this.articleMap.get(row.id)
		if (entity === undefined) {
			this.articleMap.set(row.id, {
				topic: '',
				service: '',
				folder: '',
				name: '',
				permission: this.root.Step1.DefaultPermissionType,
				showPopover: false,
				readonly: false,
				id: row.id,
			})
			entity = this.articleMap.get(row.id)!
		}
		return entity
	}
	private articleMap: Map<number, ArticleConfig>
	private dirtyArticle: Map<number, ArticleConfig>
	private root: NewMigrationContext
	private list: Article[]
	private autoTrigger: () => void
}
