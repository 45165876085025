import { observer } from 'mobx-react-lite'
import { Article } from './types'
import { useMigrationContext } from '../Context'
import { DataGridCell, TableCellLayout, Dropdown, Option } from '@fluentui/react-components'
import { useStyle } from './styles'
import { Config } from './types'

export const Topic = observer(({ item, config }: { item: Article; config: Config }) => {
	const classes = useStyle()
	const { Step4 } = useMigrationContext()
	const entity = Step4.GetEntity(item)
	return (
		<DataGridCell>
			<TableCellLayout truncate>
				<Dropdown
					className={classes.dropdown}
					button={
						<span className={classes.truncatedText} title={entity.topic}>
							{entity.topic}
						</span>
					}
					disabled={entity.readonly}
					onOptionSelect={(e, o) => {
						Step4.setTopic(item, o.optionValue!)
					}}
					aria-autocomplete="none"
					title="Topic"
				>
					{config['MS.Topic'].map((o) => (
						<Option key={o} value={o}>
							{o}
						</Option>
					))}
				</Dropdown>
			</TableCellLayout>
		</DataGridCell>
	)
})
