import { DataGridCell } from '@fluentui/react-components'
import { observer } from 'mobx-react-lite'
import { Step4NameInput } from './Step4NameInput'
import { Article } from './types'
import { useMigrationContext } from '../Context'

export const FileName = observer(({ item }: { item: Article }) => {
	const { Step4 } = useMigrationContext()
	const entity = Step4.GetEntity(item)
	return (
		<DataGridCell>
			<Step4NameInput name={entity.name} showPopover={entity.showPopover} setName={(v) => Step4.setName(item, v)} disabled={entity.readonly} />
		</DataGridCell>
	)
})
