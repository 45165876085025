export type MapType<T> = T[keyof T]
export type Docset = {
	name: string
	folder: string
	permissionedType: string
}
export type Docsets = Docset[]
export const PermissionType = {
	Public: 'Public',
	Private: 'Private',
	Secret: 'Secret',
} as const
export type EPermissionType = MapType<typeof PermissionType>
export type MigrationTaskModel = {
	migrationName: string
	permissionType: EPermissionType
	targetRepoUrl: string
	targetDocSetUrl: string
	targetDocSetName: string
	description: string
	defaultDisclaimer: string
	ownerMail: string
	createdBy: string
	articleFilter: string
}
export type MigrationId = number
export const MigrationTaskOverallStatus = {
	Created: 'Created',
	Processing: 'Processing',
	Finish: 'Finish',
	Failed: 'Failed',
} as const
export type EMigrationTaskOverallStatus = MapType<typeof MigrationTaskOverallStatus>
export type MigrationTask = {
	id: number
	ocid: string
	migrationName: string
	permissionType: EPermissionType
	permissionGroup: string
	description: string
	overallStatus: EMigrationTaskOverallStatus
	defaultDisclaimer: string
	articleFilter: string
	isDeleted: boolean
	ownerMail: string
	createdTime: string
	createdBy: string
	createdByName: string
	lastModifiedTime?: string
	lastModifiedBy?: string
	lastModifiedByName?: string
	targetRepoUrl: string
	targetDocSetUrl: string
	targetDocSetName: string
	taskStep: string
}
export const MigrationStatus = {
	PendingSync: 0,
	Syncing: 5,
	SyncFailed: 10,
	Synced: 15,
	PendingCheck: 25,
	ContentChecking: 30,
	ContentCheckFailed: 35,
	ContentChecked: 40,
	PendingConvert: 50,
	ContentConverting: 55,
	ContentConvertFailed: 60,
	ContentConverted: 65,
	PendingReview: 75,
	Reviewing: 80,
} as const
export type EMigrationStatus = MapType<typeof MigrationStatus>
export const ArticleSourceSystem = {
	Evergreen: 'Evergreen',
} as const
export type EArticleSourceSystem = MapType<typeof ArticleSourceSystem>

export type MigrationItem = {
	id: number
	ocid: string
	migrationTaskId: MigrationId
	articleSourceId: string
	articleTitle: string
	articleKBID: string
	locale: string
	articleSourceSystem: EArticleSourceSystem
	syncNumber: number
	migrationStatus: EMigrationStatus
	currentStepStartTime?: string
	isDeleted: boolean
	createdTime: string
	createdBy: string
	lastModifiedTime?: string
	lastModifiedBy?: string
	articleRevisionNumber: number
	articleAuthor: string
	articleUrl: string
	isExcluded: boolean
	articleLastPublishDate?: string
}
export const DisclaimerType = {
	File: 'File',
	PlainText: 'PlainText',
} as const
export type EDisclaimerType = MapType<typeof DisclaimerType>

export type MigrationArticle = {
	id: number
	ocid: string
	migrationTaskId: number
	migrationItemId: number
	articleSourceId: string
	articleKBID: string
	articleTitle?: string
	locale?: string
	articleSourceSystem: EArticleSourceSystem
	articleFileName: string
	localCopyFilePath?: string
	sourceRepoName?: string
	sourceLastPublishDate?: string
	sourceCreatedDate?: string
	sourceSAPName?: string
	sourceSAPStatus?: string
	sourceContentOwner?: string
	sourceKeyword?: string
	sourcePageOfView?: number
	sourceVoteOfHelpful?: number
	sourceAuthor?: string
	sourceRevisionNumber?: string
	sourceLastModifedTime?: string
	syncNumber: number
	hasImages: boolean
	hasVideos: boolean
	disclaimer?: string
	disclaimerType: EDisclaimerType
	contentDescription?: string
	isDeleted: boolean
	isExculded: boolean
	createdTime: string
	createdBy: string
	lastModifiedTime?: string
	lastModifiedBy?: string
	msTopic?: string
	msService?: string
	permissionType?: EPermissionType
	targetRepoBranch?: string
	targetRepoFileName?: string
	targetRepoFolder?: string
}
export const MigrationStep = {
	ArticleSync: 'ArticleSync',
	ContentCheck: 'ContentCheck',
	FormatConvert: 'FormatConvert',
	CheckAfterConvert: 'CheckAfterConvert',
	LMCMetaProcess: 'LMCMetaProcess',
	TOCPathProcess: 'TOCPathProcess',
	CreateReviewBranch: 'CreateReviewBranch',
	PushToReviewBranch: 'PushToReviewBranch',
} as const
export type EMigrationStep = MapType<typeof MigrationStep>
export const MigrationActionStatus = {
	Pending: 'Pending',
	Processing: 'Processing',
	Success: 'Success',
	Failed: 'Failed',
	Warning: 'Warning',
} as const
export type EMigrationActionStatus = MapType<typeof MigrationActionStatus>

export type MigrationItemStatusRespond = {
	id: number
	articleId: number
	migrationStatus: EMigrationStatus
	actions: { [key in EMigrationStep]: { finishTime?: string; status: EMigrationActionStatus } }
}
export const ReviewResult = {
	Include: 'Include',
	Exclude: 'Exclude',
	Reset: 'Reset',
} as const
export type EReviewResult = MapType<typeof ReviewResult>

export type MigrationItemCreateModel = Pick<
	MigrationItem,
	| 'migrationTaskId'
	| 'articleSourceId'
	| 'articleTitle'
	| 'articleKBID'
	| 'locale'
	| 'articleSourceSystem'
	| 'articleRevisionNumber'
	| 'articleAuthor'
	| 'articleUrl'
	| 'articleLastPublishDate'
> & { reviewResult: EReviewResult }
export type QueryData = {
	Expression: unknown
	Excludes: string[]
	Includes: string[]
}
export type UpdateQueryDataReq = {
	QueryData: QueryData
	items: MigrationItemCreateModel[]
}

export const ReviewStatus = {
	Include: 'Reviewed for inclusion',
	NotReviewed: 'To be Reviewed',
	Exclude: 'Reviewed for exclusion',
} as const
export type EReviewStatus = MapType<typeof ReviewStatus>
export const ContentIssueStatus = {
	Pending: 'Pending',
	NoNeedFix: 'NoNeedFix',
	Fixed: 'Fixed',
} as const
export type EContentIssueStatus = MapType<typeof ContentIssueStatus>

export const ContentCheckPoint = {
	PII: 'PII',
	Poli: 'Poli',
	CredScan: 'CredScan',
	RubikBrokenLink: 'RubikBrokenLink',
	RubikContentInfo: 'RubikContentInfo',
	LMCBrokenLink: 'LMCBrokenLink',
	MSAuthor: 'MSAuthor',
	LMCMediaCheck: 'LMCMediaCheck',
	Grammar: 'Grammar',
} as const
export type EContentCheckPoint = MapType<typeof ContentCheckPoint>

export type ContentCheckError = {
	id: number

	migrationItemId: number

	migrationArticleId: number

	articleSourceId: string

	syncNumber: number

	contentCheckPoint: EContentCheckPoint

	rubikScanTaskId: string

	rubikIssueId: number

	errorContent: string

	issueContext?: string

	suggestion?: string

	issueStatus: EContentIssueStatus

	createdTime: Date

	createdBy: string

	lastModifiedTime?: Date

	lastModifiedBy?: string
}

export const Step3StatsuOptions = {
	'Not retrieved': 1,
	'Retrieved failed': 2,
	'Retrieved but checked failed': 3,
	'Retrieved and checked passed': 4,
} as const
export type EStep3Status = MapType<typeof Step3StatsuOptions>
export const Step5StatsuOptions = {
	'Not converted': 1,
	'Converted failed': 2,
	'Converted but checked with warnings': 3,
	'Converted and checked passed': 4,
} as const
export type EStep5Status = MapType<typeof Step5StatsuOptions>
export type Pagination = {
	pageNum: number
	pageSize: number
}
export type PaginationResponse<T> = {
	searchResult: T[]
	pagination: {
		total: number
	}
}
export type TaskStatus = {
	included: number
	retrieved: number
	configured: number
	converted: number
	published: number
}
export type TaskStatusRespond = {
	[key: number]: TaskStatus
}
export const PRReviewStatus = {
	Approved: 'Approved',
} as const
export type EPRReviewStatus = MapType<typeof PRReviewStatus>
export const PRStatus = {
	Open: 'Open',
	Merged: 'Merged',
	Closed: 'Closed',
} as const
export type EPRStatus = MapType<typeof PRStatus>

export type ArticleForPublish = Pick<
	MigrationArticle,
	'id' | 'articleSourceId' | 'articleTitle' | 'sourceRevisionNumber' | 'sourceAuthor' | 'sourceLastPublishDate'
> &
	Partial<{
		prNumber: number
		reviewUrl: string
		reviewStatus: EPRReviewStatus
		prStatus: EPRStatus
		liveDate: string
		liveUrl: string
	}> & { lineNumber: number }
export type MigrationArticlesForConvert = Pick<
	MigrationArticle,
	'id' | 'articleSourceId' | 'sourceRevisionNumber' | 'sourceAuthor' | 'sourceLastPublishDate' | 'articleTitle' | 'lastModifiedTime'
> & { reviewUrl?: string }
