import './DataQueryBuilder.scss'
import { getMaxDepth, QueryExpression } from '@components/DataQuery/Components/DataQueryBuilder/QueryBuilderUtils'
import { QueryRows } from './QueryRows'
import {
	Button,
	Table,
	TableBody,
	TableHeader,
	TableHeaderCell,
	TableRow,
	Tooltip,
	useTableColumnSizing_unstable,
	useTableFeatures,
} from '@fluentui/react-components'
import { Add20Regular, BranchForkRegular } from '@fluentui/react-icons'
import { observer } from 'mobx-react-lite'
import { useQueryContext } from '@components/DataQuery/DataQueryContext'
import { useRequest } from 'ahooks'
import { Api } from '@/Api'
import { useEffect } from 'react'
import { reaction } from 'mobx'
import { useDataGrid } from './useDataGrid'

export const DataQueryBuilder = observer(() => {
	const context = useQueryContext()
	const maxDepth = getMaxDepth(context.Expression)

	const numOfGroupCols = Math.max(0, getMaxDepth(context.Expression) - 1)
	const { runAsync } = useRequest(Api.MigrationTask.SaveTaskQueryData, {
		manual: true,
		debounceWait: 5000,
	})
	useEffect(() => {
		return reaction(
			() => context.Expression,
			() => {
				runAsync(context.MigrationId!, context.GetQueryData())
			}
		)
	}, [])
	const { columnSizingOptions, columns } = useDataGrid(maxDepth)
	const { columnSizing_unstable, tableRef } = useTableFeatures<QueryExpression>(
		{
			columns,
			items: [],
		},
		[useTableColumnSizing_unstable({ columnSizingOptions })]
	)
	return (
		<div className="container query-builder open">
			<Table ref={tableRef} {...columnSizing_unstable.getTableProps()}>
				<TableHeader>
					<TableRow>
						<TableHeaderCell className="add-remove" {...columnSizing_unstable.getTableHeaderCellProps('add-remove')} />
						<TableHeaderCell className="group" {...columnSizing_unstable.getTableHeaderCellProps('group')}>
							<Tooltip content={context.GroupingHoverText} relationship="label">
								<Button
									icon={<BranchForkRegular />}
									appearance="transparent"
									title="Group"
									aria-label="Group"
									className="groupRowIcon"
									onClick={context.GroupRows}
								/>
							</Tooltip>
						</TableHeaderCell>
						{new Array(numOfGroupCols).fill(1).map((v, i) => (
							<TableHeaderCell key={i} className="group-col" {...columnSizing_unstable.getTableHeaderCellProps(`group-col-${i}`)} />
						))}
						<TableHeaderCell className="and-or" {...columnSizing_unstable.getTableHeaderCellProps('and-or')}>
							And/Or
						</TableHeaderCell>
						<TableHeaderCell className="field" {...columnSizing_unstable.getTableHeaderCellProps('field')}>
							Field
						</TableHeaderCell>
						<TableHeaderCell className="operator" {...columnSizing_unstable.getTableHeaderCellProps('operator')}>
							Operator
						</TableHeaderCell>
						<TableHeaderCell className="value" {...columnSizing_unstable.getTableHeaderCellProps('value')}>
							Value
						</TableHeaderCell>
					</TableRow>
				</TableHeader>
				<TableBody>
					<QueryRows />
				</TableBody>
			</Table>
			<Button appearance="transparent" icon={<Add20Regular />} onClick={context.AppendNewExpression}>
				Add new clause
			</Button>
		</div>
	)
})
