import {
	Body1,
	Breadcrumb,
	BreadcrumbButton,
	BreadcrumbDivider,
	BreadcrumbItem,
	Button,
	Input,
	Link,
	Popover,
	PopoverSurface,
	PopoverTrigger,
	SkeletonItem,
	Subtitle2,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from '@fluentui/react-components'
import { AddSquareRegular, ChevronDown24Filled, ChevronRight24Filled, DeleteRegular, EditRegular } from '@fluentui/react-icons'
import './index.scss'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Api } from '@/Api'
import { useNavigate } from 'react-router-dom'
import { FormatDate } from '@/Utilities'
import { useRequest } from 'ahooks'
import { MigrationTask } from '@/Api/Types'
import { MigrationProgress } from './Component/MigrationProgress'
import { useEffect, useState } from 'react'
import { useRootContext } from '@/Mobx'
import { LoadingSpinner } from '@components/LoadingSpinner'
const selectIcon = (expanded: boolean) => (expanded ? ChevronDown24Filled : ChevronRight24Filled)

export const AllMigrations = observer(() => {
	const navigate = useNavigate()
	const { notifications } = useRootContext()
	const state = useLocalObservable(() => ({
		isMyMigrationsExpanded: false,
		isShareMigrationsExpanded: false,
		toggleMyMigrationsExpanded() {
			this.isMyMigrationsExpanded = !this.isMyMigrationsExpanded
		},
		toggleShareMigrationsExpanded() {
			this.isShareMigrationsExpanded = !this.isShareMigrationsExpanded
		},
		get MyMigrationsIcon() {
			return selectIcon(this.isMyMigrationsExpanded)
		},
		get ShareMigrationsIcon() {
			return selectIcon(this.isShareMigrationsExpanded)
		},
	}))
	const [nameFilter, setNameFilter] = useState('')
	const { data, loading, runAsync } = useRequest(Api.MigrationTask.GetTasks, {
		manual: true,
	})
	const { data: status, loading: statusLoading } = useRequest(
		() => {
			if (data) return Api.MigrationTask.GetTaskStatus([...data.mine.map((t) => t.id), ...data.shared.map((t) => t.id)])
			else return Promise.resolve(undefined)
		},
		{
			refreshDeps: [data],
		}
	)
	useEffect(() => {
		runAsync(nameFilter)
	}, [])
	const search = () => {
		runAsync(nameFilter)
	}
	const reset = () => {
		setNameFilter('')
		runAsync('')
	}
	const onClick = (e: React.MouseEvent, id: number | string) => {
		navigate(`/migration/${id}`)
		e.preventDefault()
	}
	const renderStatus = (id: number) => {
		if (statusLoading) return <SkeletonItem size={16} />
		const s = status?.[id] ?? { included: 0, retrieved: 0, configured: 0, converted: 0, published: 0 }
		if (s.included === 0) return <Body1>Created</Body1>
		//Created, In Processing ( %# ), Completed
		if (s.published === s.included) return <Body1>Completed</Body1>
		return <MigrationProgress status={s} />
	}
	const deleteMigration = (task: MigrationTask) => {
		const i = notifications.addNotification('Deleting', `Deleting Migration(${task.migrationName})`, 'Loading')
		Api.MigrationTask.DeleteTask(task.id)
			.then(() => {
				notifications.modifyNotification(i, 'Success', `Migration(${task.migrationName}) Deleted`, 'Success')
			})
			.finally(() => {
				search()
			})
	}
	const renderItems = (tasks?: MigrationTask[]) => {
		if (!tasks) return <></>
		const formatNameAndEmail = (name: string, email: string) => {
			let result = ''
			if (name.includes('(')) {
				const sp = name.split('(')
				result = sp[0].trim()
			} else {
				result = name.trim()
			}
			if (email.includes('@')) {
				const sp = email.split('@')
				result = `${result} (${sp[0]})`
			}
			return result
		}
		return tasks.map((item) => (
			<TableRow key={item.id}>
				<TableCell>{item.migrationName}</TableCell>
				<TableCell>
					{item.targetRepoUrl}/{item.targetDocSetName}
				</TableCell>
				<TableCell>{renderStatus(item.id)}</TableCell>
				<TableCell>{FormatDate(item.createdTime)}</TableCell>
				<TableCell>{formatNameAndEmail(item.createdByName ?? '', item.createdBy)}</TableCell>
				<TableCell>{FormatDate(item.lastModifiedTime)}</TableCell>
				<TableCell>
					{formatNameAndEmail(item.lastModifiedByName ?? item.createdByName ?? '', item.lastModifiedBy ?? item.createdBy)}
				</TableCell>

				<TableCell className="operate">
					<Link href={`/migration/${item.id}`} onClick={(e) => onClick(e, item.id)}>
						<Button icon={<EditRegular />} appearance="subtle" title="Edit this migration" />
					</Link>
					<Popover withArrow>
						<PopoverTrigger disableButtonEnhancement>
							<Button icon={<DeleteRegular />} appearance="subtle" title="Delete this migration" />
						</PopoverTrigger>
						<PopoverSurface>
							<Subtitle2>Delete migration</Subtitle2>
							<section>
								<Body1 block>Are you sure you want to to delete {item.migrationName}?</Body1>
								<Body1 block>This is irreversible.</Body1>
							</section>
							<section>
								<Button onClick={() => deleteMigration(item)}>Delete</Button>
							</section>
						</PopoverSurface>
					</Popover>
				</TableCell>
			</TableRow>
		))
	}
	return (
		<section className="all-migrations">
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbButton href="/">Home</BreadcrumbButton>
				</BreadcrumbItem>
				<BreadcrumbDivider />
				<BreadcrumbItem>
					<BreadcrumbButton href="/migration" current>
						All Migrations
					</BreadcrumbButton>
				</BreadcrumbItem>
			</Breadcrumb>
			<section className="toolbar">
				<Input placeholder="Migration name" value={nameFilter} onChange={(e, d) => setNameFilter(d.value)} />
				<Button appearance="primary" onClick={search}>
					Search
				</Button>
				<Button onClick={reset}>Reset</Button>
				<Button
					icon={<AddSquareRegular />}
					className="main-action"
					iconPosition="before"
					appearance="primary"
					onClick={(e) => onClick(e, 'new')}
				>
					New Migration
				</Button>
			</section>
			<Table noNativeElements>
				<TableHeader>
					<TableRow>
						<TableHeaderCell>Migration name</TableHeaderCell>
						<TableHeaderCell>Target docset</TableHeaderCell>
						<TableHeaderCell>Status</TableHeaderCell>
						<TableHeaderCell>Created date</TableHeaderCell>
						<TableHeaderCell>Created by</TableHeaderCell>
						<TableHeaderCell>Last modified date</TableHeaderCell>
						<TableHeaderCell>Last modified by</TableHeaderCell>
						<TableHeaderCell className="operate" />
					</TableRow>
				</TableHeader>
				<TableBody>
					<TableRow className="group-row" onClick={state.toggleMyMigrationsExpanded}>
						<TableCell>
							<state.MyMigrationsIcon />
							My Migrations
						</TableCell>
					</TableRow>
					{state.isMyMigrationsExpanded && (loading ? <LoadingSpinner /> : renderItems(data?.mine))}
					<TableRow className="group-row" onClick={state.toggleShareMigrationsExpanded}>
						<TableCell>
							<state.ShareMigrationsIcon />
							Shared Migrations
						</TableCell>
					</TableRow>
					{state.isShareMigrationsExpanded && (loading ? <LoadingSpinner /> : renderItems(data?.shared))}
				</TableBody>
			</Table>
		</section>
	)
})
