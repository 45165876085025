import { ReviewStatus } from '@/Api/Types'
import {
	Menu,
	MenuTrigger,
	Button,
	MenuPopover,
	MenuList,
	MenuItemCheckbox,
	MenuCheckedValueChangeData,
	MenuCheckedValueChangeEvent,
} from '@fluentui/react-components'
import { ChevronDownFilled } from '@fluentui/react-icons'
import { useState } from 'react'

export const useStatusFilter = () => {
	const [selectedFilterStatus, setSelectedFilterStatus] = useState<Record<string, string[]>>({
		ReviewStatus: [ReviewStatus.NotReviewed, ReviewStatus.Include, ReviewStatus.Exclude, 'Duplicated'],
	})
	const onCheckedValueChange = (e: MenuCheckedValueChangeEvent, data: MenuCheckedValueChangeData) => {
		setSelectedFilterStatus((v) => {
			return Object.assign({}, v, { [data.name]: data.checkedItems })
		})
	}
	return {
		selectedStatus: selectedFilterStatus,
		component: (
			<Menu hasCheckmarks checkedValues={selectedFilterStatus} onCheckedValueChange={onCheckedValueChange}>
				<MenuTrigger disableButtonEnhancement>
					<Button
						icon={<ChevronDownFilled />}
						style={selectedFilterStatus['ReviewStatus'].length === 4 ? {} : { color: 'red' }}
						appearance="subtle"
						title="Filter with review status"
					/>
				</MenuTrigger>
				<MenuPopover>
					<MenuList>
						<MenuItemCheckbox name="ReviewStatus" value="Duplicated">
							Duplicated
						</MenuItemCheckbox>
						<MenuItemCheckbox name="ReviewStatus" value={ReviewStatus.Include}>
							{ReviewStatus.Include}
						</MenuItemCheckbox>
						<MenuItemCheckbox name="ReviewStatus" value={ReviewStatus.Exclude}>
							{ReviewStatus.Exclude}
						</MenuItemCheckbox>
						<MenuItemCheckbox name="ReviewStatus" value={ReviewStatus.NotReviewed}>
							{ReviewStatus.NotReviewed}
						</MenuItemCheckbox>
					</MenuList>
				</MenuPopover>
			</Menu>
		),
	}
}
