import { DataGridHeaderCell, DataGridHeaderCellProps, TableCellLayout } from '@fluentui/react-components'
type Props = { content: string; className?: string } & Omit<DataGridHeaderCellProps, 'className'>
//DataGridHeaderCell
export const GridHeaderCell = ({ content, className, ...prop }: Props) => {
	return (
		<DataGridHeaderCell className={className} {...prop}>
			<TableCellLayout title={content} truncate>
				{content}
			</TableCellLayout>
		</DataGridHeaderCell>
	)
}
