import { Api } from '@/Api'
import { ContentCheckError, ContentCheckPoint, EContentCheckPoint, MigrationItem } from '@/Api/Types'
import { FormatDate } from '@/Utilities'
import {
	Body1,
	Body1Strong,
	Button,
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from '@fluentui/react-components'
import { useEffect, useState } from 'react'
import './ErrorDialog.scss'

type Props = {
	item: MigrationItem
}
const checkpoints = Object.entries({
	[ContentCheckPoint.RubikBrokenLink]: 'Broken Link',
	[ContentCheckPoint.PII]: 'PII',
	[ContentCheckPoint.Poli]: 'PoliCheck',
	[ContentCheckPoint.CredScan]: 'CredScan',
	[ContentCheckPoint.MSAuthor]: 'Author',
}) as [EContentCheckPoint, string][]
const cp = checkpoints.map(([k]) => k)
export const CheckFailDialog = ({ item }: Props) => {
	const [errors, setErrors] = useState({} as { [key in EContentCheckPoint]?: ContentCheckError[] })
	useEffect(() => {
		Api.ContentCheck.GetContentCheck(item.id, cp).then((r) => {
			const res = {} as { [key in EContentCheckPoint]?: ContentCheckError[] }
			r.forEach((error) => {
				const array = res[error.contentCheckPoint] ?? []
				array.push(error)
				res[error.contentCheckPoint] = array
			})
			setErrors(res)
		})
	}, [item.id])
	return (
		<Dialog>
			<DialogTrigger disableButtonEnhancement>
				<Button appearance="outline" className="failed">
					Failed
				</Button>
			</DialogTrigger>
			<DialogSurface style={{ minWidth: '800px' }} className="error-dialog">
				<DialogBody>
					<DialogTitle>Check Error Detail:</DialogTitle>
					<DialogContent>
						<section className="grid-2">
							<section>
								<Body1Strong>Content ID:</Body1Strong>
								<Body1>{item.articleSourceId}</Body1>
							</section>
							<section>
								<Body1Strong>Author:</Body1Strong>
								<Body1>{item.articleAuthor}</Body1>
							</section>
						</section>
						<Table noNativeElements>
							<TableHeader>
								<TableRow>
									<TableHeaderCell />
									<TableHeaderCell>Check Date</TableHeaderCell>
									<TableHeaderCell>Result</TableHeaderCell>
								</TableRow>
							</TableHeader>
							<TableBody>
								{checkpoints.map(([checkpoint, display]) => {
									const error = errors[checkpoint]
									return (
										<TableRow key={checkpoint}>
											<TableCell>{display}</TableCell>
											<TableCell>{FormatDate(errors[checkpoint]?.[0]?.createdTime ?? item.currentStepStartTime)}</TableCell>
											<TableCell>
												{error ? (
													<section className="error-content">
														{error.map((error) => (
															<Body1 block key={error.id}>
																{error.errorContent}
															</Body1>
														))}
													</section>
												) : (
													<Body1>Pass</Body1>
												)}
											</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</DialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary">Close</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}
