import { DataGridCell, TableCellLayout } from '@fluentui/react-components'
import { observer } from 'mobx-react-lite'
import { useMigrationContext } from '../Context'
import { Article } from './types'
import { EnhancedOption, EnhancedSelect } from '@components/EnhancedSelect'
import { useMemo } from 'react'

export const Folder = observer(({ item, toc }: { item: Article; toc: string[] }) => {
	const { Step4 } = useMigrationContext()
	const entity = Step4.GetEntity(item)
	const options = useMemo(() => {
		return toc.map(
			(t) =>
				({
					value: t,
					text: t,
				} as EnhancedOption)
		)
	}, [toc])
	return (
		<DataGridCell>
			<TableCellLayout truncate>
				<EnhancedSelect
					value={entity.folder}
					onOptionSelect={(o) => Step4.setFolder(item, o)}
					options={options}
					positioning={{ matchTargetSize: undefined }}
					disabled={entity.readonly}
					autoComplete="off"
					spellCheck="false"
					title="Folder"
					aria-label="Folder"
				/>
			</TableCellLayout>
		</DataGridCell>
	)
})
