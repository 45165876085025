import './DataQueryResult.scss'
import { observer } from 'mobx-react-lite'
import { useQueryContext } from '@components/DataQuery/DataQueryContext'
import {
	Body1,
	Body1Strong,
	DataGrid,
	mergeClasses,
	makeStyles,
	DataGridBody,
	DataGridHeader,
	DataGridRow,
	Tooltip,
} from '@fluentui/react-components'
import { ArticleData } from '@components/DataQuery/DataQueryContext/ArticleData'
import { useComputed } from '@/Hook/useComputed'
import { action } from 'mobx'
import { ArticleSourceSystem, EReviewResult, ReviewResult, ReviewStatus } from '@/Api/Types'
import { PreviewAction } from './PreviewArticle'
import { useState } from 'react'
import { useMemoryPagination } from '@/Hook/useMemoryPagination'
import { Api } from '@/Api'
import { useDataGrid } from './useDataGrid'
import { Article } from '@components/NewMigration/Step3/type'
import { LoadingSpinner } from '@components/LoadingSpinner'

export const useStyle = makeStyles({
	grid: {
		overflowX: 'auto',
		minWidth: 'unset !important',
	},
	gridBody: {
		width: 'fit-content',
	},
	text: {
		display: 'inline-flex',
	},
	link: {
		display: 'inline-flex',
	},
	tooltip: {
		maxWidth: '350px',
	},
	countContainer: {
		display: 'grid',
		gridTemplateColumns: 'auto auto',
	},
})

export const DataQueryResult = observer(() => {
	const context = useQueryContext()
	const classes = useStyle()
	const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: ArticleData) => {
		e.preventDefault()
		e.stopPropagation()
		setPreviewRow(item)
	}
	const { columnSizingOptions, columns, selectedStatus } = useDataGrid(onLinkClick)
	const filterArticle = useComputed(() => {
		const hasDuplicatedFilter = selectedStatus['ReviewStatus'].includes('Duplicated')
		const article = [
			...context.Articles.filter((a) => {
				const status = context.GetRowReviewStatus(a['Metadata.ContentID'])
				return (hasDuplicatedFilter && status.startsWith('duplicated')) || selectedStatus['ReviewStatus'].includes(status)
			}),
		]
		article.forEach((a, i) => {
			a.lineNumber = i + 1
		})
		return article
	}, [selectedStatus])
	const { data, Pagination } = useMemoryPagination(filterArticle, 10)
	const [previewRow, setPreviewRow] = useState<ArticleData | null>(null)

	const onDialogClose = async (value?: EReviewResult) => {
		if (value && previewRow !== null) {
			if (value === ReviewResult.Exclude) await setReviewStatus(previewRow, ReviewResult.Exclude)
			else if (value === ReviewResult.Include) await setReviewStatus(previewRow, ReviewResult.Include)
			else if (value === ReviewResult.Reset) await setReviewStatus(previewRow, ReviewResult.Reset)
		}
		setPreviewRow(null)
	}
	const setReviewStatus = action(async (article: ArticleData, status: EReviewResult) => {
		if (status !== ReviewResult.Exclude && status !== ReviewResult.Include && status !== ReviewResult.Reset) return
		const contentId = article['Metadata.ContentID']
		context.QueryData.Includes.delete(contentId)
		context.QueryData.Excludes.delete(contentId)
		if (status !== ReviewResult.Reset) context.DirtyArticle.set(contentId, article)
		if (status === ReviewResult.Include) {
			context.QueryData.Includes.add(contentId)
		} else if (status === ReviewResult.Exclude) {
			context.QueryData.Excludes.add(contentId)
		}
		Api.MigrationTask.ModifyItemReviewStatus({
			migrationTaskId: context.MigrationId!,
			articleSourceId: article['Metadata.ContentID'],
			articleTitle: article.Title,
			articleKBID: article['Metadata.KBID'],
			locale: article.Locale,
			articleSourceSystem: ArticleSourceSystem.Evergreen,
			articleRevisionNumber: Number(article['Metadata.RevisionNumber']) ?? 0,
			articleAuthor: article['Properties.Contacts.WriterName'] ?? '',
			articleUrl: article.Url,
			reviewResult: status,
			articleLastPublishDate: article['Properties.LastPublishDate'] ?? '',
		})
		context.CurrentStep = 'AfterRun'
	})

	return (
		<section className="data-query-result">
			<DataGrid
				items={data}
				columns={columns}
				getRowId={(item) => item.id}
				columnSizingOptions={columnSizingOptions}
				className={mergeClasses(classes.grid, 'resizable-grid')}
				resizableColumns
				resizableColumnsOptions={{
					autoFitColumns: false,
				}}
			>
				<DataGridHeader>
					<DataGridRow>{({ renderHeaderCell }) => renderHeaderCell()}</DataGridRow>
				</DataGridHeader>
				{context.DataQueryResult.Loading ? (
					<section className="data-query-spinner">
						<LoadingSpinner />
					</section>
				) : data.length === 0 ? (
					<Body1 style={{ margin: '10px', display: 'block' }}>No results match the query.</Body1>
				) : (
					<DataGridBody<Article>>
						{({ item, rowId }) => <DataGridRow<Article> key={rowId}>{({ renderCell }) => renderCell(item)}</DataGridRow>}
					</DataGridBody>
				)}
			</DataGrid>
			<section className="data-query-footer" style={{ visibility: context.DataQueryResult.Loading ? 'hidden' : 'visible' }}>
				<Tooltip
					content={
						<section className={classes.countContainer}>
							<Body1Strong>Total:</Body1Strong>
							<Body1>{context.Articles.length}</Body1>
							<Body1Strong>Duplicated:</Body1Strong>
							<Body1>{context.DuplicationMap.size}</Body1>
							<Body1Strong>{ReviewStatus.NotReviewed}:</Body1Strong>
							<Body1>
								{context.Articles.length -
									context.QueryData.Includes.size -
									context.QueryData.Excludes.size -
									context.DuplicationMap.size}
							</Body1>
							<Body1Strong>{ReviewStatus.Include}:</Body1Strong>
							<Body1> {context.QueryData.Includes.size}</Body1>
							<Body1Strong>{ReviewStatus.Exclude}:</Body1Strong>
							<Body1> {context.QueryData.Excludes.size}</Body1>
						</section>
					}
					relationship="description"
				>
					<section className="data-query-footer-summary">
						<Body1 truncate>
							<Body1Strong>Total</Body1Strong>:<Body1>{context.Articles.length}</Body1>
						</Body1>
						<Body1 truncate>
							<Body1Strong>Duplicated:</Body1Strong>
							<Body1> {context.DuplicationMap.size}</Body1>
						</Body1>
						<Body1 truncate>
							<Body1Strong>{ReviewStatus.NotReviewed}:</Body1Strong>
							<Body1>
								{context.Articles.length -
									context.QueryData.Includes.size -
									context.QueryData.Excludes.size -
									context.DuplicationMap.size}
							</Body1>
						</Body1>
						<Body1 truncate>
							<Body1Strong>{ReviewStatus.Include}:</Body1Strong>
							<Body1> {context.QueryData.Includes.size}</Body1>
						</Body1>
						<Body1 truncate>
							<Body1Strong>{ReviewStatus.Exclude}:</Body1Strong>
							<Body1> {context.QueryData.Excludes.size}</Body1>
						</Body1>
					</section>
				</Tooltip>
				{Pagination}
			</section>
			<PreviewAction taskId={context.MigrationId!} row={previewRow} open={previewRow !== null} onClose={onDialogClose} />
		</section>
	)
})
