import React from 'react'
import moment from 'moment'
import { DatePicker } from '@fluentui/react-datepicker-compat'
import { DayOfWeek } from '@fluentui/react-calendar-compat'
import { QueryPropsType } from './QueryBuilderConstants'
import { Input, InputOnChangeData, SpinButton } from '@fluentui/react-components'
import { EnhancedSelect } from '@components/EnhancedSelect'
export interface ValueFieldProps {
	type: string
	disable: boolean
	index: number
	value: string
	options: string[]
	onChange: (index: number, value: string | Date) => void
	onFieldValueChanged: (index: number, newValue: string, type: boolean) => void
}

export const ValueField = (props: ValueFieldProps) => {
	const onFieldValueChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
		props.onChange(props.index, data.value)
	}

	switch (props.type) {
		/**
		 * Input control for username types
		 */
		case QueryPropsType.Username:
			return <Input disabled={props.disable} onChange={onFieldValueChange} value={props.value} title="Value" />
		/**
		 * Input control for string types
		 */
		case QueryPropsType.Text:
			/**
			 * if metadata type is an Enum
			 */
			if (props.options?.length) {
				return (
					<EnhancedSelect
						value={props.value}
						options={props.options.map((option) => ({
							value: option,
							text: option,
						}))}
						onOptionSelect={(newValue) => props.onChange(props.index, newValue)}
						title="Value"
					/>
				)
			} else {
				/**
				 * type is any string
				 */
				return <Input disabled={props.disable} onChange={onFieldValueChange} value={props.value} title="Value" />
			}
		/**
		 * Input control for Date types
		 */
		case QueryPropsType.Date:
			const localDate = new Date(moment(props.value).local().valueOf())

			return (
				<DatePicker
					firstDayOfWeek={DayOfWeek.Sunday}
					disabled={props.disable}
					onSelectDate={(date) => {
						const utcDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ssZ')
						props.onChange(props.index, utcDate)
					}}
					value={localDate}
					title="Value"
				/>
			)
		/**
		 * Input control for numeric types
		 */
		case QueryPropsType.Numerical:
			let value = Number(props.value)
			if (isNaN(value)) value = 0
			return (
				<SpinButton
					disabled={props.disable}
					value={value}
					onChange={(e, d) => {
						let v = Number(d.value ?? d.displayValue ?? '0')
						if (isNaN(v)) v = 0
						props.onChange(props.index, v.toString())
					}}
					title="Value"
				/>
			)

		/**
		 * Input control for Boolean types
		 */
		case QueryPropsType.Boolean:
			return (
				<EnhancedSelect
					value={props.value}
					options={[{ value: 'true' }, { value: 'false' }]}
					onOptionSelect={(newValue) => props.onChange(props.index, newValue)}
					title="Value"
				/>
			)
		case QueryPropsType.Fulltext:
			return <Input disabled={props.disable} onChange={onFieldValueChange} value={props.value} title="Value" />
		case QueryPropsType.SysChangedDate:
			/**
			 * if metadata type is an Enum
			 */
			if (props.options !== null && props.options.length > 0) {
				return (
					<EnhancedSelect
						value={props.value}
						options={props.options.map((option) => ({
							value: option,
							text: option,
						}))}
						onOptionSelect={(newValue) => props.onChange(props.index, newValue)}
						title="Value"
					/>
				)
			} else {
				/**
				 * type is any string
				 */
				return <Input disabled={props.disable} onChange={onFieldValueChange} value={props.value} title="Value" />
			}
		default:
			return <></>
		// return <Input disabled />
	}
}
