import { Env } from '@env'
import { Fetch } from './Fetch'
import {
	ArticleForPublish,
	EMigrationStep,
	EPRStatus,
	EStep5Status,
	MigrationArticlesForConvert,
	MigrationItemStatusRespond,
	Pagination,
	PaginationResponse,
	MigrationArticle as TMigrationArticle,
} from './Types'
import { WrapperPagination } from './OperationResult'
const Root = `${Env.BackendApiUrl}api/migration/MigrationArticle`
const GetArticles = (
	migrationTaskId: number,
	query: { articleId?: string; title?: string; status?: EStep5Status[]; isConverted?: boolean },
	page: Pagination
) => {
	return Fetch.Get<PaginationResponse<TMigrationArticle>>({
		url: `${Root}/GetMigrationArticles`,
		query: {
			taskId: migrationTaskId,
			...query,
			...page,
		},
	}).then((r) => WrapperPagination(r, page))
}
const ConfigArticle = (
	models: Required<Pick<TMigrationArticle, 'id' | 'msService' | 'msTopic' | 'permissionType' | 'targetRepoFileName' | 'targetRepoFolder'>>[]
) => {
	return Fetch.Post<number[]>(`${Root}/ConfigMigrationArticles`, models)
}
const ConvertCheck = (ids: number[]) => {
	return Fetch.Post<string>(`${Root}/BeginConvertCheck`, ids)
}
const GetArticleStatusAndDates = (ids: number[], steps?: EMigrationStep[]) => {
	return Fetch.Post<MigrationItemStatusRespond[]>(`${Root}/GetArticleStatusAndDates`, { items: ids, steps })
}
const RenderMarkdown = (id: number) => {
	return Fetch.Get<string>({
		url: `${Root}/RenderMarkdown`,
		query: {
			articleId: id,
		},
	})
}
const GetMigrationArticlesForPublish = (
	migrationTaskId: number,
	query: {
		articleId?: string
		title?: string
		status?: EPRStatus[]
	},
	page: Pagination
) => {
	return Fetch.Get<PaginationResponse<ArticleForPublish>>({
		url: `${Root}/GetMigrationArticlesForPublish`,
		query: {
			taskId: migrationTaskId,
			...query,
			...page,
		},
	}).then((r) => WrapperPagination(r, page))
}
const CreatePR = (ids: number[]) => {
	return Fetch.Post<string>(`${Root}/UploadArticlesToGithub`, ids)
}
const GetMigrationArticlesForConvert = (
	migrationTaskId: number,
	query: { articleId?: string; title?: string; status?: EStep5Status[] },
	page: Pagination
) => {
	return Fetch.Get<PaginationResponse<MigrationArticlesForConvert>>({
		url: `${Root}/GetMigrationArticlesForConvert`,
		query: {
			taskId: migrationTaskId,
			...query,
			...page,
		},
	}).then((r) => WrapperPagination(r, page))
}
export const MigrationArticle = {
	GetArticles,
	ConfigArticle,
	ConvertCheck,
	GetArticleStatusAndDates,
	RenderMarkdown,
	GetMigrationArticlesForPublish,
	CreatePR,
	GetMigrationArticlesForConvert,
}
