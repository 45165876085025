import { DataGridCell, Link } from '@fluentui/react-components'
import { observer } from 'mobx-react-lite'
import { useMigrationContext } from '../Context'
import { ArticleForPublish } from '@/Api/Types'

export const PRLinkRender = observer(({ item }: { item: ArticleForPublish }) => {
	const context = useMigrationContext()
	return (
		<DataGridCell>
			{item.prNumber && (
				<Link
					onClick={(e) => {
						e.stopPropagation()
					}}
					target="_blank"
					href={`https://github.com/MicrosoftDocs/${context.Step1.RepoUrl}/pull/${item.prNumber}`}
					title="PR Link"
				>
					{item.prNumber} ({context.Step1.RepoUrl})
				</Link>
			)}
		</DataGridCell>
	)
})
