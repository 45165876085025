import { EStep3Status, Step3StatsuOptions } from '@/Api/Types'
import { InfoIcon } from '@components/InfoIcon'
import { Input, Dropdown, Button, Option, Body1, makeStyles } from '@fluentui/react-components'
import { useState } from 'react'
const useStyle = makeStyles({
	option: {
		display: 'inline-flex',
		alignItems: 'center',
	},
	statusButton: {
		minWidth: '300px',
	},
})
export const useFilter = (search: () => void) => {
	const classes = useStyle()

	const Step3StatsuRender = {
		'Not retrieved': (
			<Body1 className={classes.option}>
				Not retrieved<InfoIcon>No action taken.</InfoIcon>
			</Body1>
		),
		'Retrieved failed': (
			<Body1 className={classes.option}>
				Retrieved failed<InfoIcon>Articles not retrieved. </InfoIcon>
			</Body1>
		),
		'Retrieved but checked failed': (
			<Body1 className={classes.option}>
				Retrieved but checked failed<InfoIcon>Retrieved but did not pass check.</InfoIcon>
			</Body1>
		),
		'Retrieved and checked passed': (
			<Body1 className={classes.option}>
				Retrieved and checked passed<InfoIcon>Successfully retrieved and passed check.</InfoIcon>
			</Body1>
		),
	} as { [key: string]: JSX.Element }
	const [articleId, setArticleId] = useState('')
	const [title, setTitle] = useState('')
	const [filterStatus, setFilterStatus] = useState([] as string[])
	const reset = () => {
		setArticleId('')
		setTitle('')
		setFilterStatus([])
		requestIdleCallback(() => {
			search()
		})
	}
	return [
		<>
			<Input placeholder="Article ID" value={articleId} onChange={(e, d) => setArticleId(d.value)} />
			<Input placeholder="Title" value={title} onChange={(e, d) => setTitle(d.value)} />
			<Dropdown
				selectedOptions={filterStatus}
				multiselect
				placeholder="Status"
				onOptionSelect={(e, d) => {
					setFilterStatus(d.selectedOptions)
				}}
				className={classes.statusButton}
				title="Status"
			>
				{Object.entries(Step3StatsuOptions).map(([k, v]) => (
					<Option key={k} value={v.toString()} text={k}>
						{Step3StatsuRender[k]}
					</Option>
				))}
			</Dropdown>
			<Button appearance="primary" onClick={search}>
				Search
			</Button>
			<Button onClick={reset}>Reset</Button>
		</>,
		{
			articleId,
			title,
			status: filterStatus.map((o) => Number(o) as EStep3Status),
			onlyIncluded: true,
		},
	] as const
}
