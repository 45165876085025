import { Button, DataGridCell, SkeletonItem, TableCellLayout } from '@fluentui/react-components'
import { observer } from 'mobx-react-lite'
import { useMigrationContext } from '../Context'
import { CheckFailDialog } from './CheckFailDialog'
import { RetrieveFailDialog } from './RetrieveFailDialog'
import { MigrationItem } from '@/Api/Types'

export const StatusRender = observer(({ item, statusType }: { item: MigrationItem; statusType: 'Retrieve' | 'Check' }) => {
	const { Step3 } = useMigrationContext()
	const status = Step3.Status.get(item.id) ?? { CheckStatus: 'Pending', RetrieveStatus: 'Pending' }
	let children = <></>
	switch (status[statusType === 'Retrieve' ? 'RetrieveStatus' : 'CheckStatus']) {
		case 'Pending':
		case undefined:
			children = <Button appearance="transparent">Pending</Button>
			break
		case 'Processing':
			children = <SkeletonItem />
			break
		case 'Failed':
			children = statusType === 'Retrieve' ? <RetrieveFailDialog message={status.RetrieveError} /> : <CheckFailDialog item={item} />
			break
		case 'Success':
			children = <Button appearance="transparent">Success</Button>
			break
		case 'Warning':
			children = <CheckFailDialog item={item} />
	}
	return (
		<DataGridCell>
			<TableCellLayout
				title={status[statusType === 'Retrieve' ? 'RetrieveStatus' : 'CheckStatus']}
				truncate
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				{children}
			</TableCellLayout>
		</DataGridCell>
	)
})
