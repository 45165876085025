import { useEffect, useState } from 'react'
import { useComputed } from './useComputed'
import { Pagination } from '@components/Pagination'

export function useMemoryPagination<T>(arr: T[], size: number) {
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(size)
	const offset = (page - 1) * pageSize
	const total = arr.length
	const totalPage = Math.floor(total % pageSize === 0 ? total / pageSize : total / pageSize + 1)
	const data = useComputed(() => {
		return arr.slice(offset, offset + pageSize)
	}, [pageSize, offset, arr])

	useEffect(() => {
		if (page > totalPage) setPage(Math.max(1, totalPage))
	}, [totalPage, page])
	return {
		data,
		offset,
		Pagination: (
			<Pagination
				current={page}
				pageSize={pageSize}
				total={total}
				totalPage={totalPage}
				onChange={setPage}
				changeCurrent={setPage}
				changePageSize={setPageSize}
			/>
		),
	}
}
