import { EPermissionType, PermissionType } from '@/Api/Types'
import { DataGridCell, TableCellLayout, Dropdown, Option } from '@fluentui/react-components'
import { observer } from 'mobx-react-lite'
import { useMigrationContext } from '../Context'
import { useStyle } from './styles'
import { Article } from './types'

export const Permission = observer(({ item }: { item: Article }) => {
	const classes = useStyle()
	const { Step4 } = useMigrationContext()
	const entity = Step4.GetEntity(item)
	return (
		<DataGridCell>
			<TableCellLayout truncate>
				<Dropdown
					button={
						<span className={classes.truncatedText} title={entity.permission}>
							{entity.permission}
						</span>
					}
					disabled={entity.readonly}
					onOptionSelect={(e, o) => {
						Step4.setPermissionType(item, o.optionValue! as EPermissionType)
					}}
					title="Permission Type"
				>
					<Option value={PermissionType.Public}>{PermissionType.Public}</Option>
					<Option value={PermissionType.Private}>{PermissionType.Private}</Option>
					<Option value={PermissionType.Secret}>{PermissionType.Secret}</Option>
				</Dropdown>
			</TableCellLayout>
		</DataGridCell>
	)
})
