import { makeStyles } from '@fluentui/react-components'

export const useStyle = makeStyles({
	previewIcon: {
		width: '35px',
		maxWidth: '35px',
	},
	dropdown: {
		width: '100%',
		minWidth: 'unset',
	},
	truncatedText: {
		overflowX: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	grid: {
		overflowX: 'auto',
		minWidth: 'unset !important',
	},
	gridBody: {
		width: 'fit-content',
	},
	text: {
		display: 'inline-flex',
	},
	link: {
		display: 'inline-flex',
	},
	tooltip: {
		maxWidth: '350px',
	},
})
