import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { sonicMsalInstance } from '..'
import { loginRequest } from '../Config/SonicMsalConfig'

import IndexData from './Index.json'
import FieldData from './Fields.json'
import { getOderByFields } from '@components/DataQuery/Utilities/QueryBuilderExtension'
import { getAppInsights } from '@components/DataQuery/Utilities/TelemetryService'
import {
	DataQueryIndex,
	DataField,
	MetaDataQueryParams,
	ITaskSearch,
	ICurationRun,
	ICurationRunWithName,
	ITaskRunsResult,
	ITicketEvent,
	TriggerSettings,
	IRemediateIt,
	ITextSearchSettings,
	ICurationProcessSettings,
	Task,
	ITagItem,
	IScanIssueData,
	IReportIssueParam,
	IWelcomeStats,
	IDataSource,
	CurationFilter,
} from '@components/DataQuery/Interface'
import { Env } from '@env'
import { ArticleData } from '@components/DataQuery/DataQueryContext/ArticleData'
import { RootConfig } from '@/Config/Const'
import { GetRoot } from '@/Mobx'

const NotAvailable = 'NOT_AVAILABLE'
interface AxiosError extends Error {
	config: AxiosRequestConfig
	code?: string
	response?: AxiosResponse
}
const flatInnerObject = (source: any, prefex: string, target: any) => {
	for (const key in source) {
		if (typeof source[key] !== 'object') {
			target[`${prefex}.${key}`] = source[key]
		} else {
			flatInnerObject(source[key], `${prefex}.${key}`, target)
		}
	}
}
const flatObject = (o: any) => {
	const result = {} as any
	for (const key in o) {
		if (typeof o[key] !== 'object') {
			result[key] = result['Article.' + key] = o[key]
		} else {
			flatInnerObject(o[key], key, result)
		}
	}
	return result
}
export class ApiError extends Error {
	statusCode: string
	errorMessage: string
	url: string
	methodName: string
	axiosHttpErr: AxiosError
	constructor(axiosHttpErr: AxiosError, ...params: any) {
		super(...params)

		this.axiosHttpErr = axiosHttpErr
		console.log('ApiError', this.axiosHttpErr)
		this.url = (this.axiosHttpErr.config && this.axiosHttpErr.config.url) || NotAvailable

		this.methodName =
			(this.axiosHttpErr.config && this.axiosHttpErr.config.method && this.axiosHttpErr.config.method.toUpperCase()) || NotAvailable

		this.statusCode = this.axiosHttpErr.response ? this.axiosHttpErr.response.status.toString() : NotAvailable

		this.errorMessage = `Server request failed.`
		if (axiosHttpErr.response && axiosHttpErr.response.data && axiosHttpErr.response.data.ErrorMessage) {
			this.errorMessage = axiosHttpErr.response.data.ErrorMessage
		}
	}
}

/**
 * Base loader
 */
class Loader {
	/**
	 * Axios instance to make HTTP requests. Configured with user JWT
	 */
	axiosHelper: AxiosInstance
	issueCenterHelper: AxiosInstance

	constructor() {
		this.axiosHelper = axios.create({
			baseURL: Env.Sonic.ApiUrl,
			timeout: 80000,
		})

		this.issueCenterHelper = axios.create({
			baseURL: `${process.env.REACT_APP_ISSUE_CENTER_API_URL}`,
			timeout: 80000,
		})

		function initHelperConfiguration(helper: AxiosInstance) {
			helper.interceptors.request.use(async (config) => {
				const userAccount = sonicMsalInstance.getActiveAccount()
				if (!userAccount) {
					throw Error('No active account! Verify a user has been signed in.')
				}

				const accessTokenRequest = {
					...loginRequest,
					account: userAccount,
				}

				const tokenResponse = await sonicMsalInstance.acquireTokenSilent(accessTokenRequest)

				config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`
				return config
			})

			helper.interceptors.response.use(
				(response) => {
					return response
				},
				(error) => {
					try {
						if (error.response) {
							let apiError = new ApiError(error)
							GetRoot().notifications.addNotification('Failed', 'Sonic network error.', 'Fail')
							let appInsight = getAppInsights()

							appInsight.trackException({
								error: new Error('Sonic API_ERR: ' + apiError.url + ', error data:' + apiError.errorMessage),
								severityLevel: SeverityLevel.Error,
							})

							return Promise.reject(apiError)
						} else {
							return Promise.reject(error)
						}
					} catch (catchError) {
						console.log('Sonic api error', catchError)
						return Promise.reject(error)
					}
				}
			)
		}

		initHelperConfiguration(this.axiosHelper)
		initHelperConfiguration(this.issueCenterHelper)
	}
}

/**
 * Query loader
 */
export class PowerQueryService extends Loader {
	/**
	 * Gets the list of indexes that the user has permission to query via global
	 * metadata query
	 */
	async getQueryDataIndex() {
		return new Promise<DataQueryIndex[]>((resolve, reject) => {
			const func = async () => {
				return [IndexData] as unknown as DataQueryIndex[]
				// const evergreenIndex = ((await this.axiosHelper.get('/api/search/searchableindexes')).data as DataQueryIndex[]).find(
				// 	(index) => index.name === Env.SonicIndex.Name
				// )
				// if (evergreenIndex) return [evergreenIndex]
				// else return [IndexData] as unknown as DataQueryIndex[]
			}
			let timer = setInterval(() => {
				const userAccount = sonicMsalInstance.getActiveAccount()
				if (!sonicMsalInstance.getActiveAccount() && sonicMsalInstance.getAllAccounts().length > 0) {
					// Account selection logic is app dependent. Adjust as needed for different use cases.
					sonicMsalInstance.setActiveAccount(sonicMsalInstance.getAllAccounts()[0])
				}
				if (userAccount) {
					clearInterval(timer)
					resolve(func())
				}
			}, 100)
		})
	}

	/**
	 * Gets the list of metadata field
	 * @param metadataSchemaId
	 * @returns
	 */
	async getDataFields(metadataSchemaId: string) {
		return FieldData as DataField[]
		//return (await this.axiosHelper.get(`/api/metadata/${metadataSchemaId}/fields`)).data as DataField[]
	}
	async searchArticles(ids: string[], searchIndex: string) {
		const queryParams: MetaDataQueryParams = {
			SearchIndex: searchIndex,
			SearchQuery: '*',
			Filter: `search.in(Metadata/ContentID,'${ids.join(',')}')`,
			Select: '*',
			Top: RootConfig.SonicMaxQueryItem,
		}

		const { data } = await this.axiosHelper.post('/api/search/content', queryParams)

		return data.Contents.map((topic: any) => {
			return flatObject(topic)
		}) as ArticleData[]
	}
	/**
	 * search content
	 * @param searchQuery
	 * @param searchIndex
	 * @param filterStr
	 * @param selectStr
	 * @returns
	 */
	async searchContent(
		searchQuery: string,
		searchIndex: string,
		filterStr: string,
		filterJson: string,
		selectStr: string,
		top?: number,
		skip?: number,
		orderByField?: string
	): Promise<{
		loading: boolean
		hasRunQuery: boolean
		Count: number
		PageIndex: number
		Topics: ArticleData[]
		columns: string[]
	}> {
		const results = {
			loading: false,
			hasRunQuery: true,
			Count: 0,
			PageIndex: 0,
			Topics: [],
			columns: [],
		}

		// console.log("filterStr", filterStr)

		// let orderBy = getOderByFields(searchIndex)
		// if (orderByField) {
		// 	orderBy = `${orderByField} desc`
		// }

		const queryParams: MetaDataQueryParams = {
			SearchIndex: searchIndex,
			OrderBy: orderByField,
			SearchQuery: '*',
			Filter: filterStr || '',
			FilterJson: filterJson,
			Select: selectStr,
			Top: top,
			Skip: skip,
		}

		// console.log("searchContent", queryParams)
		const r = await this.axiosHelper.post('/api/search/content', queryParams)
		const { data } = searchQuery ? r : { data: {} }

		// console.log("searchContent", data)

		if (data) results.Count = data.Count
		// if (data) results.Topics = data.Topics;
		if (data)
			results.Topics = data.Contents.map((topic: any) => {
				return flatObject(topic)
			})
		results.PageIndex = 0
		results.loading = false
		results.hasRunQuery = true
		results.columns = []

		return results
	}

	/**
	 * search content
	 * @param searchQuery
	 * @param searchIndex
	 * @param indexPrefix
	 * @param filterStr
	 * @param selectStr
	 * @returns
	 */
	async downloadSearchResult(searchQuery: string, searchIndex: string, indexPrefix: string, filterStr: string, selectStr: string) {
		let orderBy = getOderByFields(searchIndex)

		const queryParams: MetaDataQueryParams = {
			SearchIndex: searchIndex,
			IndexPrefix: indexPrefix,
			OrderBy: orderBy,
			SearchQuery: '*',
			Filter: filterStr || '',
			Select: selectStr,
		}

		return (await this.axiosHelper.post('/api/search/download', queryParams)).data as string
	}
}

/**
 * Task loader
 */
export class TaskService extends Loader {
	/**
	 * Gets all tasks
	 * @returns
	 */
	async getAllCurationName() {
		return (await this.axiosHelper.get(`/api/curationconfiguration/allcurationname`)).data as string[]
	}

	/**
	 * Gets all tasks
	 * @returns
	 */
	async getAllTaskList() {
		return (await this.axiosHelper.get(`/api/curationconfiguration/all`)).data as ITaskSearch[]
	}

	/**
	 * Gets recent tasks
	 * @returns
	 */
	async getRecentTask() {
		return (await this.axiosHelper.get(`/api/curationconfiguration/recent`)).data as ITaskSearch[]
	}

	/**
	 * Gets the curation runs list
	 * @returns ICurationRun[]
	 */
	async getCurationRuns() {
		return (await this.axiosHelper.get(`/api/pipeline/builds`)).data as ICurationRun[]
	}

	/**
	 * Gets the curation runs list
	 * @returns ICurationRun[]
	 */
	async getCurationRunsById(curationId: string) {
		return (await this.axiosHelper.get(`/api/pipeline/curationbuilds?id=${curationId}`)).data as ICurationRunWithName
	}

	/**
	 * Gets the curation runs list
	 * @returns ICurationRun[]
	 */
	async getMyCurationRuns() {
		return (await this.axiosHelper.get(`/api/curationconfiguration/mycurations`)).data as ICurationRunWithName[]
	}

	/**
	 * Gets the curation runs list
	 * @returns ICurationRun[]
	 */
	async searchTaskRunsResultById(buildId: string) {
		return (await this.axiosHelper.get(`/api/pipeline/builddetails?buildId=${buildId}`)).data as ITaskRunsResult
	}

	/**
	 *
	 * @param taskName
	 * @param SearchQuery
	 * @param SearchIndex
	 * @param Filter
	 * @param SelectString
	 * @param RunType
	 * @param SharedType
	 * @param SharedWith
	 * @param Path
	 * @param IsReport
	 * @param ReportTo
	 * @param Description
	 * @param CurationArea
	 * @param ConditionJsonString
	 * @param triggerSettings
	 * @param curationProcessSettings
	 */
	async submitTask(
		id: string,
		taskName: string,
		SearchQuery: string,
		SearchIndex: string,
		IndexPrefix: string,
		Filter: string,
		SelectString: string,
		Top: number,
		OrderBy: string,
		Datamart: string,
		DatamartFullName: string,
		DatamartShortName: string,
		DatamartGroupName: string,
		Algorithm: string,
		AlgorithmFullName: string,
		AlgorithmShortName: string,
		AlgorithmGroupName: string,
		RunType: string,
		SharedType: string,
		SharedWith: string,
		Path: string,
		IsReport: boolean,
		ReportTo: string,
		IsTicket: boolean,
		TicketType: string,
		AssignTo: string,
		TicketEvent: ITicketEvent,
		Description: string,
		CurationArea: string,
		ConditionJsonString: string,
		triggerSettings: TriggerSettings,
		remediateItSetting: IRemediateIt,
		textSearchSettings?: ITextSearchSettings,
		curationProcessSettings?: ICurationProcessSettings
	) {
		const CurationFilter: CurationFilter = {
			SearchQuery: SearchQuery,
			SearchIndex: SearchIndex,
			IndexPrefix: IndexPrefix,
			Filter: Filter || '',
			ConditionJsonString: ConditionJsonString,
			Select: SelectString,
			Top: Top,
			OrderBy: OrderBy ? OrderBy + ' desc' : '',
			Datamart: Datamart,
			DatamartFullName: DatamartFullName,
			DatamartShortName: DatamartShortName,
			DatamartGroupName: DatamartGroupName,
			Algorithm: Algorithm,
			AlgorithmFullName: AlgorithmFullName,
			AlgorithmShortName: AlgorithmShortName,
			AlgorithmGroupName: AlgorithmGroupName,
		}

		const tasks: Task = {
			id: id,
			CurationConfigurationName: taskName,
			CurationFilter: CurationFilter,
			RunType: RunType,
			SharedType: SharedType,
			SharedWith: SharedWith,
			Path: Path,
			IsReport: IsReport,
			ReportTo: ReportTo,
			IsTicket: IsTicket,
			TicketType: TicketType,
			AssignTo: AssignTo,
			TicketEvent: TicketEvent,
			Description: Description,
			CurationArea: CurationArea,
			TriggerSettings: triggerSettings,
			RemediateIt: remediateItSetting,
			TextSearchSettings: textSearchSettings,
			CurationProcessSettings: curationProcessSettings,
		}

		console.log('submitTask', tasks)

		const timezoneOffset = new Date().getTimezoneOffset() * -1

		await this.axiosHelper.post(`/api/curationconfiguration/save?timezoneOffset=${timezoneOffset}`, tasks)
	}

	/**
	 * delete task
	 * @param taskName
	 */
	async deleteCuration(id: string) {
		await this.axiosHelper.put(`/api/curationconfiguration/delete?id=${id}`)
	}

	/**
	 * switch the task
	 * @param taskStatus
	 * @param taskName
	 */
	async switchCurationStatus(taskStatus: string, id: string) {
		if (taskStatus === 'Enable') {
			await this.axiosHelper.put(`/api/curationconfiguration/enabled?id=${id}`)
		} else {
			await this.axiosHelper.put(`/api/curationconfiguration/disabled?id=${id}`)
		}
	}

	/**
	 * delete task
	 * @param taskName
	 */
	async runCuration(id: string, runType: string, isForcedTicket: boolean) {
		await this.axiosHelper.post(`/api/curationconfiguration/run?id=${id}&runType=${runType}&isForcedTicket=${isForcedTicket}`)
	}

	/**
	 * get task by curation name
	 * @param taskName
	 */
	async getCurationById(id: string) {
		return (await this.axiosHelper.get(`/api/curationconfiguration/get?id=${id}`)).data as Task
	}

	/**
	 * get task by curation name
	 * @param taskName
	 */
	async exportTaskRunsResult(csvPath: string) {
		return (await this.axiosHelper.put(`api/pipeline/download2string?blobPath=${csvPath}`)).data as string
	}

	/**
	 * get task by curation name
	 * @param taskName
	 */
	async exportTaskRunsResultByFiledId(fileId: string) {
		return (await this.axiosHelper.put(`api/pipeline/download2stringbyfiledid?fileId=${fileId}`)).data as string
	}

	/**
	 *
	 * @param id
	 * @returns
	 */
	async followUser(id: string) {
		return (await this.axiosHelper.post(`/api/curationconfiguration/follow?id=${id}`)).data
	}

	/**
	 *
	 * @param id
	 * @returns
	 */
	async unFollowUser(id: string) {
		return (await this.axiosHelper.post(`/api/curationconfiguration/unfollow?id=${id}`)).data
	}

	/**
	 * get task by curation name
	 * @param taskName
	 */
	async onExportAlgoReport(searchQuery: string, searchIndex: string, filterStr: string, selectStr: string) {
		let orderBy = 'ArticleArchivingRemainingDays desc, SapStatus desc'
		if (searchIndex.startsWith('gitpub-metadata-schema-others-')) {
			orderBy = 'NoOfBrokenLinks desc'
		} else if (searchIndex.startsWith('gitpub-metadata-schema-policheck-')) {
			orderBy = 'Domain'
		}

		const queryParams: MetaDataQueryParams = {
			SearchIndex: searchIndex,
			OrderBy: orderBy,
			SearchQuery: '*',
			Filter: filterStr || '',
			Select: selectStr,
		}

		return (await this.axiosHelper.post('/api/search/download', queryParams)).data as string
	}

	/**
	 *
	 * @param buildId
	 * @returns
	 */
	async ticketDownload(buildId: string) {
		return (await this.axiosHelper.get(`api/ticket/download?buildId=${buildId}`)).data as string
	}

	/**
	 *
	 * @param CurationId
	 * @returns
	 */
	async createDownloadLogs(CurationId: string, PageType: string, DownloadUrl: string) {
		let objParams = {
			CurationId: CurationId,
			PageType: PageType,
			DownloadUrl: DownloadUrl,
		}

		return (await this.axiosHelper.post('/api/download/logs', objParams)).data as string
	}

	/**
	 *
	 * @returns
	 */
	async getCurationTags() {
		let curationTags: ITagItem[] = []
		return await this.axiosHelper.get(`api/content/tags`).then((res) => {
			if (res.data) {
				res.data.map((item: string) => {
					curationTags.push({
						id: item,
						text: item,
					})
				})

				return curationTags
			}

			return curationTags
		})
	}

	/**
	 *
	 * @param buildId
	 * @returns
	 */
	async getTicketDevOpsConfig() {
		return (await this.axiosHelper.get(`api/curationconfiguration/ticketdevopsconfigs`)).data as any[]
	}

	async getBrokenLinkScanResult(requestUrl: string, enforce: boolean) {
		let resultData = (await this.axiosHelper.post(`api/brokenlinkchecker/verify?requestUrl=${requestUrl}&enforce=${enforce}`)).data as any

		// console.log("getBrokenLinkScanResult", resultData)
		return resultData
	}

	async getChildLinks(requestUrl: string) {
		let resultData = (await this.axiosHelper.get(`api/brokenlinkchecker/getscanresult?requestUrl=${requestUrl}`)).data as any

		// console.log("getChildLinks", resultData)
		return resultData
	}

	async getScanRequests(requestType: string) {
		let resultData = (await this.axiosHelper.get(`api/brokenlinkchecker/getscanrequests?requestType=${requestType}`)).data as any

		// console.log(requestType)
		// console.log("getScanRequests", resultData)
		return resultData
	}

	async getDataRefreshSchedule(factoryType: string, factoryName: string) {
		let resultData = (
			await this.axiosHelper.get(`api/brokenlinkchecker/getdatarefreshschedule?factoryType=${factoryType}&factoryName=${factoryName}`)
		).data as any

		return resultData
	}

	async getContentDataFactory(factoryType: string, factoryName: string) {
		let resultData = (
			await this.axiosHelper.get(`api/contentdatafactory/getcontentdatabyfactory?factoryType=${factoryType}&factoryName=${factoryName}`)
		).data as any

		return resultData
	}

	async getPiiFreshData(articleUrl: string) {
		const resultData = (await this.axiosHelper.post(`api/pii/crawler?url=${articleUrl}`)).data as any
		return resultData
	}

	async getPoliCheckScanData(articleUrl: string) {
		const resultData = (await this.axiosHelper.post(`api/policheck/crawler?url=${articleUrl}`)).data as any
		return resultData
	}

	async getIssueList(articleUrl: string, userEmail: string, sourceData: IScanIssueData[], riskArea: string) {
		const getParam = {
			articleUrl,
			userEmail,
			sourceData,
			riskArea,
		}
		const resultData = (await this.issueCenterHelper.post('v1/issue/querybyurl', getParam)).data as any
		return resultData
	}

	async createIssueReport(reportParam: IReportIssueParam) {
		const resultData = (await this.issueCenterHelper.post('v1/issue/create', reportParam)).data as any
		return resultData
	}

	async renewIssueReport(issueId: string, reporter: string, expireTime: string) {
		const renewParam = {
			issueId,
			reporter,
			expireTime,
		}
		const resultData = (await this.issueCenterHelper.post('v1/issue/renew', renewParam)).data as any
		return resultData
	}

	async cancelIssueReport(issueId: string, reporter: string) {
		const cancelParam = {
			issueId,
			reporter,
		}
		const resultData = (await this.issueCenterHelper.post('v1/issue/cancel', cancelParam)).data as any
		return resultData
	}
}

/**
 * Welcome loader
 */
export class WelcomeService extends Loader {
	/**
	 * get runs count
	 * @returns
	 */
	async getStats() {
		return (await this.axiosHelper.get(`/api/welcome/stats`)).data as IWelcomeStats
	}

	/**
	 * check site inprocess
	 * @returns
	 */
	async getSiteStatus() {
		return (await this.axiosHelper.get(`/api/welcome/searchindexchecker`)).data as string
	}
}

export class DataSourceService extends Loader {
	/**
	 * upload data source file
	 * @returns
	 */
	async uploadDataSourceFile(formData: any) {
		return await this.axiosHelper.post(`/api/datasource/upload2blob`, formData)
	}

	/**
	 * get data source list
	 * @returns
	 */
	async getDataSourceList(type?: string) {
		let result = (await this.axiosHelper.get(`/api/datasource/fetchdatasource?type=${type}`)).data as IDataSource[]

		return result
	}

	/**
	 * delete a data source
	 * @param taskName
	 */
	async deleteDataSource(name: string) {
		return await this.axiosHelper.post(`/api/datasource/deletefromblob?dataSourceName=${name}`)
	}

	async getFindTextsConfig(dataSourceName: string) {
		return await this.axiosHelper.get(`api/datasource/getfindtexts?datasourceName=${dataSourceName}`)
	}
}

export class MsacctAPI {
	powerQueryService = new PowerQueryService()
	taskService = new TaskService()
	welcomeService = new WelcomeService()
	dataSourceService = new DataSourceService()
	axiosHelper = new Loader().axiosHelper
}
