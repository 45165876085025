import { EnhancedSelect } from '@components/EnhancedSelect'
import { DataGridCell, TableCellLayout } from '@fluentui/react-components'
import { observer } from 'mobx-react-lite'
import { useMigrationContext } from '../Context'
import { Article, Config } from './types'

export const Service = observer(({ item, config }: { item: Article; config: Config }) => {
	const { Step4 } = useMigrationContext()
	const entity = Step4.GetEntity(item)
	return (
		<DataGridCell>
			<TableCellLayout truncate>
				<EnhancedSelect
					value={entity.service}
					onOptionSelect={(o) => Step4.setService(item, o)}
					options={config['MS.Service']}
					positioning={{ matchTargetSize: undefined }}
					disabled={entity.readonly}
					autoComplete="off"
					spellCheck="false"
					title="Service"
					aria-label="Service"
				/>
			</TableCellLayout>
		</DataGridCell>
	)
})
